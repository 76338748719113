import './animation.scss';
interface MemoryLogTogglerProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  allTaskIsCompleted: boolean;
  unreadLogsCount: number;
}

export const MemoryLogToggler = ({
  isOpen,
  toggleIsOpen,
  allTaskIsCompleted,
  unreadLogsCount,
}: MemoryLogTogglerProps) => {
  return (
    <div
      className="flex h-full w-full cursor-pointer items-center justify-center rounded-full"
      onClick={toggleIsOpen}
    >
      {!isOpen && (
        <div className="absolute right-[-10px] top-[-8px] z-50 flex h-6 w-6 items-center justify-center rounded-full border-[1px] border-solid border-white bg-[#FA4490] text-[14px] font-medium text-white">
          {unreadLogsCount}
        </div>
      )}

      {allTaskIsCompleted && (
        <img
          src="/circle-check-white.svg"
          alt=""
        />
      )}

      {isOpen && !allTaskIsCompleted && (
        <img
          src="/close-white.svg"
          alt=""
        />
      )}

      {!isOpen && !allTaskIsCompleted && <div className="dot-pulse"></div>}
    </div>
  );
};
