import { cn } from '@/helpers/cn';
import { IconButton } from '@/components/IconButton.tsx';
import { WorkspaceSwitcher } from './WorkspaceSwitcher.tsx';
import { LeftSideNavMenuItems } from './LeftSideNavMenuItems.tsx';
import { useLeftSideNav } from '../store.ts';
import { MenuFooter } from '@/layouts/MainLayout/components/MenuFooter.tsx';
import { CreditsLimitInfoBox } from '@/layouts/MainLayout/components/CreditsLimitInfoBox.tsx';
import { useUserPlan } from '@/api/pricing/get-user-plan.ts';
import { Button } from 'flowbite-react';
import { useUnFinishedOnboardingProcesses } from '../../../../routes/onboarding/onboarding-store.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { useOpenOnboardingModal } from '@/helpers/open-onboarding-modal.ts';

export function LeftSideNav() {
  const { isNavCollapsed, collapseNav } = useLeftSideNav();
  const { userPlan, isLoading: userPlanLoading } = useUserPlan();
  const { user } = useAuth0();
  const unFinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);
  const { openOnboardingModal } = useOpenOnboardingModal();

  return (
    <nav
      className={cn(
        'group z-10 flex h-svh w-[284px] flex-col overflow-hidden bg-gray-ultra-light py-5 transition-[width] duration-300',
        {
          'is-collapsed w-[64px]': isNavCollapsed,
        },
      )}
    >
      <div className="mb-5 flex justify-between px-3">
        <img
          src="/df-logo-black.svg"
          style={{ display: isNavCollapsed ? 'none' : 'block' }}
          width={120}
          height={20}
          className="max-w-[120px]"
          alt="Digital First AI logo"
        />
        <img
          src="/df-logo-small-black.svg"
          style={{ display: isNavCollapsed ? 'block' : 'none' }}
          width={20}
          height={20}
          className="mx-auto"
          alt="Digital First AI logo"
        />
        <div className="rotate-180 group-[.is-collapsed]:hidden">
          <IconButton
            icon="/sidebar-black.svg"
            onClick={() => collapseNav()}
          />
        </div>
      </div>

      <div className="px-3">
        <WorkspaceSwitcher isNavCollapsed={isNavCollapsed} />
      </div>

      <div className="mt-3 flex min-h-0 grow flex-col justify-between gap-4">
        <div className="grow overflow-y-auto px-3">
          <LeftSideNavMenuItems />
        </div>

        <div className="px-3">
          {unFinishedOnboardingProcesses.length > 0 && (
            <Button
              className="mb-4 w-[260px] group-[.is-collapsed]:hidden"
              color="secondary"
              size="sm"
              onClick={() => openOnboardingModal()}
            >
              Continue onboarding
            </Button>
          )}
          {!userPlanLoading && !userPlan && (
            <div className="w-[260px] group-[.is-collapsed]:hidden">
              <CreditsLimitInfoBox />
            </div>
          )}
          <MenuFooter />
        </div>
      </div>
    </nav>
  );
}
