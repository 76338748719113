import { useApi } from '../api';

import useSWR from 'swr';

export const useStripeCustomerSession = () => {
  const { getFetcher } = useApi();
  const { data, isLoading } = useSWR<string | null>('/stripe/stripe-customer-session', getFetcher);

  return {
    clientSecret: data,
    stripeCustomerLoading: isLoading,
  };
};
