import useSWR from 'swr';
import { useApi } from '../api.ts';
import { Organization } from '@/models/organization.interface.ts';

export const useOrganization = () => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR<Organization | null>('/organization', getFetcher);

  return {
    organization: data,
    isLoading,
    error,
  };
};
