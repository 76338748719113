import { DataRoomItem } from '@/models/data-room-item.interface';
import { findElementId } from './findElementId';
import { replacePromptVars } from './replacePromptVars';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { DataRoomItemType } from '@/enums/data-room-item.enum';

export const getDataRoomItemChunks = async (
  prompt: string,
  items: DataRoomItem[],
  tiptapResourceChunks: (fileId: string, type: 'smartscraper' | 'input') => Promise<string[]>,
) => {
  let replacementPrompt = prompt;
  const inputsIds = findElementId(prompt, `[data-type="${MentionReferenceType.DATA_ROOM}"]`);

  for (const inputId of inputsIds) {
    const selector = `[data-id="${inputId}"]`;
    const item = items.find((item) => item.id === inputId);

    const chunks = await tiptapResourceChunks(
      inputId as string,
      item?.type === DataRoomItemType.WEBSITE ? 'smartscraper' : 'input',
    );

    /** % is needed for better understanding by LLM */
    const text = `%%%%%${chunks.join(' ')}%%%%%`;

    replacementPrompt = replacePromptVars(replacementPrompt, text, selector);
  }

  return replacementPrompt;
};
