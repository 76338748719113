import { WebSocketStatus } from '@hocuspocus/provider';
import { memo } from 'react';
import { EditorUser } from '../types';
import { getConnectionText } from '@/components/tiptap/lib/utils';
import Tooltip from '../../ui/Tooltip';

export type EditorInfoProps = {
  characters: number;
  words: number;
  collabState: WebSocketStatus;
  users: EditorUser[];
};

export const EditorInfo = memo(({ collabState, users }: EditorInfoProps) => {
  return (
    <div className="flex items-center">
      <div className="mr-2 flex items-center gap-2">
        {collabState === 'disconnected' && (
          <img
            src="/icons/disconnected.svg"
            alt=""
          />
        )}
        <span className="text-xs font-medium text-zinc-600">{getConnectionText(collabState)}</span>
      </div>
      {collabState === 'connected' && (
        <div className="flex flex-row items-center">
          <div className="relative ml-3 flex flex-row items-center">
            {users.map((user: EditorUser) => (
              <div
                key={user.clientId}
                className="-ml-3"
              >
                <Tooltip title={user.name}>
                  <img
                    className="h-8 w-8 rounded-full border border-white dark:border-black"
                    src={
                      user.avatar ||
                      `https://api.dicebear.com/7.x/notionists-neutral/svg?seed=${
                        user.name
                      }&backgroundColor=${user?.color?.replace('#', '')}`
                    }
                    alt="avatar"
                  />
                </Tooltip>
              </div>
            ))}
            {users.length > 3 && (
              <div className="-ml-3">
                <div className="flex h-8 w-8 items-center justify-center rounded-full border border-white bg-[#FFA2A2] text-xs font-bold leading-none dark:border-black">
                  +{users.length - 3}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

EditorInfo.displayName = 'EditorInfo';
