import { PageHeader, PageHeaderProps } from '@/layouts/PageWithHeaderLayout/components/PageHeader.tsx';
import { ReactNode } from 'react';

interface PageWithHeaderLayoutProps {
  header: PageHeaderProps;
  children: ReactNode;
  subHeader?: ReactNode;
}

export function PageWithHeaderLayout({ header, children, subHeader }: PageWithHeaderLayoutProps) {
  return (
    <>
      <main className="flex h-svh w-full flex-col">
        <PageHeader {...header} />

        <div className="relative">
          <div className="page-wrapper">
            {subHeader}
            <div className="page-body">{children}</div>
          </div>
        </div>
      </main>
    </>
  );
}
