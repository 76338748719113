import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

import { Button } from '@/components/tiptap/components/ui/Button';
import { Panel, PanelHeadline } from '@/components/tiptap/components/ui/Panel';
import { Textarea } from '@/components/tiptap/components/ui/Textarea';
import { Icon } from '@/components/tiptap/components/ui/Icon';

import { AiTone, AiToneOption } from '@/components/tiptap/components/BlockEditor/types';
import { tones } from '@/components/tiptap/lib/constants';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { Toolbar } from '@/components/tiptap/components/ui/Toolbar';
import { Surface } from '@/components/tiptap/components/ui/Surface';
import { DropdownButton } from '@/components/tiptap/components/ui/Dropdown';
import { marked } from 'marked';
import { useAccessToken } from '@/api/get-access-token.ts';

export interface DataProps {
  text: string;
  addHeading: boolean;
  tone?: AiTone;
  textUnit?: string;
  textLength?: string;
  language?: string;
}

export const AiWriterView = ({ editor, node, getPos, deleteNode }: NodeViewWrapperProps) => {
  const [data, setData] = useState<DataProps>({
    text: '',
    tone: undefined,
    textLength: undefined,
    addHeading: false,
    language: undefined,
  });
  const currentTone = tones.find((t) => t.value === data.tone);
  const [previewText, setPreviewText] = useState<string | undefined>(undefined);
  const textareaId = useMemo(() => uuid(), []);
  const { getAccessToken } = useAccessToken();

  const generateText = useCallback(async () => {
    const { text: dataText, tone } = data;

    if (!data.text) {
      toast.error('Please enter a description');

      return;
    }

    const payload = {
      message: dataText,
      model: 'gpt-4o',
    };

    if (tone) {
      payload.message = `${dataText}. Use tone: ${tone}`;
    }

    setPreviewText('');

    await fetchEventSource(`${import.meta.env.VITE_API_SERVER}/lang-chain/see`, {
      method: 'POST',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(payload),
      onmessage: (event) => {
        setPreviewText((prevState) => `${prevState ?? ''}${event.data}`);
      },
      onerror: (event) => {
        const errorMessage = event?.response?.data?.error;
        const message = errorMessage !== 'An error occurred' ? `An error has occured: ${errorMessage}` : errorMessage;

        toast.error(message);
      },
    });
  }, [data]);

  const insert = useCallback(async () => {
    const from = getPos();
    const to = from + node.nodeSize;

    const text = await marked(previewText!);

    editor.chain().focus().insertContentAt({ from, to }, text).run();
  }, [editor, previewText, getPos, node.nodeSize]);

  const discard = useCallback(() => {
    deleteNode();
  }, [deleteNode]);

  const onTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData((prevData) => ({ ...prevData, text: e.target.value }));
  }, []);

  const onUndoClick = useCallback(() => {
    setData((prevData) => ({ ...prevData, tone: undefined }));
  }, []);

  const createItemClickHandler = useCallback((tone: AiToneOption) => {
    return () => {
      setData((prevData) => ({ ...prevData, tone: tone.value }));
    };
  }, []);

  return (
    <NodeViewWrapper data-drag-handle>
      <Panel
        noShadow
        className="w-full"
      >
        <div className="flex flex-col p-1">
          {previewText && (
            <>
              <PanelHeadline>Preview</PanelHeadline>
              <div
                className="relative mb-4 ml-2.5 max-h-[14rem] overflow-y-auto border-l-4 border-neutral-100 bg-white px-4 text-base text-black dark:border-neutral-700 dark:bg-black dark:text-white"
                dangerouslySetInnerHTML={{ __html: previewText }}
              />
            </>
          )}
          <div className="flex flex-row items-center justify-between gap-1">
            <PanelHeadline asChild>
              <label htmlFor={textareaId}>Prompt</label>
            </PanelHeadline>
          </div>
          <Textarea
            id={textareaId}
            value={data.text}
            onChange={onTextAreaChange}
            placeholder={'Tell me what you want me to write about.'}
            required
            className="mb-2"
          />
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="flex w-auto justify-between gap-1">
              <Dropdown.Root>
                <Dropdown.Trigger asChild>
                  <Button variant="tertiary">
                    <Icon name="Mic" />
                    {currentTone?.label || 'Change tone'}
                    <Icon name="ChevronDown" />
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Portal>
                  <Dropdown.Content
                    side="bottom"
                    align="start"
                    asChild
                  >
                    <Surface className="min-w-[12rem] p-2">
                      {!!data.tone && (
                        <>
                          <Dropdown.Item asChild>
                            <DropdownButton
                              isActive={data.tone === undefined}
                              onClick={onUndoClick}
                            >
                              <Icon name="Undo2" />
                              Reset
                            </DropdownButton>
                          </Dropdown.Item>
                          <Toolbar.Divider horizontal />
                        </>
                      )}
                      {tones.map((tone) => (
                        <Dropdown.Item
                          asChild
                          key={tone.value}
                        >
                          <DropdownButton
                            isActive={tone.value === data.tone}
                            onClick={createItemClickHandler(tone)}
                          >
                            {tone.label}
                          </DropdownButton>
                        </Dropdown.Item>
                      ))}
                    </Surface>
                  </Dropdown.Content>
                </Dropdown.Portal>
              </Dropdown.Root>
            </div>
            <div className="flex w-auto justify-between gap-1">
              {previewText && (
                <Button
                  variant="ghost"
                  className="text-red-500 hover:bg-red-500/10 hover:text-red-500"
                  onClick={discard}
                >
                  <Icon name="Trash" />
                  Discard
                </Button>
              )}
              {previewText && (
                <Button
                  variant="ghost"
                  onClick={insert}
                  disabled={!previewText}
                >
                  <Icon name="Check" />
                  Insert
                </Button>
              )}
              <Button
                variant="primary"
                onClick={generateText}
                style={{ whiteSpace: 'nowrap' }}
              >
                {previewText ? <Icon name="Repeat" /> : <Icon name="Sparkles" />}
                {previewText ? 'Regenerate' : 'Generate text'}
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    </NodeViewWrapper>
  );
};
