import { useApi } from '@/api/api.ts';

/**
 * workaround for tiptap, because tiptap is not hook based
 */
export const useTiptapResourceChunks = () => {
  const { getFetcher } = useApi();

  const tiptapResourceChunks = async (fileId: string, type: 'smartscraper' | 'input') => {
    return (await getFetcher(`/qdrant-connector/read-collection/${fileId}/${type}`)) as unknown as string[];
  };

  return {
    tiptapResourceChunks,
  };
};
