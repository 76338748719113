import { useCustomerPortal } from '@/api/pricing/customer-portal';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { SettingsSubHeader } from '../settings/components/SettingsSubHeader';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { UpsellProductsSection } from './components/UpsellProductsSection.tsx';
import { useStripeCustomerSession } from '@/api/pricing/get-stripe-customer-session.ts';
import { useUserPlan } from '@/api/pricing/get-user-plan.ts';
import { useOrganizations } from '@/api/user/get-organizations.ts';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}
export const PricingPage = () => {
  const { user } = useAuth0();
  const { customerPortal } = useCustomerPortal();
  const { userPlan } = useUserPlan();
  const { clientSecret, stripeCustomerLoading } = useStripeCustomerSession();
  const { organizations } = useOrganizations();

  const pricingTableId = import.meta.env.VITE_STRIPE_PRICING_TABLE_ID;
  const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

  const getPayer = () => {
    if (user?.org_id) {
      const organization = organizations?.find((org) => org.id === user.org_id);
      return (
        organization?.payerId?.replace('|', '_') ?? `${user?.sub?.replace('|', '_')}&${user?.org_id.replace('|', '_')}`
      );
    }
    if (user?.sub) {
      return user?.sub.replace('|', '_');
    }
  };

  return (
    <PageWithHeaderLayout
      subHeader={<SettingsSubHeader />}
      header={{
        breadcrumbs: [{ label: 'Settings', href: '#' }],
        icon: '/settings-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Billing</title>
        <script
          async
          src="https://js.stripe.com/v3/pricing-table.js"
        ></script>
      </Helmet>

      <h2 className="mb-6 flex items-center gap-4">
        <span className="text-h2-desktop">Plan</span>
        {!!userPlan && (
          <>
            <div className="flex items-center gap-2 rounded-md bg-tint-purple px-2 py-1">
              <img
                src="/rocket.svg"
                alt=""
              />
              <span className="text-button-md uppercase">{userPlan?.name}</span>
            </div>
            <a
              href={customerPortal as string}
              target="_blank"
              className="link link--md flex items-center gap-2"
              rel="noreferrer"
            >
              <span>Billing portal</span>
              <img
                src="/open-in-new-tab-blue.svg"
                alt=""
              />
            </a>
          </>
        )}
      </h2>

      {user && !stripeCustomerLoading && (
        <>
          <section className="min-h-[742px]">
            <stripe-pricing-table
              pricing-table-id={pricingTableId}
              publishable-key={publishableKey}
              client-reference-id={getPayer()}
              customer-session-client-secret={clientSecret}
            ></stripe-pricing-table>
          </section>

          {!!userPlan && <UpsellProductsSection />}
        </>
      )}
    </PageWithHeaderLayout>
  );
};
