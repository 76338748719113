import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect } from 'react';
import { useGlobalLoader } from '../components/GlobalLoader/global-loader.store.ts';
import { useSearchParams } from 'react-router-dom';
import { useAcceptInvitation } from '@/api/organization/accept-invitation.ts';
import { useDebouncedCallback } from 'use-debounce';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useRefreshAuth0Token } from '@/helpers/refresh-auth0-token.ts';
import { usePostHog } from 'posthog-js/react';
import { useTriggerUserEvent } from '@/api/user-events/api.ts';
import { UserEvent } from '@/api/user-events/model.ts';
import { useInvitationData } from '@/state/app-store.ts';
import toast from 'react-hot-toast';
import TagManager from 'react-gtm-module';

export function AuthGuard({ children }: { children: ReactNode }) {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [searchParams] = useSearchParams();
  const { acceptInvitation } = useAcceptInvitation();
  const debauncedHandleAcceptInvitation = useDebouncedCallback(handleAcceptInvitation, 200);
  const { listenerOn } = useWebSocketMessageListener();
  const { refreshAuth0Token } = useRefreshAuth0Token();
  const posthog = usePostHog();
  const { trigerUserEvent } = useTriggerUserEvent();
  const { invitationData, setInvitationData } = useInvitationData();

  function handleAcceptInvitation() {
    const invitationId = searchParams.get('invitation') ?? invitationData?.invitationId;
    const organizationId = searchParams.get('organization') ?? invitationData?.organizationId;

    if (invitationId && organizationId) {
      showLoader('Accepting invitation...');
      acceptInvitation(organizationId, invitationId)
        .then(() => {
          hideLoader();
          setInvitationData(null);
          searchParams.delete('invitation');
          searchParams.delete('organization');
          toast.success('Invitation accepted!');
        })
        .catch(() => {
          hideLoader();
          setInvitationData(null);
          searchParams.delete('invitation');
          searchParams.delete('organization');
        });
    }
  }

  const trySavingInvitationDataToStore = () => {
    const invitationId = searchParams.get('invitation');
    const organizationId = searchParams.get('organization');

    if (invitationId && organizationId) {
      setInvitationData({ organizationId, invitationId });
    }
  };

  const updateDataLayer = () => {
    const payment = searchParams.get('payment');
    const plan = searchParams.get('plan');
    const price = searchParams.get('price');

    if (!user?.sub) return;

    if (user?.['df/applicationLoginsCount'] <= 1) {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'sign_up',
          user_id: user.sub,
          email: user.email,
          registration_method: user.sub.includes('auth0') ? 'email' : 'google',
          registration_date: new Date().toISOString(), // Data rejestracji
        },
      });
    } else {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'log_in',
          user_id: user.sub,
          email: user.email,
          login_method: user.sub.includes('auth0') ? 'email' : 'google',
        },
      });
    }

    if (payment && payment === 'success') {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'subscription',
          user_id: user.sub,
          email: user.email,
          plan_name: plan,
          plan_value: price,
          currency: 'USD',
          subscription_date: new Date().toISOString(),
        },
      });
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      trySavingInvitationDataToStore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user?.sub) return;

    updateDataLayer();

    const { listenerOff } = listenerOn(`refresh-token:${user.sub}`, () => refreshAuth0Token());
    posthog?.identify(user.sub, {
      email: user.email,
    });

    trigerUserEvent(UserEvent.user_logged_in);

    return () => {
      listenerOff();
    };
  }, [user?.sub]);

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, isAuthenticated, hideLoader, showLoader]);

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    debauncedHandleAcceptInvitation();
    return <>{children}</>;
  } else {
    loginWithRedirect({ authorizationParams: { redirect_uri: window.location.origin } });
  }
}
