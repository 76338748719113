import { WebSearcherNewSearchForm } from './WebSearcherNewSearchForm.tsx';
import { WebSearcherRoomChat } from './WebSearcherRoomChat.tsx';
import { WebSearcherPanelMenu } from './WebSearcherPanelMenu.tsx';
import { useEffect, useRef, useState } from 'react';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';
import { useParams } from 'react-router-dom';
import { useOpenOnboardingModal } from '@/helpers/open-onboarding-modal.ts';
import { useDebouncedCallback } from 'use-debounce';

export function WebSearcherPanel({
  webSearcherRooms,
  onboardingMode,
}: {
  webSearcherRooms: WebSearcherRoom[];
  onboardingMode?: boolean;
}) {
  const { roomId } = useParams();
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const { openOnboardingModal } = useOpenOnboardingModal();
  const currentRoom = onboardingMode
    ? webSearcherRooms[0]
    : webSearcherRooms.find((room) => room.id === Number(roomId));
  const [bottomReached, setBottomReached] = useState(false);
  const debouncedOpenOnboardingModal = useDebouncedCallback(openOnboardingModal, 10000);

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo(0, 0);
    }
  }, [roomId]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (!onboardingMode) return;

    const target = e.target as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const percentOfScrolledView = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100);
    if (percentOfScrolledView > 90 && !bottomReached) {
      setBottomReached(true);
      debouncedOpenOnboardingModal();
    }
  };

  return (
    <>
      <div className="grid h-full w-full grid-cols-[auto,minmax(0,1fr)] rounded-2xl bg-white">
        <WebSearcherPanelMenu
          onboardingMode={onboardingMode}
          webSearcherRooms={webSearcherRooms}
        />

        <div
          className="relative overflow-auto"
          ref={scrollContainer}
          onScroll={handleScroll}
        >
          {currentRoom ? (
            <WebSearcherRoomChat room={currentRoom} />
          ) : (
            <div className="h-full w-full p-8">
              <WebSearcherNewSearchForm />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
