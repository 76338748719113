import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { FormikProvider, useFormik } from 'formik';
import { Button, Label, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { useCreateSegment } from '@/api/segments/create-segment.ts';
import { useUpdateSegment } from '@/api/segments/update-segment.ts';
import { Segment } from '@/models/segment.interface.ts';
import { mutate } from 'swr';
import { useDataRoomId } from '@/state/app-store.ts';

export const SegmentModalBody = () => {
  const { createSegment } = useCreateSegment();
  const { updateSegment } = useUpdateSegment();
  const { showLoader, hideLoader } = useGlobalLoader();
  const dataRoomId = useDataRoomId();
  const { closeModal, context } = useGlobalModal<{ segment: Segment }>();
  const segment = context?.segment;
  const isEditMode = !!segment;

  const formik = useFormik<{ segmentName: string }>({
    initialValues: {
      segmentName: isEditMode ? segment.name : '',
    },
    onSubmit: async ({ segmentName }) => {
      closeModal();
      showLoader();
      isEditMode ? await updateSegment(segment.id, segmentName) : await createSegment(segmentName);
      await mutate(`/persona-canvas/segment/${dataRoomId}`);
      hideLoader();
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6">
            <div className="mt-6">
              <Label
                htmlFor="segmentName"
                value="Segment Name"
              />
              <TextInput
                {...getDfFlowbiteFieldProps('segmentName', formik)}
                placeholder="Enter segment name"
              />
            </div>
          </div>

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!formik.values.segmentName}
            >
              {isEditMode ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
};
