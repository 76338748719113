import { cn } from '@/helpers/cn';
import { Badge, Card } from 'flowbite-react';
import { ReactNode } from 'react';
import { DfTooltip } from '@/components/DfTooltip/DfTooltip.tsx';

interface ComingSoonProjectItemListProps {
  cards: ReactNode[];
  addButtonClass: string;
  iconClass: string;
  borderClass: string;
  title: string;
  badgeColor: string;
}

export function ComingSoonProjectItemList({
  cards,
  addButtonClass,
  iconClass,
  title,
  borderClass,
  badgeColor,
}: ComingSoonProjectItemListProps) {
  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className={cn(`rounded-lg p-2 ${iconClass}`)}>
            <img
              src="/fact-check-black.svg"
              alt=""
            />
          </div>
          <h2 className="text-h4-desktop">{title}</h2>
        </div>
        <Badge
          color={badgeColor}
          size="lg"
        >
          Coming soon!
        </Badge>
      </div>

      <div className={cn('flex gap-4')}>
        <div className="flex h-[112px] items-stretch">
          <Card
            className={cn(`${addButtonClass} ${borderClass} w-[112px] cursor-not-allowed`)}
            data-tooltip-id={`coming-soon-${title}`}
          >
            <div className="flex size-full items-center justify-center">
              <img
                src="/add-black.svg"
                width={32}
                height={32}
                alt=""
              />
            </div>
          </Card>
        </div>
        <DfTooltip
          id={`coming-soon-${title}`}
          content="Coming soon!"
          place="right"
        />

        <div className="grid min-w-0 grow grid-cols-3 gap-4">{cards.map((card) => card)}</div>
      </div>
    </>
  );
}
