import { Button, TextInput } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { useState } from 'react';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';

export const OnboardingStarter = () => {
  const [url, setUrl] = useState('https://www.digitalfirst.ai/');
  const navigate = useNavigate();

  const startOnboarding = (onboardingProcessType: OnboardingProcessType, option: string) => {
    navigate(buildUrl([], undefined, { onboardingProcessType, url, option }));
  };

  return (
    <div className="flex h-screen flex-col items-center gap-8 overflow-auto">
      <h1 className="mb-10 mt-20 block rounded-3xl border border-primary-default bg-primary-100 px-8 py-4 text-center text-h1-desktop">
        UAT Onboarding test panel
      </h1>
      <div className="mb-12">
        <h2 className="mb-8 text-center text-h2-desktop">URL</h2>
        <div className="flex justify-center gap-3">
          <TextInput
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-[500px]"
            placeholder="Enter URL"
          />
        </div>
      </div>
      {[
        {
          label: 'AI Strategy',
          type: OnboardingProcessType.FLOW,
          options: [
            { label: 'Create marketing campaign', value: 323 },
            { label: 'Market Trends Analysis', value: 159 },
            { label: 'Identify B2B Buyer Persona', value: 213 },
          ],
        },
        {
          label: 'Market Research',
          type: OnboardingProcessType.WEB_SEARCHER,
          options: [
            { label: 'Company overview', value: 'Company overview' },
            { label: 'Competition Analysis', value: 'Competition Analysis' },
            { label: 'New opportunities', value: 'New opportunities' },
          ],
        },
        {
          label: 'Business canvases',
          type: OnboardingProcessType.CANVAS,
          options: [
            { label: 'Business Model Canvas', value: 7 },
            { label: 'Lean Model Canvas', value: 3 },
            { label: 'Customer Persona Canvas', value: 8 },
          ],
        },
      ].map((optionGroup, index) => (
        <div
          className="mb-12"
          key={index}
        >
          <h2 className="mb-8 text-center text-h2-desktop">{optionGroup.label}</h2>
          <div className="flex justify-center gap-3">
            {optionGroup.options.map((option, i) => (
              <Button
                onClick={() => startOnboarding(optionGroup.type, String(option.value))}
                key={i}
                className="w-[300px]"
                size="lg"
                color="primary"
                disabled={!url}
              >
                {option.label}
              </Button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
