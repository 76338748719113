import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { SettingsSubHeader } from './components/SettingsSubHeader';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { FormikProvider, useFormik } from 'formik';
import { Button, Checkbox, FileInput, Label, TextInput, ToggleSwitch } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { useEffect, useRef } from 'react';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useUpdateMe } from '@/api/user/update-me';
import { cn } from '@/helpers/cn';
import { useAuth0 } from '@auth0/auth0-react';
import { useRefreshAuth0Token } from '@/helpers/refresh-auth0-token';

export function UserSettings() {
  const { user } = useAuth0();
  const { refreshAuth0Token } = useRefreshAuth0Token();
  const { updateMe } = useUpdateMe();
  const { showLoader, hideLoader } = useGlobalLoader();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      logoBase64: undefined,
    },
    onSubmit: async ({ firstName, lastName, logoBase64 }, { resetForm }) => {
      showLoader('Saving user...');
      await updateMe({ family_name: lastName, given_name: firstName, pictureB64: logoBase64 });
      await refreshAuth0Token();
      resetForm();
      hideLoader();
    },
  });

  useEffect(() => {
    formik.setFieldValue('firstName', user?.given_name);
    formik.setFieldValue('lastName', user?.family_name);
    formik.setFieldValue('email', user?.email);
  }, [user]);

  const meLogo = formik.values.logoBase64 || user?.picture;
  const externalAuthentication = !user?.sub?.startsWith('auth0');

  return (
    <PageWithHeaderLayout
      subHeader={<SettingsSubHeader />}
      header={{
        breadcrumbs: [{ label: 'Settings', href: '#' }],
        icon: '/settings-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Settings</title>
      </Helmet>

      <div className="grid grid-cols-2 gap-6">
        <div className="h-fit rounded-2xl border p-6">
          <h4 className="text-h4-desktop">Account</h4>
          <div className="my-6 flex items-center gap-4">
            {meLogo ? (
              <img
                src={meLogo}
                className="size-20 h-auto rounded-full"
                alt=""
              />
            ) : (
              <DfAvatar
                firstName={formik.values.firstName || 'U'}
                lastName={formik.values.lastName || 'U'}
                size={80}
              />
            )}
            <div
              className={cn(
                'flex cursor-pointer items-center gap-2 text-caption-md text-primary-600',
                externalAuthentication && 'hidden',
              )}
              onClick={() => !externalAuthentication && fileInputRef?.current?.click()}
            >
              <img
                src="/upload-blue.svg"
                width={'16px'}
                alt=""
              />
              <span>Upload</span>
              <FileInput
                className="hidden"
                ref={fileInputRef}
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (!file) {
                    return;
                  }

                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64String = reader.result as string;
                    formik.setFieldValue('logoBase64', base64String);
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </div>
            <div
              className={cn(
                'flex cursor-pointer items-center gap-2 text-caption-md text-text-secondary',
                !formik.values.logoBase64 && !user?.picture ? 'hidden' : null,
              )}
              onClick={() => {
                formik.setFieldValue('logoBase64', null);
                if (fileInputRef.current) {
                  fileInputRef.current.files = new DataTransfer().files;
                }
              }}
            >
              <img
                src="/trash-gray.svg"
                width={'16px'}
              />
              Remove
            </div>
          </div>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Label
                  htmlFor="firstName"
                  value="First name"
                />
                <TextInput
                  {...getDfFlowbiteFieldProps('firstName', formik)}
                  placeholder="Insert first name"
                  disabled={externalAuthentication}
                />
              </div>
              <div className="mt-5">
                <Label
                  htmlFor="lastName"
                  value="Last name"
                />
                <TextInput
                  {...getDfFlowbiteFieldProps('lastName', formik)}
                  placeholder="Insert last name"
                  disabled={externalAuthentication}
                />
              </div>
              <div className="mt-5">
                <Label
                  htmlFor="email"
                  value="Email"
                />
                <TextInput
                  {...getDfFlowbiteFieldProps('email', formik)}
                  placeholder="Insert email"
                  disabled
                />
              </div>
              <div className="mt-6 flex justify-end">
                <Button
                  color="primary"
                  type="submit"
                  disabled={externalAuthentication}
                >
                  Save changes
                </Button>
              </div>
            </form>
          </FormikProvider>
        </div>

        <div className="hidden h-fit rounded-2xl border p-6">
          <h4 className="text-h4-desktop">Notifications</h4>

          <div className="mb-4 mt-6 flex items-center justify-between">
            <h5 className="text-h5-desktop">Title goes here</h5>
            <div className="mt-3 flex cursor-pointer justify-end text-link-md text-primary-default">Select all</div>
          </div>
          <div className="flex flex-col gap-5">
            {new Array(4).fill(0).map((_, index) => (
              <div
                key={index}
                className="flex items-center gap-2"
              >
                <ToggleSwitch
                  checked={false}
                  onChange={() => {}}
                  sizing={'sm'}
                />
                <div>
                  <div className="text-caption-md">Write label text here</div>
                  <div className="text-body-sm">Some helper text hereSome helper text here</div>
                </div>
              </div>
            ))}
          </div>

          <div className="mb-4 mt-6 flex items-center justify-between">
            <h5 className="text-h5-desktop">Title goes here</h5>
            <div className="mt-3 flex cursor-pointer justify-end text-link-md text-primary-default">Select all</div>
          </div>
          <div className="flex flex-col gap-5">
            {new Array(3).fill(0).map((_, index) => (
              <div
                key={index}
                className="flex items-center gap-2"
              >
                <Checkbox />
                <div className="text-caption-md">Write label text here</div>
              </div>
            ))}
          </div>

          <div className="mb-4 mt-6 flex items-center justify-between">
            <h5 className="text-h5-desktop">Title goes here</h5>
            <div className="mt-3 flex cursor-pointer justify-end text-link-md text-primary-default">Select all</div>
          </div>
          <div className="flex flex-col gap-5">
            {new Array(3).fill(0).map((_, index) => (
              <div
                key={index}
                className="flex items-center gap-2"
              >
                <Checkbox />
                <div className="text-caption-md">Write label text here</div>
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-end">
            <Button
              color="primary"
              type="submit"
            >
              Save changes
            </Button>
          </div>
        </div>
      </div>
    </PageWithHeaderLayout>
  );
}
