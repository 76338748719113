import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';

interface OnboardingStoreModel {
  unFinishedOnboardingProcesses: {
    [userSub: string]: OnboardingProcessType[];
  };
  onboardingScrappedUrl: {
    [userSub: string]: string;
  };
  onboardingFormData: {
    url: string;
    onboardingProcessType: OnboardingProcessType;
    option: string;
  } | null;
}

const onboardingStore = create<OnboardingStoreModel>()(
  persist(
    (): OnboardingStoreModel => ({
      unFinishedOnboardingProcesses: {},
      onboardingScrappedUrl: {},
      onboardingFormData: null,
    }),
    {
      name: 'onboarding-user-data',
    },
  ),
);

export const useUnFinishedOnboardingProcesses = (userSub: string) => {
  return onboardingStore((state) => state.unFinishedOnboardingProcesses[userSub] ?? []);
};
export const useOnboardingScrappedUrl = (userSub: string) => {
  return onboardingStore((state) => state.onboardingScrappedUrl[userSub] ?? '');
};
export const useOnboardingFormData = () => onboardingStore((state) => state.onboardingFormData);

export const setUnFinishedOnboardingProcesses = (userSub: string, onboardingProcesses: OnboardingProcessType[]) => {
  onboardingStore.setState((state) => ({
    ...state,
    unFinishedOnboardingProcesses: {
      ...state.unFinishedOnboardingProcesses,
      [userSub]: onboardingProcesses,
    },
  }));
};
export const setOnboardingScrappedUrl = (userSub: string, url: string) => {
  onboardingStore.setState((state) => ({
    ...state,
    onboardingScrappedUrl: {
      ...state.onboardingScrappedUrl,
      [userSub]: url,
    },
  }));
};
export const setOnboardingFormData = (onboardingFormData: OnboardingStoreModel['onboardingFormData']) => {
  onboardingStore.setState({ onboardingFormData });
};
