import { FormikProvider, useFormik } from 'formik';
import { Button, Label, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useCreateWorkspace } from '@/api/workspace/create-workspace.ts';
import { Urls } from '@/consts/urls.ts';
import { useNavigate } from 'react-router-dom';

export function CreateWorkspaceModalBody() {
  const { createWorkspace } = useCreateWorkspace();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal } = useGlobalModal();
  const navigate = useNavigate();

  const formik = useFormik<{ workspaceName: string }>({
    initialValues: {
      workspaceName: '',
    },
    onSubmit: async ({ workspaceName }) => {
      closeModal();
      showLoader('Creating workspace...');
      await createWorkspace(workspaceName);
      navigate(Urls.DASHBOARD);
      hideLoader();
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6">
            <div className="mt-6">
              <Label
                htmlFor="workspaceName"
                value="Workspace Name"
              />
              <TextInput
                {...getDfFlowbiteFieldProps('workspaceName', formik)}
                placeholder="Enter workspace name"
              />
            </div>
          </div>

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!formik.values.workspaceName}
            >
              Create
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}
