import { StripeUpsellProductName } from '@/enums/StripeUpsellProductName.enum.ts';
import { StripePlanLimit } from '@/models/stripe-plan-limit.interface.ts';

export const getPlanLimitForUpsellProduct = (
  upsellProductName: StripeUpsellProductName,
  planLimits: StripePlanLimit[],
): StripePlanLimit => {
  switch (upsellProductName) {
    case StripeUpsellProductName.DF_TOKEN:
      return planLimits.find((limit) => limit.name === 'dfCredits')!;
    case StripeUpsellProductName.WORKSPACE:
      return planLimits.find((limit) => limit.name === 'workspacesCount')!;
    case StripeUpsellProductName.STORAGE:
      return planLimits.find((limit) => limit.name === 'storageLimit')!;
  }
};
