import { Organization } from '@/models/organization.interface.ts';
import useSWR from 'swr';
import { useApi } from '@/api/api.ts';
import { useAuth0 } from '@auth0/auth0-react';

export const useOrganizations = () => {
  const { getFetcher } = useApi();
  const { user } = useAuth0();

  const { data } = useSWR<Organization[]>(`/user/organizations`, getFetcher);

  return {
    organizations: data,
    organizationsCreatedByUser: data?.filter((org) => org.payerId === user?.sub),
  };
};
