import { Auth0Provider } from '@auth0/auth0-react';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Flowbite } from 'flowbite-react';
import { dfFlowbiteTheme } from './dfFlowbiteTheme.const.ts';
import ReactDOM from 'react-dom/client';
import './index.scss';
import React, { useEffect } from 'react';
import { router } from './router.tsx';
import { Settings } from 'luxon';
import * as Sentry from '@sentry/react';
import { setGoogleTagManager } from './external-prod-scripts.tsx';
import { PostHogProvider } from 'posthog-js/react';

if (import.meta.env.VITE_NODE_ENV === 'production') {
  setGoogleTagManager();
}

Sentry.init({
  dsn: 'https://056e83c488b94906955934cfa653036c@sentry.seedigitalfirst.eu/13',
  environment: import.meta.env.VITE_NODE_ENV,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Settings.defaultLocale = 'en';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <PostHogProvider
    apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
    options={{ api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST }}
  >
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.href,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
      }}
      cacheLocation="localstorage"
    >
      <Flowbite theme={dfFlowbiteTheme}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </Flowbite>
    </Auth0Provider>
  </PostHogProvider>,
);
