import { AccordionInfo } from '@/components/AccordionInfo.tsx';
import { FileDragAndDropInput } from '@/components/FileDragAndDropInput.tsx';

import { Button } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface.ts';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { GLOBAL_LOADER_MESSAGES } from '@/components/GlobalLoader/global-loader-messages.const.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useAddResource } from '@/api/inputs/add-resource.ts';

export function DataRoomAddFilesModalBody() {
  const { addResource } = useAddResource();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal } = useGlobalModal();

  const formik = useFormik<{ files: File[] }>({
    initialValues: {
      files: [],
    },
    onSubmit: async ({ files }: { files: File[] }) => {
      const payloads: AddResourcePayload[] = await inputsPayloadMapper[InputType.File](files);
      closeModal();
      showLoader(GLOBAL_LOADER_MESSAGES.AddingData);
      await Promise.all(payloads.map((payload) => addResource(payload)));
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <AccordionInfo
        question="What type of files should I provide?"
        answer="Whether it's your latest marketing campaigns, a blog post, or a brief on a new product launch, feel free to share them with us. Your information is secure, and our AI will utilize it to gain a deeper understanding of your business."
      />

      <form onSubmit={formik.handleSubmit}>
        <div className="mt-6">
          <FileDragAndDropInput
            id="fileDragAndDrop"
            onChange={(files) => formik.setFieldValue('files', files)}
            asContainer={true}
          >
            <div className="mt-2 flex flex-col items-center justify-center gap-2">
              <div className="text-body-md text-primary-default">Click to upload</div>
              <p className="text-body-md">TXT, DOCX, PPTX, CSV, PDF, MP3, MP4, MD</p>
              <p className="text-body-sm">Max. File Size: 50MB</p>
            </div>
          </FileDragAndDropInput>
        </div>

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={formik.values.files.length === 0}
          >
            Add
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
