import { AuthGuard } from '@/guards/AuthGuard.tsx';
import { AuthorizedAppInitializer } from '@/guards/AuthorizedAppInitializer.tsx';
import { MainLayout } from '@/layouts/MainLayout/MainLayout.tsx';
import { Outlet } from 'react-router-dom';
import { WebSocketErrorListener } from '@/components/WebSocketErrorListener.tsx';
import { StoreFiller } from '@/components/StoreFiller.tsx';
import { OnboardingModeRedirectGuard } from '@/guards/OnboardingModeRedirectGuard.tsx';

export function AuthorizedApp() {
  return (
    <>
      <OnboardingModeRedirectGuard>
        <AuthGuard>
          <WebSocketErrorListener>
            <AuthorizedAppInitializer>
              <StoreFiller>
                <MainLayout>
                  <Outlet />
                </MainLayout>
              </StoreFiller>
            </AuthorizedAppInitializer>
          </WebSocketErrorListener>
        </AuthGuard>
      </OnboardingModeRedirectGuard>
    </>
  );
}
