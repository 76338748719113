import { useCanvases } from '@/api/canvases/api.ts';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';

export const OnboardingCanvasInitializer = () => {
  const { canvases } = useCanvases();
  const navigate = useNavigate();

  useEffect(() => {
    if (canvases) {
      navigate(buildUrl([Urls.ONBOARDING, Urls.CANVASES, Urls.CANVAS], { canvasId: canvases[0]?.id }));
    }
  }, [canvases]);

  return <></>;
};
