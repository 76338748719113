import { cn } from '@/helpers/cn';
import { DfAvatar } from './DfAvatar.tsx';
import { User } from '@/models/user.interface.ts';

export function AvatarList({
  users,
  maxDisplayingUsers,
  size = 24,
}: {
  users: User[];
  maxDisplayingUsers: number;
  size?: number;
}) {
  const translation = size / 3;
  const borderWidth = 2;

  const notDisplayingUsers = users.length - maxDisplayingUsers;
  const renderedCirclesCount = notDisplayingUsers > 0 ? maxDisplayingUsers + 1 : users.length;
  const containerInitWidth = renderedCirclesCount * size;
  const containerShrinkWidth = containerInitWidth - (renderedCirclesCount - 1) * translation;

  return (
    <div
      className="relative flex items-start"
      style={{ width: containerShrinkWidth }}
    >
      {users.slice(0, maxDisplayingUsers).map((user, index) =>
        user?.picture ? (
          <img
            key={index}
            src={user?.picture}
            className="size-6 rounded-full"
          />
        ) : (
          <div
            key={index}
            className={cn('shrink-0 rounded-full')}
            style={{
              transform: `translateX(-${index * translation}px)`,
              borderWidth: borderWidth,
              borderColor: 'white',
              width: size,
              height: size,
            }}
          >
            <DfAvatar
              firstName={user.given_name}
              lastName={user.family_name}
              size={size - borderWidth * 2}
            />
          </div>
        ),
      )}
      {notDisplayingUsers > 0 && (
        <div
          className={cn('flex shrink-0 items-center justify-center rounded-full border-[2px] bg-primary-600')}
          style={{
            transform: `translateX(-${maxDisplayingUsers * translation}px)`,
            borderColor: 'white',
            width: size,
            height: size,
          }}
        >
          <span className="text-[11px] text-white">+{notDisplayingUsers}</span>
        </div>
      )}
    </div>
  );
}
