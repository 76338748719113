import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Avatar, Card } from 'flowbite-react';
import { dateToRelativeString } from '@/helpers/date-to-relative-string.ts';
import { setCurrentWorkspace, useCurrentWorkspace } from '@/state/app-store.ts';
import { Workspace } from '@/models/workspace.interface.ts';
import { cn } from '@/helpers/cn';

export const WorkspaceCard = ({ workspace }: { workspace: Workspace }) => {
  const currentWorkspace = useCurrentWorkspace();
  const navigate = useNavigate();

  const handleSelectWorkspace = () => {
    setCurrentWorkspace(workspace);
    navigate(buildUrl([Urls.DASHBOARD]));
  };

  return (
    <>
      <button onClick={handleSelectWorkspace}>
        <Card className={cn({ 'outline outline-primary-default': currentWorkspace?.id === workspace.id })}>
          <div className="mb-4 rounded-lg">
            {!workspace.avatarUrl && (
              <div className="flex size-10 items-center justify-center rounded-lg bg-pink-300 text-h3-desktop">
                {workspace?.name.charAt(0).toUpperCase()}
              </div>
            )}
            {workspace.avatarUrl && <Avatar img={import.meta.env.VITE_ASSETS + workspace.avatarUrl} />}
          </div>
          <div className="mb-1 text-button-lg">{workspace.name}</div>
          <div className="text-body-sm text-neutrals-700">Viewed {dateToRelativeString(workspace.modifiedAt)}</div>
        </Card>
      </button>
    </>
  );
};
