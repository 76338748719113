import { useApi } from '../api';

import useSWR from 'swr';
import { StripePlanLimit } from '@/models/stripe-plan-limit.interface.ts';

export const usePlanLimits = () => {
  const { getFetcher } = useApi();
  const { data, isLoading } = useSWR<StripePlanLimit[]>('/plan-limit/quota', getFetcher);

  return {
    planLimits: data,
    isLoading,
  };
};
