import { useGetAllTools } from 'src/libs/tools/api';
import { ToolsContext } from 'src/libs/tools/ToolsContext';
import { useLeftSideNav } from '@/layouts/MainLayout/store.ts';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlowTemplate } from '@/api/flow/get-flow-template';
import TiptapEditor from '@/components/tiptap/TiptapEditor';
import { usePermissions } from '@/api/permission/get-permissions.ts';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import toast from 'react-hot-toast';
import {
  setIsTemplateEditing,
  setTiptapAccessMode,
  setTiptapCurrentView,
} from '@/components/tiptap/extensions/DigitalFirst/store.ts';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum.ts';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';
import { useAccessToken } from '@/api/get-access-token.ts';

export function FlowTemplateEditor() {
  const { flowId } = useParams<{ flowId: string }>();
  const { template, isLoading } = useFlowTemplate(Number(flowId));
  const { tools } = useGetAllTools();
  const { collapseNav } = useLeftSideNav();
  const { getAccessToken } = useAccessToken();
  const [token, setToken] = useState('');
  const { canEditFlowsLibrary } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    collapseNav();
  }, []);

  useEffect(() => {
    if (!canEditFlowsLibrary) {
      navigate(buildUrl([Urls.DASHBOARD]));
      toast.error('You do not have access to this document');
    }
    {
      setTiptapAccessMode(TiptapAccessMode.FULL);
      setTiptapCurrentView(TiptapView.EXECUTION_MODE); // execution mode is default view
      setIsTemplateEditing(true);
    }
  }, [canEditFlowsLibrary]);

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, [getAccessToken]);

  if (isLoading) {
    return;
  }

  return (
    <div className="flex h-svh w-full flex-col">
      <ToolsContext.Provider value={{ tools }}>
        {token && (
          <TiptapEditor
            docName={String(template.tiptapDocument?.id)}
            token={token}
            editable={true}
          />
        )}
      </ToolsContext.Provider>
    </div>
  );
}
