import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';

import { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { closeConfigurationEditor, useIsConfigurationEditorVisible, useTiptapCurrentView } from '../store';

import { cn } from '@/helpers/cn';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';

import { Label, Select } from 'flowbite-react';
import { ToolsContext } from 'src/libs/tools/ToolsContext';
import { Tool, ToolCategory } from 'src/libs/tools/type.model';
import { ToolForm } from 'src/libs/tools/ToolForm';
import { ConfigurationModeView } from './ConfigurationModeView';
import { ExecutionModeView } from './ExecutionModeView';
import { useUpdateToolType } from 'src/libs/tools/api';
import { useTiptapToolsByCategory } from '../../../../../../libs/tools/use-tiptap-tools-by-category.tsx';
import { usePermissions } from '@/api/permission/get-permissions.ts';
import './DFGenOutputView.scss';

export const DFGenOutputView = function DFGenOutputView(props: NodeViewWrapperProps) {
  const toolCategory = props.node.attrs.toolcategory as ToolCategory;
  const nodeId = props?.node?.attrs?.id;

  const isExecutionMode = useTiptapCurrentView() == TiptapView.EXECUTION_MODE;
  const isConfigurationEditorVisible = useIsConfigurationEditorVisible(nodeId);

  const allTools = useContext(ToolsContext);
  const toolsByCategory = useTiptapToolsByCategory(allTools?.tools || []);
  const { updateToolType } = useUpdateToolType();
  const formWrapperRef = useRef<HTMLDivElement>(null);
  const { canSeeHiddenPromptField } = usePermissions();

  const saveChanges = async () => {
    const form = formWrapperRef.current?.querySelector('form');
    const formData = new FormData(form as HTMLFormElement);

    let toolData: { [k: string]: FormDataEntryValue };

    if (canSeeHiddenPromptField) {
      toolData = Object.fromEntries(formData);
    } else {
      toolData = {
        ...Object.fromEntries(formData),
        hiddenPrompt: props?.node.attrs?.tooldata?.hiddenPrompt ? props?.node.attrs?.tooldata?.hiddenPrompt : '',
      };
    }

    props.updateAttributes({
      tooldata: toolData,
    });

    if (props.node.attrs?.selectedtoolid) {
      return closeConfigurationEditor();
    }

    closeConfigurationEditor();
  };

  useEffect(() => {
    //set default tool
    if (!props.node.attrs?.selectedtool) {
      props.node.attrs.selectedtool = toolsByCategory[toolCategory][0];
    }
  }, []);

  const getTextToDisplay = () => {
    const prompt = props.node.attrs?.tooldata?.prompt;
    const query = props.node.attrs?.tooldata?.query;
    const tool = props.node.attrs?.selectedtool;

    if (prompt) {
      return prompt;
    }

    if (query) {
      return query;
    }

    if (tool && tool.displayName) {
      return tool.displayName;
    }

    return '';
  };

  return (
    <NodeViewWrapper>
      <div className="df-node-border rounded-2xl border border-gray-divider">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="relative flex w-full flex-col">
              {!isExecutionMode ? (
                <ConfigurationModeView
                  node={props?.node}
                  updateAttributes={props.updateAttributes}
                  toolCategory={toolCategory}
                  textToDisplay={getTextToDisplay()}
                />
              ) : (
                <ExecutionModeView
                  editor={props.editor}
                  nodeId={nodeId}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* </Panel> */}
      {createPortal(
        <>
          <div
            className={cn(
              'pointer-events-none absolute top-0 z-[110] h-full w-full bg-gray-dark opacity-0 transition-opacity duration-150',
              {
                'pointer-events-auto opacity-40': isConfigurationEditorVisible,
              },
            )}
            onClick={() => closeConfigurationEditor()}
          />

          <div
            className={cn(
              'fixed bottom-0 right-0 top-0 z-[300] m-3 flex w-full max-w-[440px] flex-col rounded-3xl transition-all duration-200 ease-in-out',
              {
                'pointer-events-none translate-x-[200px] opacity-0': !isConfigurationEditorVisible,
                'pointer-events-auto translate-x-0 opacity-100': isConfigurationEditorVisible,
              },
            )}
          >
            <div className="relative flex-col justify-between rounded-t-2xl bg-gray-ultra-light p-6">
              <div className="mb-2 flex w-full justify-stretch text-h2-desktop font-medium text-gray-dark">
                <div className="grow">Configuration</div>
                <div>
                  <button
                    className="hover:opacity-70"
                    onClick={() => closeConfigurationEditor()}
                  >
                    <img
                      src="/close-side-panel.svg"
                      className="cursor-pointer"
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-dark">Define your prompt and other parameters</div>
            </div>

            <div
              ref={formWrapperRef}
              className="grow overflow-y-scroll bg-white p-8"
            >
              <div className="mb-5">
                <Label htmlFor="select-tool">Select tool</Label>
                <Select
                  id="select-tool"
                  name="select-tool"
                  value={props.node.attrs?.selectedtool?.displayName ?? ''}
                  onChange={(e) => {
                    const toolDisplayName = e.target.value;
                    const tool = allTools?.tools?.find((tool) => tool.displayName === toolDisplayName);
                    props.updateAttributes({ selectedtool: tool });

                    if (props.node.attrs?.selectedtoolid && tool) {
                      updateToolType(props.node.attrs?.selectedtoolid, tool.name);
                    }
                  }}
                >
                  {toolsByCategory[toolCategory]?.map((option: Tool) => (
                    <option key={option.name}>{option.displayName}</option>
                  ))}
                </Select>
              </div>

              {props.node.attrs?.selectedtool && (
                <ToolForm
                  tool={props.node.attrs?.selectedtool}
                  hasOutput={false}
                  hideSaveButton={true}
                  initialValue={props.node.attrs.tooldata}
                  enableReinitialize={true}
                  tiptap={{
                    turnOnTiptapInputAsPromptInput: true,
                    toc: props.editor.extensionStorage.tableOfContents,
                  }}
                />
              )}
            </div>

            <div className="flex flex-col rounded-b-2xl bg-white p-8">
              <button
                className="flex cursor-pointer justify-center rounded-lg bg-indigo-600 px-4 py-3 text-sm text-white duration-200 ease-in-out hover:bg-indigo-700"
                onClick={saveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        </>,
        document.getElementById('root')!,
      )}
    </NodeViewWrapper>
  );
};
