import { Helmet } from 'react-helmet';
import { WebSearcherPageHeader } from '../../web-searcher/components/WebSearcherPageHeader.tsx';
import { WebSearcherAnswerLoadingPlaceholder } from '../../web-searcher/components/WebSearcherAnswerLoadingPlaceholder.tsx';

export const OnboardingWebSearcherMockupPage = () => {
  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai | WebSearcher</title>
      </Helmet>
      <main className="flex h-svh w-full flex-col bg-neutrals-200">
        <WebSearcherPageHeader />
        <div className="flex h-[calc(100svh-66px)] pb-3 pr-3">
          <>
            <div className="grid h-full w-full grid-cols-[auto,minmax(0,1fr)] rounded-2xl bg-white">
              <div></div>
              <div className="relative overflow-auto">
                <div className="flex items-start justify-center px-[3.75rem] pb-[3.75rem] pt-8">
                  <div className="flex flex-col gap-10">
                    <div className="grid grid-cols-[minmax(10%,42rem),minmax(auto,22rem)] gap-12">
                      <div className="flex min-w-0 flex-col gap-10">
                        <h2
                          className="web-searcher-header mt-7 flex scroll-mt-[3.75rem] items-center gap-4 rounded-2xl p-6"
                          style={{ backgroundImage: 'url(/main-gradient-sm.jpg)', backgroundSize: 'cover' }}
                        >
                          <img
                            src="/generate-gradient-purple.svg"
                            alt=""
                          />
                          <div>
                            <h1>
                              <div className="mb-1 text-body-md text-neutrals-800">Asked question</div>
                              <div className="text-h3-desktop">This is some question</div>
                            </h1>
                          </div>
                        </h2>

                        <WebSearcherAnswerLoadingPlaceholder />
                      </div>

                      <div className="mt-7 min-w-0">
                        <div className="flex flex-col gap-6">
                          {new Array(3).fill(null).map((_, i) => (
                            <div
                              className="h-[8rem] w-full animate-pulse rounded-xl bg-neutrals-200"
                              key={i}
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </main>
    </>
  );
};
