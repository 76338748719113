import useSWR from 'swr';
import { useApi } from '../api';
import { StripeProduct } from '@/models/stripe-product.interface';

export const useMyProducts = () => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR<StripeProduct[]>('stripe/my-products', getFetcher);

  return {
    products: data,
    isLoading,
    error,
  };
};
