import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { useMemo } from 'react';
import { Persona } from '@/models/persona.interface.ts';
import { convertPersonaToPersonaDetails } from '@/helpers/convert-persona-to-persona-details.ts';

export const usePersonaDetails = (personaId: number) => {
  const { getFetcher } = useApi();

  const { data } = useSWR<Persona>(`/persona-canvas/${personaId}`, getFetcher);

  const personaDetails: PersonaDetails | undefined = useMemo((): PersonaDetails | undefined => {
    if (!data) return;

    return convertPersonaToPersonaDetails(data);
  }, [data]);

  return {
    personaDetails,
  };
};
