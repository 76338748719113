import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { Select, TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { ProjectCard } from './components/ProjectCard';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useProjects } from '@/api/projects/get-projects.ts';
import { RecommendedToolsSection } from './components/RecommendedToolsSection';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';

import { useMemo } from 'react';
import { filterEntity } from '@/helpers/filter-entity';

export function ProjectListPanel() {
  const { openModal } = useGlobalModal();
  const { projects } = useProjects();

  const formik = useFormik({
    initialValues: {
      search: '',
      modified: '',
    },
    validationSchema: object({
      search: string(),
      modified: string(),
    }),
    onSubmit: () => {},
  });

  const onProjectAdd = () => {
    openModal(MODALS.CreateProject);
  };

  const filteredProjects = useMemo(() => {
    return projects?.filter((project) => filterEntity(project, formik.values.search, formik.values.modified));
  }, [projects, formik.values.search, formik.values.modified]);

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [{ label: 'Projects', href: '#' }],
        icon: '/folder-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Projects</title>
      </Helmet>

      <h1 className="mb-6 text-h4-desktop">All projects</h1>

      <section className="mb-6">
        <FormikProvider value={formik}>
          <form
            onSubmit={formik.handleSubmit}
            className="flex justify-between"
          >
            <div className="relative">
              <TextInput
                {...getDfFlowbiteFieldProps('search', formik)}
                placeholder="Search"
                className="w-96"
                icon={HiSearch}
              />
            </div>

            <Select {...getDfFlowbiteFieldProps('modified', formik)}>
              <option>All</option>
              <option>Modified: Last 7 days</option>
              <option>Modified: Last 14 days</option>
              <option>Modified: Last 30 days</option>
            </Select>
          </form>
        </FormikProvider>
      </section>

      <section className="mt-7 rounded-xl bg-primary-100 p-4">
        <RecommendedToolsSection />
      </section>

      <section className="mt-7 grid grid-cols-4 gap-4">
        <button
          onClick={onProjectAdd}
          className="large-add-button flex min-h-[9.5rem] cursor-pointer flex-col items-center justify-center rounded-xl border border-gray-divider bg-primary-100 transition duration-300 hover:bg-primary-600 active:bg-primary-700"
        >
          <img
            src="/add-blue.svg"
            alt=""
            width={33}
          />
        </button>
        {filteredProjects &&
          filteredProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
            />
          ))}
      </section>
    </PageWithHeaderLayout>
  );
}
