import { Dropdown } from 'flowbite-react';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { ReactElement } from 'react';
import { useRemoveResource } from '@/api/inputs/remove-resource.ts';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';

export interface DropdownDataRoomItemOptionsProps {
  children: ReactElement;
  item: DataRoomItem;
  onPreview?: () => void;
}

export function DropdownDataRoomItemOptions({ children, item, onPreview }: DropdownDataRoomItemOptionsProps) {
  const { removeResource } = useRemoveResource();
  const { openDialog } = useGlobalDialog();
  const { showLoader, hideLoader } = useGlobalLoader();

  return (
    <>
      <Dropdown
        label=""
        renderTrigger={() => children}
        onClick={(e) => e.stopPropagation()}
      >
        {onPreview && item.isParsed && (
          <Dropdown.Item onClick={onPreview}>
            <img
              src="/preview-gray.svg"
              className="mr-2 size-4"
              alt=""
            />
            Preview
          </Dropdown.Item>
        )}

        <Dropdown.Item
          onClick={() => {
            openDialog(DIALOGS.DeleteAddedDataConfirmation, async () => {
              showLoader('Deleting added data...');
              await removeResource(item.id);
              hideLoader();
            });
          }}
        >
          <img
            src="/trash-gray.svg"
            className="mr-2"
            alt=""
          />
          Delete
        </Dropdown.Item>
      </Dropdown>
    </>
  );
}
