import { FormikProvider, useFormik } from 'formik';
import { Button, Label, Textarea } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useAddOrganizationMembers } from '@/api/organization/add-organization-members';

export function InviteToOrganizationModalBody() {
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal } = useGlobalModal();
  const { addOrganizationMembers } = useAddOrganizationMembers();

  const formik = useFormik<{ emailAddresses: string }>({
    initialValues: {
      emailAddresses: '',
    },
    onSubmit: async ({ emailAddresses }) => {
      showLoader('Inviting members...');
      try {
        await addOrganizationMembers(emailAddresses.split(','));
        closeModal();
      } finally {
        hideLoader();
      }
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6">
            <Label
              htmlFor="emailAddresses"
              value="Email addresses"
            />
            <Textarea
              {...getDfFlowbiteFieldProps('emailAddresses', formik)}
              placeholder="jasminesmith@example.com, mathstmith@example.com"
              rows={5}
            />
          </div>

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
            >
              Invite
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}
