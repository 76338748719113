import { findElementId } from '@/components/tiptap/extensions/DigitalFirst/helpers/findElementId.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { useSegments } from '@/api/segments/get-segments.ts';
import { Persona } from '@/models/persona.interface.ts';
import { useMemo } from 'react';
import { marked } from 'marked';
import { convertPersonaToPersonaDetails } from '@/helpers/convert-persona-to-persona-details.ts';

export const useInjectPersonasIntoPrompt = (): {
  injectPersonasIntoPrompt: (prompt: string) => string;
} => {
  const { segments } = useSegments();
  const personas = useMemo(() => {
    return segments?.flatMap((segment) => segment.personaCanvases);
  }, [segments]);

  const injectPersonasIntoPrompt = (prompt: string) => {
    const personasIds = findElementId(prompt, `[data-type="${MentionReferenceType.PERSONA}"]`);
    const mentionedPersonas: Persona[] = [];

    if (!personasIds || !personasIds.length) return prompt;

    for (const personaId of personasIds) {
      const persona = personas?.find((persona) => persona.id === +personaId);

      if (persona) {
        mentionedPersonas.push(persona);
      }
    }

    return personasToText(mentionedPersonas, prompt);
  };

  return {
    injectPersonasIntoPrompt,
  };
};

const personasToText = (mentionedPersonas: Persona[], prompt: string): string => {
  let personasPrompt = prompt;

  mentionedPersonas.forEach((mentionedPersona) => {
    let allCanvasPositionsAsText = '';
    const persona = convertPersonaToPersonaDetails(mentionedPersona);

    // replacing persona position mentions
    for (const position of persona.positions) {
      let markedPosition = '';
      if (position.value.length) {
        const markedPositionTitle = marked(`##${position.label}\n\n`) as string;
        markedPosition += markedPositionTitle;
        allCanvasPositionsAsText += markedPositionTitle;
        for (const positionValue of position.value) {
          const markedValue = marked(`${positionValue}\n\n`) as string;
          markedPosition += markedValue;
          allCanvasPositionsAsText += markedValue;
        }
      }
      const positionMention = `&amp;${persona.name}/${position.label}`;
      personasPrompt = personasPrompt.replaceAll(positionMention, markedPosition);
    }

    // replacing whole persona mentions
    const canvasMention = `&amp;${persona.name}`;
    personasPrompt = personasPrompt.replaceAll(canvasMention, allCanvasPositionsAsText);
  });

  return personasPrompt;
};
