import { cn } from '@/helpers/cn';
import { Button, TextInput } from 'flowbite-react';
import { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useChatHistory } from '@/api/chat/get-chat-history.ts';
import { useSendMessage } from '@/api/chat/send-message.ts';
import { useDeleteMessages } from '@/api/chat/delete-messages.ts';
import { useAgentPanel } from './agent-panel.store.ts';
import { v4 as uuid } from 'uuid';
import { ChatMessage } from '@/models/chat-message.interface.ts';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { mutate } from 'swr';
import { useDataRoomId } from '@/state/app-store.ts';
import { htmlToText } from '@/helpers/html-to-text.ts';
import { llmMarked } from '../../helpers/llmMarked';
import './AgentPanel.scss';

export function AgentPanel() {
  const { messages, isLoading } = useChatHistory();
  const { sendMessage } = useSendMessage();
  const { deleteMessages } = useDeleteMessages();
  const { isVisible, toggleAgentPanel } = useAgentPanel();
  const [messagesHistory, setMessagesHistory] = useState<ChatMessage[]>([]);
  const { listenerOn } = useWebSocketMessageListener();
  const dataRoomId = useDataRoomId();

  const exampleQuestions = [
    'What is the pricing plan?',
    'How does a web application work?',
    'What are the benefits of using the application?',
  ];

  const [question, setQuestion] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const onExampleQuestionClick = (index: number) => {
    setQuestion(exampleQuestions[index]);
  };

  const onQuestionSend = async () => {
    const wsChannel = 'chat-question:' + uuid();
    let messageResponse = '';

    setQuestion('');

    const message: ChatMessage = {
      text: htmlToText(question),
      type: 'user',
    };

    const messagesWithNewQuestion = [...messagesHistory, message];

    setMessagesHistory(messagesWithNewQuestion);
    await sendMessage(htmlToText(question), wsChannel);

    const { listenerOff } = listenerOn(wsChannel, (data: string | Record<string, boolean>) => {
      if (typeof data === 'string') {
        messageResponse += data;

        const messageAnswer: ChatMessage = {
          text: llmMarked(messageResponse) as string,
          type: 'bot',
        };

        setMessagesHistory([...messagesWithNewQuestion, messageAnswer]);
      } else if (data.done) {
        listenerOff();
        setTimeout(() => {
          mutate(`/chat/${dataRoomId}`);
        }, 250);
      }
    });
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = async (event) => {
    if (event.key === 'Enter') {
      await onQuestionSend();
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagesHistory]);

  useEffect(() => {
    setMessagesHistory(messages);
  }, [messages]);

  return (
    <div
      className={cn(
        'agent-panel fixed right-0 z-[1001] flex w-[420px] transform flex-col border-l border-solid border-gray-divider bg-white shadow-lg transition-transform duration-300',
        { 'translate-x-0': isVisible, 'translate-x-full': !isVisible },
      )}
    >
      <div className="flex items-center justify-between border-b border-gray-divider p-3">
        <div className="flex flex-row items-center gap-3">
          <img
            src="/df-logo.svg"
            className="rounded-full border border-gray-divider p-2"
            alt=""
          />
          <h2 className="text-subtitle-md font-semibold">DigitalFirst AI Agent</h2>
        </div>
        <div className="flex flex-row gap-2">
          <img
            src="/trash-gray.svg"
            className="cursor-pointer"
            alt=""
            onClick={deleteMessages}
          />
          <img
            src="/close-side-panel.svg"
            className="cursor-pointer"
            alt=""
            onClick={toggleAgentPanel}
          />
        </div>
      </div>
      {!isLoading && (
        <>
          {messagesHistory?.length > 0 && (
            <div className="agent-message-list flex flex-1 flex-col gap-6 overflow-auto p-3">
              {messagesHistory.map(({ type, text }, index) => (
                <div
                  key={index}
                  className={cn(
                    'flex max-w-72 flex-row-reverse items-start gap-3 p-2 text-sm',
                    { 'self-end rounded-lg rounded-br-none bg-gray-ultra-light': type === 'user' },
                    { 'justify-end': type !== 'user' },
                  )}
                >
                  <div dangerouslySetInnerHTML={{ __html: llmMarked(text) as string }}></div>
                  {type !== 'user' && (
                    <img
                      src="/df-logo-small.svg"
                      className="rounded-full border border-gray-divider p-2"
                      alt=""
                    />
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          )}
          {!messagesHistory?.length && (
            <div className="flex flex-1 flex-col items-center justify-center gap-8 p-3">
              <div className="flex flex-col items-center justify-center">
                <div className="mb-1 rounded-lg bg-tint-blue p-2">
                  <img
                    src="/chat-blue.svg"
                    alt=""
                  />
                </div>
                <div className="text-base font-medium">Ask a question</div>
              </div>
              <div className="flex w-full flex-col gap-3">
                {exampleQuestions.map((question, index) => (
                  <div
                    key={index}
                    onClick={() => onExampleQuestionClick(index)}
                    className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border border-gray-divider p-3 text-sm font-medium transition hover:bg-gray-bg active:bg-gray-bg"
                  >
                    <div>{question}</div>
                    <div className="flex text-primary-default">
                      Ask
                      <img
                        src="/arrow-up-blue.svg"
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <div className="flex flex-row gap-2 p-3">
        <TextInput
          value={question}
          onInput={(e: ChangeEvent<HTMLInputElement>) => setQuestion(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Ask a question..."
          className="flex flex-1"
        />
        <Button
          onClick={onQuestionSend}
          size="xs"
          color="primary"
        >
          <img
            src="/send-white.svg"
            alt=""
          />
        </Button>
      </div>
    </div>
  );
}
