import { marked } from 'marked';
import { CanvasDetails } from '@/api/canvases/api';

interface CanvasPositionValues {
  value: string;
  position: {
    label: string;
  };
}

interface CanvasTemplate {
  name: string;
  id: number;
}

export interface Canvas {
  id: number;
  positionValues: CanvasPositionValues[];
  template: CanvasTemplate;
}

export const canvasToText = (canvases: CanvasDetails[], prompt: string) => {
  let canvasPrompt = prompt;

  canvases.forEach((canvas) => {
    let allCanvasPositionsAsText = '';

    // replacing canvas position mentions
    for (const position of canvas.positions) {
      const markedPositionTitle = marked(`##${position.label}\n\n`) as string;
      let markedPosition = '';
      if (position.value.length) {
        markedPosition += markedPositionTitle;
        allCanvasPositionsAsText += markedPositionTitle;
        for (const positionValue of position.value) {
          const markedValue = marked(`${positionValue}\n\n`) as string;
          markedPosition += markedValue;
          allCanvasPositionsAsText += markedValue;
        }
      }
      const positionMention = `@${canvas.name}/${position.label}`;
      canvasPrompt = canvasPrompt.replaceAll(positionMention, markedPosition);
    }

    // replacing whole canvas mentions
    const canvasMention = `@${canvas.name}`;
    canvasPrompt = canvasPrompt.replaceAll(canvasMention, allCanvasPositionsAsText);
  });

  return canvasPrompt;
};
