import { ReactNode, useEffect } from 'react';
import { setUserPlan } from '../../routes/pricing/user-plan-store.ts';
import { useUserPlan } from '@/api/pricing/get-user-plan.ts';

export const StoreFiller = ({ children }: { children: ReactNode }) => {
  const { userPlan } = useUserPlan();

  useEffect(() => {
    if (userPlan) {
      setUserPlan(userPlan);
    }
  }, [userPlan]);

  return <>{children}</>;
};
