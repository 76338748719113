import { cn } from '@/helpers/cn';
import { Badge } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import { MemoryContextEmptySlot } from '@/components/MemoryContextEmptySlot.tsx';
import { MemoryContextFilledSlot } from '@/components/MemoryContextFilledSlot.tsx';
import { useMemoryContext } from '@/api/memory-context/get-memory-context.ts';
import { useParams } from 'react-router-dom';
import { Editor } from '@tiptap/react';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { sortableHandleDragEnd } from '@/helpers/sortable-handle-drag-end';

export function MemoryContextTiptapSidebar({
  isOpen,
  toggle,
  editor,
}: {
  isOpen: boolean;
  toggle: () => void;
  editor: Editor;
}) {
  const { projectId } = useParams<{ projectId: string }>();
  const { memoryContextItems, updateMemoryContext } = useMemoryContext(projectId!);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 3 },
    }),
  );

  const windowClassName = cn(
    'absolute top-0 left-0 bg-white h-full relative w-0 duration-300 transition-all z-[30]',
    !isOpen && 'border-r-transparent',
    isOpen && 'w-[25rem] border-l border-l-neutral-200',
  );

  return (
    <div className={windowClassName}>
      <div className="h-full w-full min-w-[25rem] overflow-hidden">
        <div className="relative h-full w-full overflow-y-auto p-6">
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-2">
              <img
                className="h-8 w-8 rounded-lg bg-notice-300 p-2"
                src="/pin-notice.svg"
                alt=""
              />
              <h2 className="text-h5-desktop">Memory Context</h2>
              <Badge
                size="circle"
                color="yellow"
              >
                {memoryContextItems.filter((item) => item !== null).length}
              </Badge>
            </div>
            <IconButton
              icon="/close-side-panel-black.svg"
              onClick={toggle}
            />
          </div>
          <div className="mt-6 flex flex-col gap-3">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={(e) =>
                sortableHandleDragEnd(
                  e,
                  memoryContextItems.filter((m) => !!m),
                  updateMemoryContext,
                )
              }
            >
              <SortableContext
                items={memoryContextItems.filter((m) => !!m).map((m) => m.id)}
                strategy={verticalListSortingStrategy}
              >
                {memoryContextItems.map((memoryContextItem, index) =>
                  memoryContextItem !== null ? (
                    <MemoryContextFilledSlot
                      key={index}
                      memoryContextItem={memoryContextItem}
                      index={index}
                      tiptapMode={true}
                      editor={editor}
                    />
                  ) : (
                    <MemoryContextEmptySlot
                      key={index}
                      index={index}
                    />
                  ),
                )}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>
    </div>
  );
}
