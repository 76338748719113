import { ToolCard } from './ToolCard.tsx';
import { Link } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useGetAllTools } from '../../../libs/tools/api.ts';

export function RecommendedToolsSection() {
  const { tools, isLoading } = useGetAllTools();

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-1">
          <img
            src="/generate-colorful.svg"
            alt=""
          />
          <span className="text-caption-md">Recommended tools</span>
        </div>
        <Link
          to={buildUrl([Urls.TOOLS_LIBRARY])}
          className="link link--sm"
        >
          View all
          <img
            src="/arrow-right-blue.svg"
            alt=""
          />
        </Link>
      </div>

      <div className="mt-3 grid grid-cols-5 gap-4">
        {!isLoading &&
          tools.slice(0, 5).map((tool) => (
            <ToolCard
              key={tool.name}
              tool={tool}
            />
          ))}
      </div>
    </>
  );
}
