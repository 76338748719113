import useSWR from 'swr';
import { useApi } from '../api.ts';
import { Workspace } from '@/models/workspace.interface.ts';

export const useWorkspaces = (): {
  workspaces: Workspace[];
  isLoading: boolean;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading } = useSWR('/workspace/by-user', getFetcher);

  return {
    workspaces: data ? [...data]?.sort((a, b) => b.modifiedAt.localeCompare(a.modifiedAt)) : [],
    isLoading,
  };
};
