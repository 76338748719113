import { GlobalLoader } from '@/components/GlobalLoader/GlobalLoader.tsx';
import { GlobalModal } from '@/components/GlobalModal/GlobalModal.tsx';
import { GlobalDialog } from '@/components/GlobalDialog/GlobalDialog.tsx';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { GlobalConfetti } from '@/components/GlobalConfetti/GlobalConfetti.tsx';

export function App() {
  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai</title>
      </Helmet>
      <GlobalLoader />
      <GlobalModal />
      <GlobalDialog />
      <GlobalConfetti />
      <div>
        <Toaster />
      </div>

      <Outlet />
    </>
  );
}
