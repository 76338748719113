import { Card } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { DropdownSegmentOptions } from './DropdownSegmentOptions.tsx';
import { Segment } from '@/models/segment.interface.ts';
import { useCreatePersona } from '@/api/segments/persona/create-persona.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useDeletePersona } from '@/api/segments/persona/delete-persona.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import { mutate } from 'swr';

export const SingleSegmentPanel = ({ segment }: { segment: Segment }) => {
  const { createPersona } = useCreatePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { deletePersona } = useDeletePersona();
  const { openDialog } = useGlobalDialog();
  const dataRoomId = useDataRoomId();
  const navigate = useNavigate();

  const onAddPersona = async () => {
    showLoader();
    const persona = await createPersona(segment.id);
    navigate(buildUrl([Urls.SEGMENTS, Urls.PERSONA_DETAILS], { personaId: persona.id }));
    hideLoader();
  };

  const onDeletePersona = async (personaId: number) => {
    openDialog(DIALOGS.DeletePersona, async () => {
      showLoader();
      await deletePersona(personaId);
      await mutate(`/persona-canvas/segment/${dataRoomId}`);
      hideLoader();
    });
  };

  return (
    <div>
      <h2 className="mb-4 flex items-center gap-1 text-h4-desktop">
        <span>{segment.name}</span>
        <DropdownSegmentOptions segment={segment} />
      </h2>
      <div className="grid grid-cols-[112px,1fr] items-start gap-4">
        <button
          className=""
          onClick={onAddPersona}
        >
          <Card className="size-[112px] border-primary-300 !bg-primary-200 hover:!bg-primary-300 active:!bg-primary-400">
            <div className="flex size-full items-center justify-center">
              <img
                src="/add-black.svg"
                width={32}
                height={32}
                alt=""
              />
            </div>
          </Card>
        </button>
        <div className="grid grid-cols-3 gap-4">
          {segment.personaCanvases.map((persona, i) => (
            <div
              key={i}
              className="relative h-[112px]"
            >
              <div className="absolute right-4 top-4">
                <IconButton
                  icon="/trash-gray.svg"
                  size={24}
                  alt="Delete"
                  onClick={() => onDeletePersona(persona.id)}
                />
              </div>
              <Link
                className="h-full"
                to={buildUrl([Urls.SEGMENTS, Urls.PERSONA_DETAILS], { personaId: persona.id })}
              >
                <Card className="h-full">
                  <h3 className="mb-1 text-h5-desktop">{persona.name}</h3>
                  <div className="text-body-md text-neutrals-700">{persona.description}</div>
                </Card>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
