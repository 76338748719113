import { PersonaDetails } from '@/models/persona-details.interface';
import { Card, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { useUpdatePersona } from '@/api/segments/persona/update-persona.ts';
import { IconButton } from '@/components/IconButton.tsx';
import { PersonaUpdatePayload } from '@/models/persona-update-payload.interface.ts';
import { mutate } from 'swr';

export const PersonaMainInfoCard = ({ personaDetails }: { personaDetails: PersonaDetails }) => {
  const [name, setName] = useState<string>(personaDetails.name);
  const [description, setDescription] = useState<string>(personaDetails.description);
  const [isEditing, setIsEditing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { updatePersona } = useUpdatePersona();

  const onSave = async () => {
    if (!name || !description) {
      return;
    }

    const payload: PersonaUpdatePayload = {
      name,
      description,
    };
    setIsProcessing(true);
    await updatePersona(personaDetails.id, payload);
    await mutate(`/persona-canvas/${personaDetails.id}`);
    setIsProcessing(false);
    setIsEditing(false);
  };

  return (
    <div className="relative">
      {isProcessing && (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white opacity-80">
          <img
            src="/loading-blue.svg"
            className="animate-spin-slow"
            alt=""
          />
        </div>
      )}

      <div className="absolute right-4 top-4">
        {isEditing ? (
          <>
            <IconButton
              icon="/close-gray.svg"
              onClick={() => {
                setIsEditing(false);
                setName(personaDetails.name);
                setDescription(personaDetails.description);
              }}
            />
            <IconButton
              icon="/approve-gray.svg"
              onClick={onSave}
            />
          </>
        ) : (
          <>
            <IconButton
              icon="/edit-gray.svg"
              onClick={() => {
                setIsEditing(true);
              }}
            />
          </>
        )}
      </div>

      <Card className="items-center">
        <div className="flex h-[348px] flex-col items-center justify-center">
          <img
            src="/user-black.svg"
            width={164}
            height={164}
            alt="Persona image"
            className="mb-8 size-[164px] scale-125 rounded-full"
          />
          <div className="flex h-[89px] w-full flex-col text-center">
            {isEditing ? (
              <>
                <TextInput
                  className="mb-2"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Title"
                />
                <TextInput
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Name"
                />
              </>
            ) : (
              <>
                <h2 className="mb-2 text-h3-desktop">{name}</h2>
                <div className="text-body-lg text-neutrals-700">{description}</div>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
