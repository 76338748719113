import { useRef } from 'react';

export interface AccordionInfoProps {
  question: string;
  answer: string;
}

export function AccordionInfo({ question, answer }: AccordionInfoProps) {
  const answerRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLImageElement>(null);

  return (
    <div className="overflow-hidden rounded-lg border border-primary-default bg-primary-100 py-3.5 pl-4 pr-3">
      <div
        className="flex cursor-pointer items-center"
        onClick={() => {
          if (answerRef.current && iconRef.current) {
            answerRef.current.style.maxHeight =
              answerRef.current.style.maxHeight === '0px' ? `${answerRef.current.scrollHeight}px` : '0px';

            iconRef.current.style.transform =
              iconRef.current.style.transform === 'rotate(180deg)' ? 'rotate(0deg)' : 'rotate(180deg)';
          }
        }}
      >
        <img
          src="/bulb-blue.svg"
          className="mr-3"
          alt=""
        />
        <div className="mr-4 grow text-subtitle-sm font-medium">{question}</div>
        <img
          ref={iconRef}
          src="/chevron-down-gray.svg"
          className="duration-300"
          alt=""
        />
      </div>

      <div
        ref={answerRef}
        className="overflow-hidden transition-all duration-300"
        style={{ maxHeight: '0px' }}
      >
        <div className="pt-3.5 text-body-sm text-text-secondary">{answer}</div>
      </div>
    </div>
  );
}
