import { FormikProvider, useFormik } from 'formik';
import { ToolFormikFields } from './type.model';
import { Button } from 'flowbite-react';
import { useState } from 'react';
import { FormikDynamicFormItem } from './FormikDynamicFormItem';
import { cn } from '@/helpers/cn';
import { TOC } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';

export interface FormicDynamicFormProps {
  initialValue: Record<string, string>;
  validationSchema: object;
  formikBaseFields: ToolFormikFields[];
  formikAdvancedFields: ToolFormikFields[];
  hideSaveButton?: boolean;
  onSubmit: (values: Record<string, string>) => void;
  enableReinitialize?: boolean;
  submitLabel: string;
  tiptap?: {
    turnOnTiptapInputAsPromptInput: boolean;
    toc: TOC;
  };
}

export const FormikDynamicForm = ({
  initialValue,
  validationSchema,
  formikBaseFields,
  formikAdvancedFields,
  hideSaveButton,
  submitLabel,
  onSubmit,
  enableReinitialize,
  tiptap,
}: FormicDynamicFormProps) => {
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: enableReinitialize,
  });
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-5">
          {formikBaseFields.map((field) => (
            <FormikDynamicFormItem
              field={field}
              formik={formik}
              key={field.name}
              tiptap={tiptap}
            />
          ))}
        </div>

        {formikAdvancedFields.length > 0 && (
          <div className="mb-4 mt-5 flex justify-end">
            <button
              className="link link--lg"
              onClick={() => setShowAdvancedFields(!showAdvancedFields)}
            >
              Advanced options
              <img
                src="/chevron-down-blue.svg"
                className={cn('transition', { 'rotate-180': showAdvancedFields })}
                alt=""
              />
            </button>
          </div>
        )}

        <div className="flex flex-col gap-5">
          {showAdvancedFields &&
            formikAdvancedFields.map((field) => (
              <FormikDynamicFormItem
                field={field}
                formik={formik}
                key={field.name}
              />
            ))}
        </div>

        {!hideSaveButton && (
          <Button
            className="mt-4"
            type="submit"
            isProcessing={formik.isSubmitting}
            disabled={formik.isSubmitting}
          >
            {submitLabel}
          </Button>
        )}
      </form>
    </FormikProvider>
  );
};
