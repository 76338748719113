import ConfettiExplosion from 'react-confetti-explosion';
import { useGlobalConfettiInternalState } from '@/components/GlobalConfetti/global-confetti.store.ts';

export const GlobalConfetti = () => {
  const { showConfetti } = useGlobalConfettiInternalState();

  return (
    <>
      {showConfetti && (
        <div className="fixed left-0 top-0 z-[9999] flex h-[50%] w-full items-center justify-center">
          <ConfettiExplosion
            force={0.8}
            height={window.innerHeight}
            width={window.innerWidth}
          />
        </div>
      )}
    </>
  );
};
