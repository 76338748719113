import { AccordionInfo } from '@/components/AccordionInfo.tsx';
import { Button } from 'flowbite-react';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';

export function DataRoomAddFromGoogleDriveModalBody() {
  const { closeModal } = useGlobalModal();

  return (
    <>
      <AccordionInfo
        question="What type of content should I share?"
        answer="You can share any content that provides value to your business and marketing. This includes campaign briefings, materials, articles, and previous strategies."
      />
      <Button
        color="secondary"
        className="mt-8 w-full"
      >
        <img
          src="/google-drive.svg"
          alt=""
        />
        Select from Google Drive
      </Button>

      <div className="modal-divider mb-6 mt-8"></div>

      <div className="flex items-center justify-end gap-3">
        <Button
          color="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button color="primary">Add</Button>
      </div>
    </>
  );
}
