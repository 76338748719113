import { cn } from '@/helpers/cn';
import { MemoryLogItem } from './MemoryLog';

interface MemoryLogBodyProps {
  isOpen: boolean;
  allTaskIsCompleted: boolean;
  logs: MemoryLogItem[];
}

export const MemoryLogBody = ({ isOpen, allTaskIsCompleted, logs }: MemoryLogBodyProps) => {
  if (!isOpen) return null;
  return (
    <div className="absolute bottom-[66px] right-0 w-[380px] rounded bg-white p-4 shadow">
      <h4
        className={cn('flex items-center pb-5 text-[16px] font-semibold leading-[150%] text-[#191C30E5]', {
          'justify-between': allTaskIsCompleted,
        })}
      >
        <span>Memory log</span>
      </h4>
      <div className="max-h-[200px] overflow-y-auto pr-3">
        {logs?.map((log, index) => (
          <div
            key={index}
            className="my-2 flex w-full items-center text-[14px] font-semibold leading-[150%] text-[#191C30E5]"
          >
            <img src="/check-green.svg" />

            <span>{log.message}</span>
          </div>
        ))}
        {!logs?.length && <div className="text-[14px] font-semibold leading-[150%] text-[#191C30E5]">No logs</div>}
      </div>
    </div>
  );
};
