import { UpgradePlanForm } from '@/components/UpgradePlanForm.tsx';
import { ContinueOnboardingForm } from '../../routes/onboarding/components/ContinueOnboardingForm.tsx';

export const ContinueOnboardingModalBody = () => {
  return (
    <div className="grid grid-cols-[52%,minmax(0,1fr)] gap-10">
      <ContinueOnboardingForm />
      <UpgradePlanForm />
    </div>
  );
};
