import { useApi } from '@/api/api.ts';

export const useByeUpsellProduct = () => {
  const { postFetcher } = useApi();

  const buyUpsellProduct = async (priceId: string, quantity: number) => {
    location.href = await postFetcher(`/stripe/buy-upsell-product`, { arg: { priceId, quantity } });
  };

  return {
    buyUpsellProduct,
  };
};
