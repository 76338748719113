import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';

import { useMyFlows } from '@/api/flow/my-flow/get-my-flows';
import { setFlowInstance } from '@/helpers/flowInstance.ts';
import { useProjects } from '@/api/projects/get-projects.ts';

export const OnboardingFlowInitializer = () => {
  const { projects } = useProjects();
  const { myFlows } = useMyFlows(projects?.[0]?.id ?? null);
  const navigate = useNavigate();

  useEffect(() => {
    if (projects?.length && myFlows?.length) {
      setFlowInstance(myFlows[0].id);
      navigate(
        buildUrl([Urls.ONBOARDING, Urls.PROJECTS, Urls.PROJECT, Urls.FLOW_EDITOR], {
          flowId: myFlows[0].id,
          projectId: projects[0].id,
        }),
      );
    }
  }, [projects, myFlows]);

  return <></>;
};
