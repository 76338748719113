import { useUnFinishedOnboardingProcesses } from '../../routes/onboarding/onboarding-store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';

export const useOpenOnboardingModal = () => {
  const { openModal } = useGlobalModal();
  const { user } = useAuth0();
  const unfinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);

  const openOnboardingModal = () => {
    if (unfinishedOnboardingProcesses.length) {
      openModal(MODALS.ContinueOnboarding);
    } else {
      openModal(MODALS.UpgradePlan);
    }
  };

  return { openOnboardingModal };
};
