import { useResourceChunks } from '@/api/inputs/get-resource-chunks';
import { DataRoomItemType } from '@/enums/data-room-item.enum';
import { DataRoomItem } from '@/models/data-room-item.interface';

export const AddedDataItemChunks = ({ item }: { item: DataRoomItem }) => {
  const { isLoading, resourceChunks } = useResourceChunks(
    item.id,
    item.type === DataRoomItemType.WEBSITE ? 'smartscraper' : 'input',
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return resourceChunks.map((chunk, index) => (
    <div
      key={index}
      className="px-4"
    >
      <p className="py-2">
        <span className="font-bold">{index + 1}.</span>
        {chunk}
      </p>
    </div>
  ));
};
