import { MyCanvasCard } from './components/MyCanvasCard.tsx';
import { LibraryCanvasCard } from './components/LibraryCanvasCard.tsx';
import { Link } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { CanvasCategory } from '@/enums/canvas-category.enum.ts';
import { useCanvases, useCanvasTemplates } from '@/api/canvases/api.ts';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';

export function CanvasPanel() {
  const { canvases, isLoading: isLoadingCanvases } = useCanvases();
  const { canvasTemplates, isLoading: isLoadingTemplates } = useCanvasTemplates();

  return (
    <>
      <PageWithHeaderLayout
        header={{
          breadcrumbs: [{ label: 'Strategy canvases', href: '#' }],
          icon: '/canvases-black.svg',
        }}
      >
        <Helmet>
          <title>Digitalfirst.ai | Canvases</title>
        </Helmet>

        {!isLoadingCanvases && !isLoadingTemplates && !!canvases?.length && (
          <section className="mb-10">
            <div className="flex items-center justify-between">
              <h2 className="mb-5 text-h2-desktop">My canvases</h2>
              <Link
                to={buildUrl([Urls.CANVASES, Urls.MY_CANVASES])}
                className="link link--lg"
              >
                View all
                <img
                  src="/arrow-right-blue.svg"
                  alt=""
                />
              </Link>
            </div>

            <div className="grid grid-cols-3 gap-6">
              {canvases?.slice(0, 6).map((canvasDetails) => (
                <MyCanvasCard
                  key={canvasDetails.id}
                  canvasDetails={canvasDetails}
                />
              ))}
            </div>
          </section>
        )}

        {!!canvasTemplates?.length && (
          <section>
            <div className="flex items-center justify-between">
              <h2 className="mb-5 text-h2-desktop">Library</h2>
              <Link
                to={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.ALL })}
                className="link link--lg"
              >
                View all
                <img
                  src="/arrow-right-blue.svg"
                  alt=""
                />
              </Link>
            </div>

            <div className="grid grid-cols-3 gap-6">
              {canvasTemplates?.slice(0, 6).map((canvasTemplate) => (
                <LibraryCanvasCard
                  key={canvasTemplate.id}
                  canvasTemplate={canvasTemplate}
                />
              ))}
            </div>
          </section>
        )}
      </PageWithHeaderLayout>
    </>
  );
}
