import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { Link } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Button } from 'flowbite-react';

export const GenericPlanLimitModalBody = () => {
  const { closeModal } = useGlobalModal();

  return (
    <div className="mt-8 flex flex-col text-center">
      <div className="mb-8 self-center rounded-xl bg-notice-300 p-3">
        <img
          src="/block-black.svg"
          alt=""
        />
      </div>
      <h1 className="mb-4 text-h3-desktop">You have reached the limit of your plan</h1>
      <p className="mb-10 text-body-md">You can upgrade your plan or extend your limits from settings.</p>
      <Link
        to={buildUrl([Urls.PRICING])}
        onClick={closeModal}
      >
        <Button
          as="div"
          color="primary"
        >
          Go to settings
        </Button>
      </Link>
    </div>
  );
};
