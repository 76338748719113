import { cn } from '@/helpers/cn';
import { Card } from 'flowbite-react';
import { Project } from '@/models/project.interface.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Link } from 'react-router-dom';
import { IconButton } from '@/components/IconButton.tsx';
import { DropdownProjectOptions } from './DropdownProjectOptions.tsx';
import { dateToRelativeString } from '@/helpers/date-to-relative-string.ts';

export function ProjectCard({ project }: { project: Project }) {
  return (
    <>
      <div className="relative">
        <div className="absolute right-4 top-4">
          <DropdownProjectOptions project={project}>
            <div>
              <IconButton
                icon="/three-dots-gray.svg"
                alt="More"
              />
            </div>
          </DropdownProjectOptions>
        </div>
        <Link to={buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: project.id })}>
          <Card className="hover:bg-gray-bg hover:text-text-primary active:bg-gray-ultra-light has-[button:hover,a:hover]:bg-transparent">
            <div className="mb-4 flex w-full items-center justify-between">
              <div className={cn('rounded-lg p-2.5', project.color ?? 'bg-green-light')}>
                <img
                  src="/business-black.svg"
                  alt=""
                />
              </div>
            </div>
            <h5 className="mb-1 text-h5-desktop">{project.name}</h5>
            <div className="mb-4 text-body-sm">Viewed {dateToRelativeString(project.modifiedAt)}</div>
          </Card>
        </Link>
      </div>
    </>
  );
}
