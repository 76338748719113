import { Button, Progress } from 'flowbite-react';
import { Helmet } from 'react-helmet';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { setOnboardingFormData, useOnboardingFormData } from './onboarding-store.ts';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { OnboardingFlowMockupPage } from './components/OnboardingFlowMockupPage.tsx';
import { OnboardingWebSearcherMockupPage } from './components/OnboardingWebSearcherMockupPage.tsx';
import { OnboardingCanvasMockupPage } from './components/OnboardingCanvasMockupPage.tsx';
import toast from 'react-hot-toast';

export const StartOnboardingLoadingScreen = () => {
  const { user } = useAuth0();
  const { listenerOn } = useWebSocketMessageListener();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const navigate = useNavigate();
  const onboardingFormData = useOnboardingFormData();
  const [onboardingProcessType, setOnboardingProcessType] = useState<OnboardingProcessType | null>(null);

  useEffect(() => {
    setOnboardingProcessType(onboardingFormData!.onboardingProcessType);
  }, []);

  useEffect(() => {
    if (!onboardingProcessType) return;

    const wsChannel = `onbording:${user!.sub}`;

    setOnboardingFormData(null);

    const { listenerOff } = listenerOn(wsChannel, async (data) => {
      if (typeof data === 'string') {
        setLoadingProgress((prev) => prev + 25);
        setLoadingStatus(data);
      } else {
        const typedData = data as { error?: string };

        if (typedData.error) {
          listenerOff();
          navigate(buildUrl([Urls.DASHBOARD]));
          toast.error(typedData.error);
        } else {
          setTimeout(() => {
            redirectToSelectedOnboardingProcess();
            listenerOff();
          }, 2000);
          setLoadingProgress(100);
          setLoadingStatus('Completed!');
        }
      }
    });

    return () => {
      listenerOff();
    };
  }, [onboardingProcessType]);

  const redirectToSelectedOnboardingProcess = () => {
    switch (onboardingProcessType) {
      case OnboardingProcessType.FLOW:
        navigate(buildUrl([Urls.ONBOARDING, Urls.FLOW_EDITOR]));
        return;
      case OnboardingProcessType.WEB_SEARCHER:
        navigate(buildUrl([Urls.ONBOARDING, Urls.WEB_SEARCHER]));
        return;
      case OnboardingProcessType.CANVAS:
        navigate(buildUrl([Urls.ONBOARDING, Urls.CANVASES]));
        return;
    }
  };

  const renderMockupPage = useMemo((): ReactNode => {
    switch (onboardingProcessType) {
      case OnboardingProcessType.FLOW:
        return <OnboardingFlowMockupPage />;
      case OnboardingProcessType.WEB_SEARCHER:
        return <OnboardingWebSearcherMockupPage />;
      case OnboardingProcessType.CANVAS:
        return <OnboardingCanvasMockupPage />;
    }
  }, [onboardingProcessType]);

  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai | Onboarding</title>
      </Helmet>

      {renderMockupPage}

      {createPortal(
        <div className="fixed left-0 top-0 z-[4000] flex h-screen w-screen items-center justify-center p-8 backdrop-blur">
          <div className="flex w-[38rem] flex-col rounded-2xl bg-white p-10 shadow-lg">
            <h1 className="mb-6 flex flex-wrap items-center gap-3 text-5xl font-medium">
              <span>We are</span>
              <img
                src="/screen-touch-black.svg"
                className="rounded-xl bg-mint-500 p-3.5"
                alt=""
              />
              <span>pulling</span>
              <span className="italic">everything</span>
              <span>together</span>
              <img
                src="/puzzles-black.svg"
                className="rounded-xl bg-primary-300 p-3.5"
                alt=""
              />
            </h1>

            <div className="mb-8 text-center text-sm">
              Our AI is busy analyzing the details. This may take just a moment.
              <br />
              Thank you for your patience!
            </div>

            <div className="flex flex-col gap-2">
              <Progress
                progress={loadingProgress}
                color="primary"
                size="md"
                theme={{
                  bar: 'space-x-2 transition-all duration-700 rounded-full text-center font-medium leading-none text-cyan-300',
                }}
              />
              <div className="text-sm leading-tight text-primary-600">
                {loadingProgress}% {loadingStatus}
              </div>
            </div>

            <div className="flex justify-center">
              <Button
                onClick={() => navigate(buildUrl([Urls.DASHBOARD]))}
                className="mt-8"
                size="lg"
                color="secondary"
                disabled={loadingProgress >= 75}
              >
                Skip onboarding
              </Button>
            </div>
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};
