import { create } from 'zustand';

interface WebSearcherStoreProps {
  roomsData: {
    [roomId: number]: {
      questions: string[];
      isFetchingAnswer: boolean;
    };
  };
  isMenuOpen: boolean;
}

const webSearcherStore = create<WebSearcherStoreProps>(() => ({
  roomsData: {},
  isMenuOpen: true,
}));

const setAnswerFetchingStart = (roomId: number) => {
  webSearcherStore.setState((state) => ({
    roomsData: {
      ...state.roomsData,
      [roomId]: {
        ...state.roomsData[roomId],
        isFetchingAnswer: true,
      },
    },
  }));
};

const setAnswerFetchingEnd = (roomId: number) => {
  webSearcherStore.setState((state) => ({
    roomsData: {
      ...state.roomsData,
      [roomId]: {
        ...state.roomsData[roomId],
        isFetchingAnswer: false,
      },
    },
  }));
};

const setQuestions = (roomId: number, questions: string[]) => {
  webSearcherStore.setState((state) => ({
    roomsData: {
      ...state.roomsData,
      [roomId]: {
        ...state.roomsData[roomId],
        questions,
      },
    },
  }));
};

export const useWebSearcherStore = (roomId: number) => ({
  setAnswerFetchingStart: () => setAnswerFetchingStart(roomId),
  setAnswerFetchingEnd: () => setAnswerFetchingEnd(roomId),
  setQuestions: (questions: string[]) => setQuestions(roomId, questions),
  questions: webSearcherStore((state) => state.roomsData[roomId]?.questions),
  isFetchingAnswer: webSearcherStore((state) => state.roomsData[roomId]?.isFetchingAnswer),
});

export const useWebSearcherMenuStore = (): {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
} => ({
  isMenuOpen: webSearcherStore((state) => state.isMenuOpen),
  setIsMenuOpen: (isMenuOpen: boolean) => webSearcherStore.setState({ isMenuOpen }),
});
