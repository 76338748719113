import { create } from 'zustand';
import { StripeProduct } from '@/models/stripe-product.interface.ts';

interface UserPlanState {
  userPlan: StripeProduct | null;
}

const useUserPlanStore = create<UserPlanState>(() => ({
  userPlan: null,
}));

export const setUserPlan = (userPlan: StripeProduct) => {
  useUserPlanStore.setState({ userPlan });
};

export const useStoredUserPlan = () => useUserPlanStore((state) => state.userPlan);
