import { Progress } from 'flowbite-react';
import { StripeUpsellProduct } from '@/models/stripe-upsell-product.interface.ts';
import { StripeUpsellProductName } from '@/enums/StripeUpsellProductName.enum.ts';
import { useMemo } from 'react';
import { usePlanLimits } from '@/api/pricing/get-plan-limit.ts';
import { formatBytes } from '@/helpers/format-bytes.ts';
import { UpsellProductForm } from './UpsellProductForm.tsx';
import { getPlanLimitForUpsellProduct } from '@/helpers/get-plan-limit-for-upsell-product.ts';

export const UpsellProductCard = ({ upsellProduct }: { upsellProduct: StripeUpsellProduct }) => {
  const { planLimits } = usePlanLimits();

  const planLimit = useMemo(() => {
    if (!planLimits) return;

    return getPlanLimitForUpsellProduct(upsellProduct.metadata.upsellName, planLimits);
  }, [planLimits, upsellProduct]);

  const productInfo = useMemo(() => {
    if (!planLimit) return;

    switch (upsellProduct.metadata.upsellName) {
      case StripeUpsellProductName.WORKSPACE:
        return {
          title: 'Workspace',
          usageElement: (
            <>
              <span className="font-medium text-text-primary">{planLimit.currentValue}</span> of{' '}
              <span className="font-medium text-text-primary">{planLimit.maxValue}</span> workspaces used
            </>
          ),
        };
      case StripeUpsellProductName.STORAGE:
        return {
          title: 'Storage',
          usageElement: (
            <>
              <span className="font-medium text-text-primary">{formatBytes(planLimit.currentValue)}</span> of{' '}
              <span className="font-medium text-text-primary">{formatBytes(planLimit.maxValue)}</span> storage used
            </>
          ),
        };
    }
  }, [upsellProduct.metadata.upsellName, planLimit]);

  return (
    <>
      {!!planLimit && !!productInfo && (
        <div className="flex flex-col gap-4 rounded-2xl border border-gray-divider p-6">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-medium">{productInfo.title}</h3>
              <img
                src="/info-gray.svg"
                alt=""
              />
            </div>
            <div className="text-sm text-text-secondary">{productInfo.usageElement}</div>
          </div>

          <Progress
            progress={(planLimit.currentValue / planLimit.maxValue) * 100}
            size="sm"
            color="primary"
          />

          <UpsellProductForm upsellProduct={upsellProduct} />
        </div>
      )}
    </>
  );
};
