import { useApi } from '@/api/api.ts';

export const useBuyPlan = () => {
  const { postFetcher } = useApi();

  const buyPlan = async (isAnnual: boolean) => {
    location.href = await postFetcher('/stripe/buy-plan', { arg: { isAnnual } });
  };

  return {
    buyPlan,
  };
};
