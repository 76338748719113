'use client';

import { Editor as CoreEditor } from '@tiptap/core';
import { memo, useEffect, useMemo, useState } from 'react';
import { TableOfContentsStorage } from '@tiptap-pro/extension-table-of-contents';
import { cn } from '@/helpers/cn';
import { useDebounce } from 'use-debounce';
import romanize from './romanize';
// import numbering from 'numbering';

// declare let numbering: any;

export type TableOfContentsProps = {
  editor: CoreEditor;
  onItemClick?: () => void;
};

export const TableOfContents = memo(({ editor, onItemClick }: TableOfContentsProps) => {
  const [data, setData] = useState<TableOfContentsStorage | null>(null);
  const debounceContent = useDebounce(data?.content, 7000);

  // const numberingSchemes = useMemo(() => [numbering('1.'), numbering('a.'), numbering('i.'), numbering('')], []);

  useEffect(() => {
    const handler = ({ editor: currentEditor }: { editor: CoreEditor }) => {
      setData({ ...currentEditor.extensionStorage.tableOfContents });
    };

    handler({ editor });

    editor.on('update', handler);
    editor.on('selectionUpdate', handler);

    return () => {
      editor.off('update', handler);
      editor.off('selectionUpdate', handler);
    };
  }, [editor]);

  const items = useMemo(() => {
    let counter = 1;
    let subcounter = 0;
    let subsubcounter = 0;
    const letters = 'abcdefghijklmnopqrstuvwxyz';

    return data?.content
      .filter(
        (item) =>
          !!document.getElementById(item.id) &&
          !document.querySelector(`div[data-node-view-wrapper] #${CSS.escape(item.id)}`),
      )
      .map((item) => {
        if (subcounter >= letters.length) {
          subcounter = letters.length - 1;
        }

        if (item.level === 1) {
          subcounter = 0;
          return { ...item, textContent: `${counter++}. ${item.textContent}` };
        } else if (item.level === 2) {
          subsubcounter = 1;
          return { ...item, textContent: `${letters[subcounter++]}. ${item.textContent}` };
        } else if (item.level === 3) {
          return { ...item, textContent: `${romanize(subsubcounter++)}. ${item.textContent}` };
        } else {
          return { ...item, textContent: item.textContent };
        }
      });
  }, [debounceContent]);

  // const items = useMemo(() => {
  //   if (!debounceContent) return [];

  //   const filteredItems = debounceContent.filter(
  //     (item) =>
  //       !!document.getElementById(item.id) &&
  //       !document.querySelector(`div[data-node-view-wrapper] #${CSS.escape(item.id)}`),
  //   );

  //   // Build a tree structure
  //   const tree = [];
  //   const lookup = {};
  //   filteredItems.forEach((item) => {
  //     lookup[item.id] = { ...item, children: [] };
  //   });

  //   filteredItems.forEach((item) => {
  //     if (item.level === 1) {
  //       tree.push(lookup[item.id]);
  //     } else {
  //       const parent = filteredItems.find((p) => p.level === item.level - 1 && p.id < item.id);
  //       if (parent) {
  //         lookup[parent.id].children.push(lookup[item.id]);
  //       }
  //     }
  //   });

  //   return tree;
  // }, [debounceContent]);
  // numberingSchemes[schemeIndex].reset();

  // const getNumber()
  return (
    <>
      <div className="mb-5 text-nowrap text-[12px] font-medium text-text-secondary">Table of contents</div>
      {items && items.length > 0 ? (
        <div className="flex flex-col">
          {items?.map((item) => {
            return (
              <a
                key={item.id}
                href={`#${item.id}`}
                style={{ paddingLeft: `calc(8px + ${1.1 * item.level - 1}rem)` }} //, fontWeight: item.level === 1 ? 'medium' : 'normal' }}
                onClick={onItemClick}
                className={cn(
                  'block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800',
                  item.isActive && 'bg-gray-100 text-neutral-800',
                )}
              >
                {item.textContent}
              </a>
            );
          })}
        </div>
      ) : (
        <div className="text-sm text-neutral-500">Start adding headlines to your document …</div>
      )}
    </>
  );
});

TableOfContents.displayName = 'TableOfContents';
