import { TiptapDocument } from './tiptap-document.interface';

export interface FlowTemplate {
  id: number;
  name: string;
  description: string;
  category: FlowTemplateCategory;
  tiptapDocument: TiptapDocument;
  access: {
    name: string;
    owner: string;
  };
}

export enum FlowTemplateCategory {
  MY = 'my',
  MARKETING = 'marketing',
  COMMUNICATION = 'communication',
  EXECUTION = 'execution',
  DOCUMENTATION = 'documentation',
  ANALYSIS = 'analysis',
  RESEARCH = 'research',
  STRATEGY = 'strategy',
  CREATIVE_DEVELOPMENT = 'creativeDevelopment',
  CONTENT_CREATION = 'contentCreation',
  ONBOARDING = 'onboarding',
}
