export function ProjectItemPlaceholder() {
  return (
    <>
      <div className="h-28 rounded-xl bg-gradient-to-l from-white to-[#F7F7F9] p-4">
        <div className="flex flex-col items-start gap-3 py-1">
          <div className="h-3 w-2/3 rounded-3xl bg-neutrals-300"></div>
          <div className="h-3 w-1/2 rounded-3xl bg-neutrals-300"></div>
          <div className="h-6 w-6 rounded-full bg-neutrals-300"></div>
        </div>
      </div>
    </>
  );
}
