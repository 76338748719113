import { Link, useParams } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Card } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import { Flow } from '@/models/flow.interface.ts';
import { DropdownMyFlowOptions } from './DropdownMyFlowOptions.tsx';
import { Urls } from '@/consts/urls.ts';
import { dateToRelativeString } from '@/helpers/date-to-relative-string.ts';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { useOrganizationMembers } from '@/api/organization/get-organization-members.ts';
import { useAuth0 } from '@auth0/auth0-react';

export function MyFlowCard({ myFlow }: { myFlow: Flow }) {
  const { user } = useAuth0();
  const { organizationMembers } = useOrganizationMembers();
  const { projectId } = useParams<{ projectId: string }>();

  if (!projectId) {
    return null;
  }

  const getCreator = () => {
    if (myFlow.tiptapDocument.user_id === user?.user_id) {
      return user;
    }

    const creator = (organizationMembers ?? []).find((value) => value.user_id === myFlow.tiptapDocument.user_id);
    return creator;
  };

  return (
    <>
      <div className="relative">
        <div className="absolute right-4 top-[calc(50%-12px)]">
          <DropdownMyFlowOptions myFlow={myFlow}>
            <IconButton
              icon="/three-dots-gray.svg"
              size={24}
            />
          </DropdownMyFlowOptions>
        </div>
        <Link
          className="block h-full max-h-full min-w-0 grow"
          to={buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.FLOW_EDITOR], { flowId: myFlow.id, projectId })}
        >
          <Card
            className="h-full max-h-full hover:bg-gray-bg active:bg-gray-ultra-light"
            key={myFlow.id}
          >
            <div className="flex w-full gap-4">
              <div className="flex min-w-0 grow flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="overflow-hidden text-ellipsis text-nowrap text-button-lg">{myFlow.name}</span>
                  <img
                    src="/generate-black.svg"
                    alt=""
                  />
                </div>
                <div className="overflow-hidden text-ellipsis text-nowrap text-body-sm">
                  Edited {dateToRelativeString(myFlow.modifiedAt)}
                </div>
                {getCreator()?.picture ? (
                  <img
                    src={getCreator()?.picture}
                    className="size-6 rounded-full"
                  />
                ) : (
                  <DfAvatar
                    firstName={getCreator()?.given_name || 'U'}
                    lastName={getCreator()?.family_name || 'U'}
                    size={24}
                  />
                )}
              </div>
              <div className="flex w-[24px] shrink-0 items-center"></div> {/* place for an absolute button */}
            </div>
          </Card>
        </Link>
      </div>
    </>
  );
}
