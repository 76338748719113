import { UpgradePlanForm } from '@/components/UpgradePlanForm.tsx';

export const UpgradePlanModalBody = () => {
  return (
    <div className="grid grid-cols-[45%,minmax(0,1fr)] gap-10">
      <img
        src="/upgrade-plan-picture.jpg"
        className="sticky top-0 h-full max-h-[calc(90svh-1.5rem)] w-full rounded-l-xl object-cover"
        alt=""
      />

      <UpgradePlanForm />
    </div>
  );
};
