/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldInputProps, FormikProps, getIn } from 'formik';
import { TextInputProps } from 'flowbite-react';

export function getDfFlowbiteFieldProps(
  name: string,
  formik: FormikProps<any>,
): FieldInputProps<any> & {
  id: string;
  color: TextInputProps['color'];
  helperText: string;
} {
  const touched = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);

  return {
    id: name,
    ...formik.getFieldProps(name),
    color: touched && error ? 'failure' : 'gray',
    helperText: touched && error,
  };
}

export function getDfFieldProps(
  name: string,
  formik: FormikProps<any>,
): FieldInputProps<any> & {
  id: string;
} {
  return {
    id: name,
    ...formik.getFieldProps(name),
  };
}
