import { Card } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import { useCallback, useEffect, useState } from 'react';
import ContentEditableListEditor from './ContentEditableList.tsx';
import { CanvasDetails, CanvasPosition, useGenerateCanvasSection, useSaveCanvas } from '@/api/canvases/api.ts';
import { setCanvasSectionProcessingState, useCanvasSectionProcessingStatus } from '@/api/canvases/store.ts';
import { listenOnCanvasFillerMessage } from '../../../helpers/listen-on-canvas-filler-message.ts';
import { mutate } from 'swr';
import { ToolName } from 'src/libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';

export function CanvasPositionCard({
  position,
  canvasDetails,
}: {
  position: CanvasPosition;
  canvasDetails: CanvasDetails;
}) {
  const { saveCanvas } = useSaveCanvas();
  const { generateCanvasSection } = useGenerateCanvasSection();

  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [editedDescriptions, setEditedDescriptions] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const isProcessing = useCanvasSectionProcessingStatus(canvasDetails.id, position.id);

  useEffect(() => {
    setDescriptions(position.value);
  }, [position.value]);

  const onDescriptionsChange = useCallback((newDescriptions: string[]) => {
    setEditedDescriptions(newDescriptions);
  }, []);

  const [borderColor] = useState('#F8F7FE');
  const [circleColor] = useState('#4B30F0');

  const onSaveChanges = async () => {
    const newPositions = canvasDetails.positions.filter((p) => p.id !== position.id);
    newPositions.push({
      ...position,
      value: editedDescriptions,
    });
    setCanvasSectionProcessingState(canvasDetails.id, position.id, true);
    await saveCanvas(canvasDetails.templateId, newPositions, canvasDetails.id);
    setCanvasSectionProcessingState(canvasDetails.id, position.id, false);
    setIsEditing(false);
    setDescriptions(editedDescriptions);
  };

  const onRegenerateSection = async () => {
    setCanvasSectionProcessingState(canvasDetails.id, position.id, true);
    const wsChannel = `${ToolName.CANVAS_FILLER_POSITION}: ${uuid()}`;
    generateCanvasSection(canvasDetails.id, position.id, wsChannel);

    listenOnCanvasFillerMessage(wsChannel, () => {
      mutate(`/strategy-canvas/instance/${canvasDetails.id}`);
      setCanvasSectionProcessingState(canvasDetails.id, position.id, false);
    });
  };

  return (
    <>
      <Card className="relative">
        {isProcessing && (
          <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white opacity-80">
            <img
              src="/loading-blue.svg"
              className="animate-spin-slow"
              alt=""
            />
          </div>
        )}

        <div className="flex w-full items-center justify-between">
          <h2 className="text-subtitle-md">{position.label}</h2>
          <div className="flex gap-2">
            {isEditing ? (
              <>
                <IconButton
                  icon="/close-gray.svg"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                />
                <IconButton
                  icon="/approve-gray.svg"
                  onClick={onSaveChanges}
                />
              </>
            ) : (
              <>
                <IconButton
                  icon="/regenerate-gray.svg"
                  onClick={onRegenerateSection}
                />
                <IconButton
                  icon="/edit-gray.svg"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              </>
            )}
          </div>
        </div>

        {isEditing && (
          <div className="mt-4 w-full">
            <ContentEditableListEditor
              descriptions={descriptions}
              onDescriptionsChange={onDescriptionsChange}
            />
          </div>
        )}
        {!isEditing && descriptions.length !== 0 && (
          <ul className="mt-4">
            {descriptions.map((item, index) => (
              <li
                key={index}
                className={`relative mb-4 pl-6 text-body-md text-text-secondary before:absolute before:left-0 before:top-[6px] before:h-[14px] before:w-[14px] before:rounded-full before:border-[3px] before:border-[var(--tw-before-border-color)] before:bg-[var(--tw-before-bg-color)] before:content-['']`}
                style={{
                  ['--tw-before-border-color' as never]: borderColor,
                  ['--tw-before-bg-color' as never]: circleColor,
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </Card>
    </>
  );
}
