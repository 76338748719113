import { Dropdown } from 'flowbite-react';
import { LeftSideNavMenuItem } from '@/layouts/MainLayout/components/LeftSideNavMenuItem.tsx';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { isOrganizationAdmin } from '@/helpers/is-organization-admin.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { cn } from '@/helpers/cn';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '@/api/user/get-organizations.ts';
import { useSwitchToOrganizationAccount } from '@/helpers/switch-to-organization-account.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';

export const UserMenuItem = () => {
  const navigate = useNavigate();
  const { logout, user, loginWithRedirect } = useAuth0();
  const { organizations } = useOrganizations();
  const { switchToOrganizationAccount } = useSwitchToOrganizationAccount();
  const { showLoader, hideLoader } = useGlobalLoader();

  const onSwitchAccount = (organization?: string) => {
    // If user is already in the account, do nothing
    if ((!!user?.org_id && user.org_id === organization) || (!user?.org_id && !organization)) {
      return;
    }

    showLoader();
    if (organization) {
      switchToOrganizationAccount(organization).catch(() => {
        hideLoader();
      });
    } else {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
          prompt: 'none',
        },
      }).catch(() => {
        hideLoader();
      });
    }
  };

  return (
    <>
      <Dropdown
        label=""
        placement="top-end"
        className="!top-[-10px] max-h-[40svh] w-[300px] overflow-y-auto"
        renderTrigger={() => (
          <div>
            <LeftSideNavMenuItem
              label={`${user?.given_name} ${user?.family_name}`}
              customLeftIcon={
                <div className="ml-1">
                  {user?.picture ? (
                    <img
                      src={user.picture}
                      className="size-8 h-auto rounded-full"
                      alt=""
                    />
                  ) : (
                    <DfAvatar
                      firstName={user?.given_name || 'U'}
                      lastName={user?.family_name || 'U'}
                    />
                  )}
                </div>
              }
              addons={
                <div className="flex gap-1">
                  <IconButton
                    icon="/settings-black.svg"
                    size={24}
                    onClick={() => {
                      if (isOrganizationAdmin(user)) {
                        navigate(buildUrl([Urls.ORGANIZATION_SETTINGS]));
                      } else {
                        navigate(buildUrl([Urls.USER_SETTINGS]));
                      }
                    }}
                  />
                  <IconButton
                    icon="/logout-black.svg"
                    size={24}
                    onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                  />
                </div>
              }
            />
          </div>
        )}
      >
        <div className="dropdown-header">Personal account</div>

        <div className="dropdown-item__workspace-container">
          <Dropdown.Item onClick={() => onSwitchAccount()}>
            <div
              className={cn('dropdown-item__workspace-avatar !rounded-full', {
                'rounded-full border border-white outline outline-primary-default': !user?.org_id,
              })}
            >
              <div className={cn()}>
                {user?.picture ? (
                  <img
                    src={user.picture}
                    className="size-8 h-auto rounded-full"
                    alt=""
                  />
                ) : (
                  <DfAvatar
                    firstName={user?.given_name || 'U'}
                    lastName={user?.family_name || 'U'}
                  />
                )}
              </div>
            </div>
            <div className="text-left">{`${user?.given_name} ${user?.family_name}`}</div>
          </Dropdown.Item>
        </div>

        {!!organizations && organizations.length > 0 && (
          <>
            <div className="dropdown-header">Organizations</div>

            {organizations.map((organization) => (
              <div
                key={organization.id}
                className="dropdown-item__workspace-container"
              >
                <Dropdown.Item onClick={() => onSwitchAccount(organization.id)}>
                  <div className="dropdown-item__workspace-avatar">
                    <div
                      className={cn(
                        'flex size-8 items-center justify-center rounded-full bg-pink-300 text-h4-desktop group-[.is-collapsed]:m-0',
                        {
                          'border border-white outline outline-primary-default': user?.org_id === organization.id,
                        },
                      )}
                    >
                      {organization?.display_name.charAt(0).toUpperCase()}
                    </div>
                  </div>
                  <div className="text-left">{organization.display_name}</div>
                </Dropdown.Item>
              </div>
            ))}
          </>
        )}
      </Dropdown>
    </>
  );
};
