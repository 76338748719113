import { cn } from '@/helpers/cn';
import { useState } from 'react';
import { useMemoryContext } from '@/api/memory-context/get-memory-context.ts';
import { useParams } from 'react-router-dom';
import { MemoryContextEmptySlot } from '@/components/MemoryContextEmptySlot.tsx';
import { MemoryContextFilledSlot } from '@/components/MemoryContextFilledSlot.tsx';
import React from 'react';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { sortableHandleDragEnd } from '@/helpers/sortable-handle-drag-end';

export function MemoryContextProjectSection() {
  const { projectId } = useParams<{ projectId: string }>();
  const [isExpanded, setIsExpanded] = useState(false);
  const { memoryContextItems, maxSlots, updateMemoryContext } = useMemoryContext(projectId!);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 3 },
    }),
  );

  return (
    <div className="rounded-2xl bg-notice-200 p-4">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <img
            className="h-8 w-8 rounded-lg bg-notice-300 p-2"
            src="/pin-notice.svg"
            alt=""
          />
          <h4 className="text-h4-desktop">Memory context</h4>
          <span className="rounded-xl bg-notice-1000 p-1 text-caption-sm text-white">
            {memoryContextItems.filter((item) => item !== null).length}/{maxSlots}
          </span>
        </div>

        <button
          className="flex items-center gap-2 text-button-lg text-notice-900"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          View all
          <img
            src="/chevron-down-notice.svg"
            className={cn('transition', { 'rotate-180': isExpanded })}
            alt=""
          />
        </button>
      </div>

      <div className="mt-4 grid grid-cols-4 items-start gap-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToParentElement]}
          onDragEnd={(e) =>
            sortableHandleDragEnd(
              e,
              memoryContextItems.filter((m) => !!m),
              updateMemoryContext,
            )
          }
        >
          <SortableContext items={memoryContextItems.filter((m) => !!m).map((m) => m.id)}>
            {memoryContextItems.slice(0, isExpanded ? memoryContextItems.length : 4).map((memoryContextItem, index) => (
              <React.Fragment key={index}>
                {memoryContextItem !== null ? (
                  <MemoryContextFilledSlot
                    memoryContextItem={memoryContextItem}
                    index={index}
                  />
                ) : (
                  <MemoryContextEmptySlot index={index} />
                )}
              </React.Fragment>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}
