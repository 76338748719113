import { Button, Card } from 'flowbite-react';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useNavigate } from 'react-router-dom';
import { CanvasTemplate, useSaveCanvas } from '@/api/canvases/api.ts';

export function LibraryCanvasCard({ canvasTemplate }: { canvasTemplate: CanvasTemplate }) {
  const { saveCanvas } = useSaveCanvas();
  const navigate = useNavigate();

  const onButtonClick = async () => {
    const canvas = await saveCanvas(canvasTemplate.id);
    navigate(buildUrl([Urls.CANVASES, Urls.CANVAS], { canvasId: canvas.id }));
  };

  return (
    <>
      <Card>
        <div className="flex h-full w-full flex-col justify-between">
          <div className="flex flex-col items-start">
            <div className="mb-4 rounded-lg bg-tint-purple p-2.5">
              <img
                src="/business-black.svg"
                alt=""
              />
            </div>
            <h3 className="mb-2 text-h3-desktop">{canvasTemplate.name}</h3>
            <div className="mb-6 text-text-secondary">{canvasTemplate.description}</div>
          </div>

          <Button
            color="secondary"
            gradientMonochrome="yellow"
            className="w-full"
            onClick={onButtonClick}
          >
            <img
              src="/generate-yellow.svg"
              alt=""
            />
            Generate
          </Button>
        </div>
      </Card>
    </>
  );
}
