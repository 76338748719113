import { useCreatePublicDFAxios } from '@/api/create-df-axios.ts';

export const usePublicApi = () => {
  const { createPublicDFAxios } = useCreatePublicDFAxios();

  const publicGetFetcher = async (url: string) => {
    return createPublicDFAxios()
      .get(url)
      .then((res) => res.data);
  };

  const publicPostFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    return createPublicDFAxios()
      .post(url, arg)
      .then((res) => res.data);
  };

  const publicDeleteFetcher = async <T>(url: string, data?: { arg: T }) => {
    return createPublicDFAxios()
      .delete(url, data && { data: data.arg })
      .then((res) => res.data);
  };

  const publicPutFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    return createPublicDFAxios()
      .put(url, arg)
      .then((res) => res.data);
  };

  const publicPatchFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    return createPublicDFAxios()
      .patch(url, arg)
      .then((res) => res.data);
  };

  return {
    publicGetFetcher,
    publicPostFetcher,
    publicDeleteFetcher,
    publicPutFetcher,
    publicPatchFetcher,
  };
};
