import { useNavigate, useParams } from 'react-router-dom';
import { useCanvasDetails } from '@/api/canvases/api.ts';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { DropdownCanvasOptions } from '../../canvases/components/DropdownCanvasOptions.tsx';
import { GenerateCanvasButton } from '../../canvases/routes/canvas-deatils/components/GenerateCanvasButton.tsx';
import { Helmet } from 'react-helmet';
import { CanvasPositionCard } from '../../canvases/routes/canvas-deatils/components/CanvasPositionCard.tsx';
import { useEffect } from 'react';
import { confettiBoom } from '@/components/GlobalConfetti/global-confetti.store.ts';
import { setUnFinishedOnboardingProcesses, useUnFinishedOnboardingProcesses } from '../onboarding-store.ts';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { useOpenOnboardingModal } from '@/helpers/open-onboarding-modal.ts';

export const OnboardingCanvasDetails = () => {
  const { canvasId } = useParams<{ canvasId: string }>();
  const { canvasDetails, isLoading } = useCanvasDetails(canvasId!);
  const navigate = useNavigate();
  const { openOnboardingModal } = useOpenOnboardingModal();
  const { user } = useAuth0();
  const unFinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);

  useEffect(() => {
    if (canvasDetails) {
      confettiBoom();
      setUnFinishedOnboardingProcesses(
        user!.sub!,
        unFinishedOnboardingProcesses.filter((type) => type !== OnboardingProcessType.CANVAS),
      );

      setTimeout(() => {
        openOnboardingModal();
      }, 30000);
    }
  }, [canvasDetails]);

  if (isLoading || !canvasDetails) return;

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Strategy canvases', href: buildUrl([Urls.CANVASES]) },
          { label: 'My canvases', href: buildUrl([Urls.CANVASES, Urls.MY_CANVASES]) },
          { label: canvasDetails?.name ?? '', href: '#' },
        ],
        icon: '/canvases-black.svg',
        leftAddons: [
          <DropdownCanvasOptions
            key={canvasDetails.id}
            instanceId={canvasDetails.id}
            onDelete={() => {
              navigate(buildUrl([Urls.CANVASES, Urls.MY_CANVASES]));
            }}
          />,
        ],
        rightAddons: [
          <GenerateCanvasButton
            key={canvasDetails.id}
            canvasDetails={canvasDetails}
          />,
        ],
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | {canvasDetails.name}</title>
      </Helmet>

      <div className="grid grid-cols-2 items-start gap-6">
        {canvasDetails.positions
          .sort((a, b) => {
            return a.hierarchyPosition - b.hierarchyPosition;
          })
          .map((position) => (
            <CanvasPositionCard
              key={position.id}
              position={position}
              canvasDetails={canvasDetails}
            />
          ))}
      </div>
    </PageWithHeaderLayout>
  );
};
