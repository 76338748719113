import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { FormikProvider, useFormik } from 'formik';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { SettingsSubHeader } from './components/SettingsSubHeader.tsx';
import { Helmet } from 'react-helmet';
import { Button, Label, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { CheckboxWithLabel } from '@/components/CheckboxWithLabel.tsx';
import { object, string } from 'yup';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { useCreateOrganization } from '@/api/organization/create-organization.ts';

export const CreateOrganizationPage = () => {
  const { createOrganization } = useCreateOrganization();
  const { showLoader } = useGlobalLoader();

  const formik = useFormik<{ name: string; migrateData: boolean }>({
    initialValues: {
      name: '',
      migrateData: true,
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async ({ name, migrateData }) => {
      showLoader('Creating organization...');
      await createOrganization(name, migrateData).catch((error) => {
        if (error.response.status === 409) {
          formik.setErrors({ name: 'Organization with this name already exists' });
        }
      });
    },
  });

  return (
    <PageWithHeaderLayout
      subHeader={<SettingsSubHeader />}
      header={{
        breadcrumbs: [{ label: 'Settings', href: '#' }],
        icon: '/settings-black.svg',
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | Settings</title>
      </Helmet>

      <div className="grid grid-cols-2 gap-6">
        <form
          className="h-fit rounded-2xl border p-6"
          onSubmit={formik.handleSubmit}
        >
          <FormikProvider value={formik}>
            <h2 className="mb-8 text-h4-desktop">Create organization</h2>
            <div className="mb-8">
              <Label value="Organization name" />
              <TextInput {...getDfFlowbiteFieldProps('name', formik)} />
            </div>
            <div>
              <CheckboxWithLabel
                name="migrateData"
                label="I want to migrate all data (workspaces, projects etc.) from my personal account to this organization"
                formik={formik}
              />
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                color="primary"
                type="submit"
              >
                Create organization
              </Button>
            </div>
          </FormikProvider>
        </form>
      </div>
    </PageWithHeaderLayout>
  );
};
