import { create } from 'zustand';

type GlobalConfettiState = {
  show: boolean;
};

const useGlobalConfettiStore = create<GlobalConfettiState>(() => ({
  show: false,
}));

export const confettiBoom = () => {
  useGlobalConfettiStore.setState(() => ({
    show: true,
  }));
  setTimeout(() => {
    useGlobalConfettiStore.setState(() => ({
      show: false,
    }));
  }, 2000);
};

export const useGlobalConfettiInternalState = () => ({
  showConfetti: useGlobalConfettiStore((state) => state.show),
});
