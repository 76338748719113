export const OnboardingFlowMockupPage = () => {
  return (
    <>
      <div className="max-w-full">
        <div className="flex h-svh w-full flex-col">
          <div className="z-0 flex h-full flex-col bg-gray-ultra-light">
            <div>
              <div className="relative flex h-14 items-center justify-between py-1 pl-2 pr-8">
                <div className="flex items-center gap-2 text-sm">
                  <div className="rounded bg-gray-ultra-light p-1.5">
                    <img
                      src="/folder-black.svg"
                      width={20}
                      height={20}
                      alt=""
                    />
                  </div>
                  <span className="text-text-secondary">
                    <a href="/projects">Projects</a> /{' '}
                  </span>
                  <span className="text-text-secondary">
                    <a href="/projects/project/407">Default project</a> /{' '}
                  </span>
                  <span className="font-medium">Marketing Campaign Generator</span>
                </div>
                <div className="absolute left-[calc(50%-120px)]">
                  <div className="flex rounded-lg border border-zinc-200 bg-neutrals-300 p-[2px]">
                    <button className="mr-1 flex w-[120px] items-center justify-center gap-2 rounded-md border-none p-1 text-sm font-medium text-gray-dark outline-none focus:outline-none">
                      <img
                        src="/article.svg"
                        alt=""
                      />
                      Edit
                    </button>
                    <button className="flex w-[128px] items-center justify-center gap-2 rounded-md border-none bg-white p-1 text-sm font-medium text-gray-dark outline-none focus:outline-none">
                      <img
                        src="/play_arrow.svg"
                        alt=""
                      />
                      Flow
                    </button>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <button
                    aria-checked="false"
                    aria-labelledby=":r3h:-flowbite-toggleswitch-label"
                    id=":r3h:-flowbite-toggleswitch"
                    role="switch"
                    tabIndex={0}
                    type="button"
                    className="group flex cursor-pointer rounded-lg focus:outline-none"
                  >
                    <div
                      data-testid="flowbite-toggleswitch-toggle"
                      className="relative h-8 w-14 min-w-14 rounded-full border bg-white after:absolute after:left-[4px] after:top-[3px] after:h-6 after:w-6 after:rounded-full after:bg-[url('/memory-context-off.svg')] after:bg-contain after:bg-center after:bg-no-repeat after:transition-all"
                    />
                  </button>
                  <button
                    type="button"
                    className="group relative flex items-stretch justify-center rounded-lg bg-primary-default text-center font-medium text-white transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] hover:bg-primary-hover focus:z-10 focus:outline-none active:bg-primary-active"
                  >
                    <span className="flex h-[2.125rem] items-center gap-2 rounded-md px-4 text-button-md transition-all duration-200 has-[img]:pl-3">
                      <img
                        src="/generate-white.svg"
                        width={16}
                        height={16}
                        alt=""
                      />
                      Generate
                    </span>
                  </button>
                  <button
                    type="button"
                    className="group relative flex items-stretch justify-center rounded-lg border-[1px] border-gray-divider bg-white text-center font-medium text-text-primary transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] hover:bg-gray-bg focus:z-10 focus:outline-none active:bg-gray-ultra-light"
                  >
                    <span className="flex h-[2.125rem] items-center gap-2 rounded-md px-4 text-button-md transition-all duration-200 has-[img]:pl-3">
                      <img
                        src="/download-black.svg"
                        width={16}
                        height={16}
                        alt=""
                      />
                      PDF
                    </span>
                  </button>
                  <button
                    type="button"
                    className="group relative flex items-stretch justify-center rounded-lg border-[1px] border-gray-divider bg-white text-center font-medium text-text-primary transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] hover:bg-gray-bg focus:z-10 focus:outline-none active:bg-gray-ultra-light"
                  >
                    <span className="flex h-[2.125rem] items-center gap-2 rounded-md px-4 text-button-md transition-all duration-200 has-[img]:pl-3">
                      <img
                        src="/group-add-black.svg"
                        width={16}
                        height={16}
                        alt=""
                      />
                      Share
                    </span>
                  </button>
                  <div className="flex items-center">
                    <div className="mr-2 flex items-center gap-2">
                      <span className="text-xs font-medium text-zinc-600" />
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="relative ml-3 flex flex-row items-center">
                        <div className="-ml-3">
                          <span>
                            <img
                              className="h-8 w-8 rounded-full border border-white dark:border-black"
                              src="https://lh3.googleusercontent.com/a/ACg8ocKYrvg6a8-9vArbSYP49AUclq9tYfkCYKH3TVzJxDxrR0hv-Ac=s96-c"
                              alt="avatar"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative mb-3 mr-3 flex h-full min-h-0 grow rounded-2xl bg-white">
              <div className="absolute left-3 top-3 z-20 h-8 w-8">
                <button
                  className="bg-interactive-gray rounded-full p-1"
                  style={{ padding: 4 }}
                >
                  <img
                    src="/sidebar-black.svg"
                    alt=""
                    width={24}
                    height={24}
                    style={{ width: 24, height: 24 }}
                  />
                </button>
              </div>
              <div className="z-30 flex">
                <div className="relative left-0 top-0 h-full w-72 border-r border-r-neutral-200 bg-white transition-all duration-300 dark:border-r-neutral-800">
                  <div className="h-full w-full overflow-hidden">
                    <div className="relative h-full w-full overflow-y-auto px-3 py-6">
                      <div className="mb-5 text-nowrap text-[12px] font-medium text-text-secondary">
                        Table of contents
                      </div>
                      <div className="flex flex-col">
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          1. About company
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          2. Persona
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          3. Positioning Story
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          4. Big Idea
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          5. Choose the best Big Idea
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          6. Marketing Funnel
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          7. Campaign Key Visual
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          8. Campaign slogan
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          9. Prompt for graphical model
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          10. Poster
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          11. Billboard
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          12. Landing Page prompt
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          13. Landing page concept
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          14. Landing page text
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          15. Post
                        </a>
                        <a
                          className="block w-full truncate rounded p-[7px] text-[12px] font-medium text-text-secondary transition-all hover:bg-gray-100 hover:text-neutral-800"
                          style={{ paddingLeft: 'calc(8px + 0.1rem)' }}
                        >
                          16. Post text
                        </a>
                      </div>
                      <div className="absolute right-3 top-4 h-8 w-8 rotate-180 opacity-100 transition-opacity duration-200 group-[.is-collapsed]:hidden">
                        <button
                          className="bg-interactive-gray rounded-full p-1"
                          style={{ padding: 4 }}
                        >
                          <img
                            src="/sidebar-black.svg"
                            alt=""
                            width={24}
                            height={24}
                            style={{ width: 24, height: 24 }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex h-full flex-1 flex-col overflow-hidden">
                <div
                  className="flex-1 overflow-y-auto"
                  aria-expanded="false"
                >
                  <div
                    contentEditable="true"
                    autoCorrect="off"
                    autoCapitalize="off"
                    translate="no"
                    className="tiptap ProseMirror min-h-full"
                    aria-expanded="false"
                    tabIndex={0}
                  >
                    <h1
                      id="d8f8ed3d-9da6-43dd-adcf-0f0aca947953"
                      data-toc-id="d8f8ed3d-9da6-43dd-adcf-0f0aca947953"
                    >
                      About company
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      alt=""
                                      width={16}
                                      height={16}
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p
                                        data-placeholder=""
                                        className="is-empty"
                                      >
                                        <br className="ProseMirror-trailingBreak" />
                                      </p>
                                      <p>
                                        Based on the information provided in the document, the company sells expensive
                                        headphones in light blue and orange colors. The company's logo features multiple
                                        colors, with pink being the dominant color.
                                      </p>
                                      <ul>
                                        <li>
                                          <p>
                                            Company Name: The document does not explicitly mention the name of the
                                            company.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            Services: The company specializes in the sale of high-end headphones in
                                            light blue and orange colors.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            Mission: The document does not provide specific information about the
                                            company's mission.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            Offer: The company offers a range of expensive headphones in specific
                                            colors, catering to customers looking for premium audio products.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            B2B or B2C: The document does not specify whether the company operates in a
                                            B2B (business-to-business) or B2C (business-to-consumer) model.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            Target Audience: The target audience of the company would likely include
                                            individuals who value high-quality audio products and are willing to invest
                                            in premium headphones. The specific focus on light blue and orange colors
                                            may also attract customers who appreciate unique and stylish designs in
                                            their audio accessories.
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="396903d9-5b1f-48ee-86bb-6fd60dd29b6c"
                      data-toc-id="396903d9-5b1f-48ee-86bb-6fd60dd29b6c"
                    >
                      Persona
                    </h1>
                    <p>
                      A persona is a fictional character that represents a specific segment of users or customers,
                      created based on research and data. It includes details like demographics, behaviors, goals, and
                      challenges to help businesses understand and empathize with their audience. Personas are used to
                      guide design decisions and marketing strategies by ensuring they align with the needs and
                      preferences of target users.
                    </p>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      alt=""
                                      width={16}
                                      height={16}
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p
                                        data-placeholder=""
                                        className="is-empty"
                                      >
                                        <br className="ProseMirror-trailingBreak" />
                                      </p>
                                      <p>
                                        Based on the information provided, the company sells expensive headphones,
                                        indicating a focus on high-quality audio products and stylish design. Given
                                        these characteristics, the business is primarily B2C (business-to-consumer)
                                        oriented. While it's possible to have a B2B component (e.g., selling to retail
                                        stores), the primary focus here seems to be on individuals seeking premium
                                        headphones for personal use.
                                      </p>
                                      <h1
                                        id="33d6169e-3982-45b9-bd7c-69de54eddb52"
                                        data-toc-id="33d6169e-3982-45b9-bd7c-69de54eddb52"
                                      >
                                        Buyer Persona for B2C Segment
                                      </h1>
                                      <h2
                                        id="da0ec6d9-5941-4871-b8e1-863839479957"
                                        data-toc-id="da0ec6d9-5941-4871-b8e1-863839479957"
                                      >
                                        Persona
                                      </h2>
                                      <ul>
                                        <li>
                                          <p>
                                            <strong>Name:</strong> Emily Trendsetter
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Role:</strong> Style-Conscious Audiophile
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Problem:</strong> Wants headphones that deliver exceptional sound
                                            quality while aligning with her unique fashion sense.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Pains:</strong> Frustrated by the lack of high-quality headphones
                                            that are both stylish and match her personal aesthetic. Annoyed by
                                            generic-looking audio gear that doesn't complement her style.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Jobs-To-Be-Done:</strong> Wants to experience immersive audio while
                                            showcasing her individuality through fashion-forward headphones.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Motivation:</strong> Driven by a desire to stand out and be
                                            recognized for her taste in cutting-edge fashion and technology.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Trigger:</strong> Newly launched music streaming service with
                                            exclusive high-fidelity tracks; inspired by friends and influencers
                                            flaunting unique tech wearables.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Barriers:</strong> Concerns about the investment in expensive
                                            equipment that may not perform as advertised or fit her fashion criteria.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            <strong>Context:</strong> Familiar with mainstream audio brands and fashion
                                            trends but has been searching for something that combines both elements
                                            seamlessly.
                                          </p>
                                        </li>
                                      </ul>
                                      <h3
                                        id="5c35dbc8-9be6-4581-8644-9945bfee836f"
                                        data-toc-id="5c35dbc8-9be6-4581-8644-9945bfee836f"
                                      >
                                        Insights for Marketing
                                      </h3>
                                      <ul>
                                        <li>
                                          <p>
                                            Highlight the unique colors and design of the headphones in marketing
                                            materials to appeal to her fashion sense.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            Emphasize the superior sound quality and the exclusivity of the product.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            Leverage social proof, showing influencers or stylish peers using the
                                            headphones.
                                          </p>
                                        </li>
                                        <li>
                                          <p>
                                            Address the value proposition clearly, reassuring quality and style
                                            alignment with her lifestyle.
                                          </p>
                                        </li>
                                      </ul>
                                      <h3
                                        id="92fd064e-4ce3-4a20-baac-1c80580997c0"
                                        data-toc-id="92fd064e-4ce3-4a20-baac-1c80580997c0"
                                      >
                                        Conclusion
                                      </h3>
                                      <p>
                                        For this B2C-focused business, defining a persona like Emily Trendsetter can
                                        guide marketing efforts to tap into the desires and pain points of
                                        fashion-forward audiophiles. By targeting individuals who prioritize both sound
                                        quality and aesthetic appeal, the company can effectively position its high-end
                                        headphones as must-have lifestyle accessories.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="f1a0325a-7a4d-4410-b3c4-75803141b3a6"
                      data-toc-id="f1a0325a-7a4d-4410-b3c4-75803141b3a6"
                    >
                      Positioning Story
                    </h1>
                    <p>
                      A Positioning Story is a strategic narrative that defines a brand's unique identity and value
                      proposition in the market. It aims to differentiate the brand from competitors by highlighting its
                      strengths and the benefits it offers to its target audience. This story helps create an emotional
                      connection with customers, guiding marketing efforts and fostering brand loyalty.
                    </p>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="dd055149-39c7-4cf4-8a7e-470934cb43e7"
                      data-toc-id="dd055149-39c7-4cf4-8a7e-470934cb43e7"
                    >
                      Big Idea
                    </h1>
                    <p>
                      A big idea is a central, groundbreaking concept or thought that drives a marketing campaign,
                      project, or strategy, providing it with coherence and direction. It serves as the foundation upon
                      which all communication elements are built, allowing for differentiation from competitors and
                      effective engagement with the audience.
                    </p>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="5dd93a6e-6809-4902-9677-f03f9f3c3e37"
                      data-toc-id="5dd93a6e-6809-4902-9677-f03f9f3c3e37"
                    >
                      Choose the best Big Idea
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="bde76fcb-8e1c-405e-9aef-c1011babccdc"
                      data-toc-id="bde76fcb-8e1c-405e-9aef-c1011babccdc"
                    >
                      Marketing Funnel
                    </h1>
                    <p>
                      A marketing funnel is a model that represents the customer journey from awareness to purchase. It
                      illustrates the stages a potential customer goes through, including awareness, interest,
                      consideration, and decision. The funnel helps businesses understand and optimize their marketing
                      strategies to convert leads into paying customers.
                    </p>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div contentEditable="false">
                      <hr />
                    </div>
                    <h1
                      id="fc025d5c-6445-4fb1-ad0a-18dcf20576e8"
                      data-toc-id="fc025d5c-6445-4fb1-ad0a-18dcf20576e8"
                    >
                      Campaign Key Visual
                    </h1>
                    <p>
                      A key visual is a central image or graphic used to convey the core message and theme of a
                      marketing campaign or brand identity. It serves as a visual anchor, ensuring consistency across
                      various media and platforms. Typically, it incorporates distinctive elements like colors,
                      typography, and imagery that are instantly recognizable and aligned with the brand's objectives.
                    </p>
                    <h1
                      id="03f81f5a-5c69-4743-813e-608df72b0948"
                      data-toc-id="03f81f5a-5c69-4743-813e-608df72b0948"
                    >
                      Campaign slogan
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="607a5825-79ed-42c1-8270-c05d9b292192"
                      data-toc-id="607a5825-79ed-42c1-8270-c05d9b292192"
                    >
                      Prompt for graphical model
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="f94d31ad-92d2-4377-8212-fcf0b5097906"
                      data-toc-id="f94d31ad-92d2-4377-8212-fcf0b5097906"
                    >
                      Poster
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="266eb8df-0cc5-473a-b4de-4fe21ad39fc8"
                      data-toc-id="266eb8df-0cc5-473a-b4de-4fe21ad39fc8"
                    >
                      Billboard
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="0d702f2a-905d-459e-9970-a556da4dcf3f"
                      data-toc-id="0d702f2a-905d-459e-9970-a556da4dcf3f"
                    >
                      Landing Page prompt
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="52cc1e36-5283-47cf-81d8-be37a03b36c4"
                      data-toc-id="52cc1e36-5283-47cf-81d8-be37a03b36c4"
                    >
                      Landing page concept
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="17e1472b-42bc-415a-a94d-9430d5370572"
                      data-toc-id="17e1472b-42bc-415a-a94d-9430d5370572"
                    >
                      Landing page text
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="0314afd5-1e5f-4d99-9410-3545a02d8711"
                      data-toc-id="0314afd5-1e5f-4d99-9410-3545a02d8711"
                    >
                      Post
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1
                      id="be65a8f8-9472-46cd-a9fb-2c2e530bcec1"
                      data-toc-id="be65a8f8-9472-46cd-a9fb-2c2e530bcec1"
                    >
                      Post text
                    </h1>
                    <div className="react-renderer node-dfGenOutput">
                      <div
                        data-node-view-wrapper=""
                        style={{ whiteSpace: 'normal' }}
                      >
                        <div className="df-node-border rounded-2xl border border-gray-divider">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center justify-between gap-1">
                              <div className="relative flex w-full flex-col">
                                <div className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark">
                                  <button className="link link--md ml-auto">
                                    <img
                                      src="/generate-blue.svg"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                    Generate
                                  </button>
                                </div>
                                <div>
                                  <div
                                    className="content w-full p-4 focus-visible:outline-none"
                                    data-node-view-content=""
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    <div
                                      data-node-view-content-react=""
                                      style={{ whiteSpace: 'inherit' }}
                                    >
                                      <p />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      data-placeholder=""
                      className="is-empty"
                    >
                      <br className="ProseMirror-trailingBreak" />
                    </p>
                  </div>
                  <div
                    style={{
                      pointerEvents: 'none',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  />
                </div>
                <div className="absolute bottom-[50px] hidden w-full justify-center">
                  <button className="rounded-lg bg-[#2e4fff] p-2 py-2 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-arrow-up-down"
                    >
                      <path d="m21 16-4 4-4-4" />
                      <path d="M17 20V4" />
                      <path d="m3 8 4-4 4 4" />
                      <path d="M7 4v16" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="relative left-0 top-0 z-[30] h-full w-0 border-r-transparent bg-white transition-all duration-300">
                <div className="h-full w-full min-w-[25rem] overflow-hidden">
                  <div className="relative h-full w-full overflow-y-auto p-6">
                    <div className="flex items-center justify-between gap-4">
                      <div className="flex items-center gap-2">
                        <img
                          className="h-8 w-8 rounded-lg bg-notice-300 p-2"
                          src="/pin-notice.svg"
                          alt=""
                        />
                        <h2 className="text-h5-desktop">Memory Context</h2>
                        <span
                          className="flex !h-5 !w-5 items-center justify-center gap-1 rounded-full bg-yellow-100 !p-0 text-xs !font-medium text-yellow-1000"
                          data-testid="flowbite-badge"
                        >
                          0
                        </span>
                      </div>
                      <button
                        className="bg-interactive-gray rounded-full p-1"
                        style={{ padding: 4 }}
                      >
                        <img
                          src="/close-side-panel-black.svg"
                          alt=""
                          width={24}
                          height={24}
                          style={{ width: 24, height: 24 }}
                        />
                      </button>
                    </div>
                    <div className="mt-6 flex flex-col gap-3">
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          1
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          2
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          3
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          4
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          5
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          6
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          7
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500">
                        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">
                          8
                        </div>
                        <div className="flex gap-2">
                          <img
                            src="/pin-notice.svg"
                            alt=""
                          />
                          <span>Pin content</span>
                        </div>
                      </div>
                      <div
                        id="DndDescribedBy-1"
                        style={{ display: 'none' }}
                      >
                        To pick up a draggable item, press the space bar. While dragging, use the arrow keys to move the
                        item. Press space again to drop the item in its new position, or press escape to cancel.
                      </div>
                      <div
                        id="DndLiveRegion-1"
                        role="status"
                        aria-live="assertive"
                        aria-atomic="true"
                        style={{
                          position: 'fixed',
                          width: 1,
                          height: 1,
                          margin: '-1px',
                          border: 0,
                          padding: 0,
                          overflow: 'hidden',
                          clip: 'rect(0px, 0px, 0px, 0px)',
                          clipPath: 'inset(100%)',
                          whiteSpace: 'nowrap',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
