import { cn } from '@/helpers/cn';
import { memo, useCallback } from 'react';
import { Editor } from '@tiptap/react';
import { TableOfContents } from '../TableOfContents';
import { IconButton } from '@/components/IconButton';

export const Sidebar = memo(
  ({ editor, isOpen, onClose }: { editor: Editor; isOpen?: boolean; onClose: () => void }) => {
    const handlePotentialClose = useCallback(() => {
      if (window.innerWidth < 1024) {
        onClose();
      }
    }, [onClose]);

    const windowClassName = cn(
      'absolute top-0 left-0 bg-white h-full relative w-0 duration-300 transition-all',
      !isOpen && 'border-r-transparent',
      isOpen && 'w-72 border-r border-r-neutral-200 dark:border-r-neutral-800',
    );

    return (
      <div className={windowClassName}>
        <div className="h-full w-full overflow-hidden">
          <div className="relative h-full w-full overflow-y-auto px-3 py-6">
            <TableOfContents
              onItemClick={handlePotentialClose}
              editor={editor}
            />
            <div
              className={cn(
                'absolute right-3 top-4 h-8 w-8 rotate-180 transition-opacity duration-200 group-[.is-collapsed]:hidden',
                {
                  'opacity-0': !isOpen,
                  'opacity-100': isOpen,
                },
              )}
            >
              <IconButton
                icon="/sidebar-black.svg"
                onClick={() => onClose()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Sidebar.displayName = 'TableOfContentSidepanel';
