import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@/components/IconButton.tsx';
import { useEffect, useMemo, useState } from 'react';
import { Select, TextInput } from 'flowbite-react';
import { useMyFlows } from '@/api/flow/my-flow/get-my-flows.ts';
import { DropdownProjectOptions } from '../../components/DropdownProjectOptions.tsx';
import { FormikProvider, useFormik } from 'formik';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { HiSearch } from 'react-icons/hi';
import { object, string } from 'yup';
import { ProjectItemList } from './components/ProjectItemList.tsx';
import { MyFlowCard } from './components/MyFlowCard.tsx';
import { MemoryContextProjectSection } from './components/MemoryContextProjectSection.tsx';
import { Urls } from '@/consts/urls.ts';
import { TiptapDocumentType } from '@/enums/tiptap-document-type.enum.ts';
import { Flow } from '@/models/flow.interface.ts';
import { useProject } from '@/api/projects/get-project.ts';
import { ComingSoonProjectItemList } from './components/ComingSoonProjectItemList.tsx';
import { ProjectItemPlaceholder } from './components/ProjectItemPlaceholder.tsx';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { CreateProjectItemDropdown } from './components/CreateProjectItemDropdown.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { filterEntity } from '@/helpers/filter-entity.ts';
import { AvatarList } from '@/components/AvatarList.tsx';
import { TiptapDocument } from '@/models/tiptap-document.interface.ts';
import { useOrganizationMembers } from '@/api/organization/get-organization-members.ts';
import { User } from '@/models/user.interface.ts';
import { useAuth0 } from '@auth0/auth0-react';

export function ProjectDetails() {
  const { user } = useAuth0();
  const { projectId } = useParams();
  const { organizationMembers } = useOrganizationMembers();
  const { project, isLoading: isProjectLoading } = useProject(Number(projectId));
  const { myFlows, isLoading: isMyFlowsLoading } = useMyFlows(Number(projectId));
  const [myFlowsList, setMyFlowsList] = useState<Flow[]>([]);
  const [myToolFlowsList, setMyToolFlowsList] = useState<Flow[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMyFlowsLoading && myFlows) {
      setMyFlowsList(myFlows.filter((flow) => flow.tiptapDocument.type === TiptapDocumentType.FLOW));
      setMyToolFlowsList(myFlows.filter((flow) => flow.tiptapDocument.type === TiptapDocumentType.TOOL));
    }
  }, [myFlows, isMyFlowsLoading]);

  const formik = useFormik({
    initialValues: {
      search: '',
      modified: '',
    },
    validationSchema: object({
      search: string(),
      modified: string(),
    }),
    onSubmit: () => {},
  });

  const onCreateMyFlow = () => {
    navigate(Urls.FLOWS_LIBRARY);
  };

  const onAddTool = () => {
    navigate(Urls.TOOLS_LIBRARY);
  };

  const filteredMyFlowsList = useMemo(() => {
    return myFlowsList.filter((flow) => filterEntity(flow, formik.values.search, formik.values.modified));
  }, [myFlowsList, formik.values.search, formik.values.modified]);

  const filteredMyToolFlowsList = useMemo(() => {
    return myToolFlowsList.filter((flow) => filterEntity(flow, formik.values.search, formik.values.modified));
  }, [myToolFlowsList, formik.values.search, formik.values.modified]);

  const getCreator = (tiptapDocument: TiptapDocument) => {
    if (tiptapDocument.user_id === user?.user_id) {
      return user;
    }

    const creator = (organizationMembers ?? []).find((value) => value.user_id === tiptapDocument.user_id);
    return creator;
  };

  const getAvatarUsers = (): User[] => {
    const uniqueDocuments = [...myFlowsList, ...myToolFlowsList]
      .map((v) => v.tiptapDocument)
      .reduce((acc, current) => {
        if (!acc.some((item) => item.user_id === current.user_id)) {
          acc.push(current);
        }
        return acc;
      }, [] as TiptapDocument[]);

    return uniqueDocuments.map((v) => getCreator(v)).filter(Boolean) as User[];
  };

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Projects', href: buildUrl([Urls.PROJECTS]) },
          { label: project?.name ?? '', href: '#' },
        ],
        icon: '/folder-black.svg',
        rightAddons: [<CreateProjectItemDropdown key={project?.id} />],
      }}
    >
      {!isProjectLoading && project && (
        <>
          <Helmet>
            <title>Digitalfirst.ai | {project.name}</title>
          </Helmet>

          <div className="mb-8 flex items-center gap-2">
            <h1 className="text-h2-desktop">{project.name}</h1>
            <AvatarList
              users={getAvatarUsers()}
              maxDisplayingUsers={2}
            />
            <DropdownProjectOptions project={project}>
              <div>
                <IconButton
                  icon="/three-dots-gray.svg"
                  size={24}
                  alt="More"
                />
              </div>
            </DropdownProjectOptions>
          </div>

          <section>
            <FormikProvider value={formik}>
              <form
                onSubmit={formik.handleSubmit}
                className="flex justify-between"
              >
                <div className="relative">
                  <TextInput
                    {...getDfFlowbiteFieldProps('search', formik)}
                    placeholder="Search"
                    className="w-96"
                    icon={HiSearch}
                  />
                </div>

                <Select {...getDfFlowbiteFieldProps('modified', formik)}>
                  <option>All</option>
                  <option>Modified: Last 7 days</option>
                  <option>Modified: Last 14 days</option>
                  <option>Modified: Last 30 days</option>
                </Select>
              </form>
            </FormikProvider>
          </section>

          <section className="mt-10">
            <MemoryContextProjectSection />
          </section>

          <section className="mt-10">
            <ProjectItemList
              addButtonClass="!bg-mint-300 hover:!bg-mint-400 active:!bg-mint-500"
              iconClass="bg-mint-500"
              borderClass="border-mint-500"
              title="Strategy"
              addButtonHandler={onCreateMyFlow}
              cards={filteredMyFlowsList.map((flow) => (
                <MyFlowCard
                  myFlow={flow}
                  key={flow.id}
                />
              ))}
            />
          </section>

          <section className="mt-10">
            <ProjectItemList
              addButtonClass="!bg-orange-100 hover:!bg-orange-200 active:!bg-orange-300"
              iconClass="bg-orange-400"
              borderClass="border-orange-200"
              title="Tools"
              addButtonHandler={onAddTool}
              cards={filteredMyToolFlowsList.map((flow) => (
                <MyFlowCard
                  myFlow={flow}
                  key={flow.id}
                />
              ))}
            />
          </section>

          <section className="mt-10">
            <ComingSoonProjectItemList
              addButtonClass="!bg-pink-100"
              iconClass="bg-pink-300"
              borderClass="border-pink-200"
              title="Creation"
              badgeColor="pink"
              cards={
                !isMyFlowsLoading
                  ? [
                      { id: 123412341, name: 'This is a flow name' },
                      { id: 123412342, name: 'Flow for something' },
                      { id: 123412343, name: 'Flow for something else' },
                    ].map((_, index) => <ProjectItemPlaceholder key={index} />)
                  : []
              }
            />
          </section>

          <section className="mt-10">
            <ComingSoonProjectItemList
              addButtonClass="!bg-primary-100"
              iconClass="bg-primary-300"
              borderClass="border-primary-200"
              title="Campaigns"
              badgeColor="primary"
              cards={
                !isMyFlowsLoading
                  ? [
                      { id: 123412341, name: 'This is a flow name' },
                      { id: 123412342, name: 'Flow for something' },
                      { id: 123412343, name: 'Flow for something else' },
                    ].map((_, index) => <ProjectItemPlaceholder key={index} />)
                  : []
              }
            />
          </section>

          <section className="mt-10">
            <ComingSoonProjectItemList
              addButtonClass="!bg-yellow-100"
              iconClass="bg-yellow-400"
              borderClass="border-yellow-300"
              title="Assets"
              badgeColor="yellow"
              cards={
                !isMyFlowsLoading
                  ? [
                      { id: 123412341, name: 'This is a flow name' },
                      { id: 123412342, name: 'Flow for something' },
                      { id: 123412343, name: 'Flow for something else' },
                    ].map((_, index) => <ProjectItemPlaceholder key={index} />)
                  : []
              }
            />
          </section>
        </>
      )}
    </PageWithHeaderLayout>
  );
}
