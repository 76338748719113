import useSWRImmutable from 'swr/immutable';
import { useApi } from '../api';

export const useResourceChunks = (
  fileId: string,
  type: 'input' | 'smartscraper',
): {
  resourceChunks: string[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, error, isLoading } = useSWRImmutable(`/qdrant-connector/read-collection/${fileId}/${type}`, getFetcher);
  return {
    resourceChunks: data,
    isLoading,
    error,
  };
};
