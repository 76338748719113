import { useApi } from '@/api/api.ts';
import { useSwitchToOrganizationAccount } from '@/helpers/switch-to-organization-account.ts';

export const useCreateOrganization = (): {
  createOrganization: (name: string, migrateData: boolean) => Promise<void>;
} => {
  const { postFetcher } = useApi();
  const { switchToOrganizationAccount } = useSwitchToOrganizationAccount();

  const createOrganization = async (name: string, migrateData: boolean): Promise<void> => {
    const organization: string = await postFetcher('/organization', { arg: { name, migrateData } });
    await switchToOrganizationAccount(organization);
  };

  return {
    createOrganization,
  };
};
