import { MutableRefObject } from 'react';
import { Instance, Props } from 'tippy.js';
import Mention from '@tiptap/extension-mention';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { DOMOutputSpec } from 'prosemirror-model';
import { PluginKey } from '@tiptap/pm/state';
import { mentionRender } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mentionRender.ts';
import { Segment } from '@/models/segment.interface.ts';
import { Persona } from '@/models/persona.interface.ts';

export const PersonaMention = (tippy: MutableRefObject<Instance<Props>[]>, segments: Segment[]) => {
  return Mention.extend({ name: MentionReferenceType.PERSONA }).configure({
    HTMLAttributes: {
      class: MentionReferenceType.PERSONA,
    },
    renderText({ options, node }) {
      return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
    },
    renderHTML({ options, node }): DOMOutputSpec {
      const span = document.createElement('span');
      span.dataset.id = node.attrs.id;
      span.dataset.child = node.attrs.childId;
      span.dataset.label = node.attrs.label;
      span.dataset.type = MentionReferenceType.PERSONA;
      span.classList.add('inline-block', 'px-2', 'bg-lime-100', 'rounded', 'text-lime-1000', 'text-body-md');

      span.innerHTML = `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;

      return span;
    },
    deleteTriggerWithBackspace: true,
    suggestion: {
      char: '&',
      pluginKey: new PluginKey(MentionReferenceType.PERSONA),
      items: async ({ query }: { query: string }) => {
        const commands = segments
          ?.flatMap((segment) => segment.personaCanvases)
          ?.map((persona: Persona) => ({
            id: persona.id,
            name: persona.name,
            label: persona.name,
            children: {
              name: 'list',
              commands: persona?.template.positions?.map((position) => ({
                label: position.label,
                id: position.id,
              })),
            },
          }));

        return [
          {
            name: 'List',
            title: 'Add to prompt',
            commands: commands.filter(({ name }: { name: string }) =>
              name.toLowerCase().startsWith(query.toLowerCase()),
            ),
          },
        ];
      },
      render: mentionRender(tippy),
    },
  });
};
