import {
  setTiptapCurrentView,
  useTiptapAccessMode,
  useTiptapCurrentView,
} from '@/components/tiptap/extensions/DigitalFirst/store';
import { cn } from '@/helpers/cn';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';

export const ExecutionModeSwitcher = () => {
  const currentView = useTiptapCurrentView();
  const tiptapAccessMode = useTiptapAccessMode();

  return (
    tiptapAccessMode === TiptapAccessMode.FULL && (
      <div className="flex rounded-lg border border-zinc-200 bg-neutrals-300 p-[2px]">
        <button
          onClick={() => setTiptapCurrentView(TiptapView.CONFIGURATION_MODE)}
          className={cn(
            'mr-1 flex w-[120px] items-center justify-center gap-2 rounded-md border-none p-1 text-sm font-medium text-gray-dark outline-none focus:outline-none',
            {
              'bg-white': currentView === TiptapView.CONFIGURATION_MODE,
            },
          )}
        >
          <img
            src={`/article.svg`}
            alt=""
          />
          Edit
        </button>
        <button
          onClick={() => setTiptapCurrentView(TiptapView.EXECUTION_MODE)}
          className={cn(
            'flex w-[128px] items-center justify-center gap-2 rounded-md border-none p-1 text-sm font-medium text-gray-dark outline-none focus:outline-none',
            {
              'bg-white': currentView === TiptapView.EXECUTION_MODE,
            },
          )}
        >
          <img
            src={`/play_arrow.svg`}
            alt=""
          />
          Flow
        </button>
      </div>
    )
  );
};
