import { Card } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { CanvasDetails } from '@/api/canvases/api.ts';
import { DropdownCanvasOptions } from './DropdownCanvasOptions.tsx';
import { dateToRelativeString } from '@/helpers/date-to-relative-string.ts';

export function MyCanvasCard({ canvasDetails }: { canvasDetails: CanvasDetails }) {
  return (
    <>
      <div className="relative">
        <div className="absolute right-4 top-4">
          <DropdownCanvasOptions instanceId={canvasDetails.id} />
        </div>
        <Link to={buildUrl([Urls.CANVASES, Urls.CANVAS], { canvasId: canvasDetails.id })}>
          <Card className="hover:bg-gray-bg hover:text-text-primary active:bg-gray-ultra-light">
            <div className="mb-4 flex w-full items-center justify-between">
              <div className="rounded-lg bg-tint-purple p-2.5">
                <img
                  src="/business-black.svg"
                  alt=""
                />
              </div>
            </div>
            <h3 className="mb-1 text-h3-desktop">{canvasDetails.name}</h3>
            <div className="mb-4 text-text-secondary">Edited {dateToRelativeString(canvasDetails.modifiedAt)}</div>
          </Card>
        </Link>
      </div>
    </>
  );
}
