import { useApi } from '@/api/api.ts';
import { Persona } from '@/models/persona.interface.ts';

export const useCreatePersona = (): {
  createPersona: (segmentId: number) => Promise<Persona>;
} => {
  const { postFetcher } = useApi();

  const createPersona = async (segmentId: number) => {
    return postFetcher(`/persona-canvas`, {
      arg: { segmentId, name: 'Persona', description: 'Name', positionValue: [] },
    });
  };

  return {
    createPersona,
  };
};
