import { ReactNode } from 'react';
import { LeftSideNav } from './components/LeftSideNav.tsx';
import { AgentPanel } from '../../components/AgentPanel/AgentPanel.tsx';
import { MemoryLog } from '@/components/MemoryLog/MemoryLog.tsx';

export function MainLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="grid h-svh grid-cols-[auto,minmax(0,1fr)]">
        <LeftSideNav />
        <div className="max-w-full">{children}</div>
      </div>
      <AgentPanel />
      <MemoryLog />
    </>
  );
}
