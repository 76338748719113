import GenerateIcon from '@assets/generate-purple-and-black.svg';
import { Button } from 'flowbite-react';
import { useAgentPanel } from './agent-panel.store.ts';

export const AgentPanelButton = () => {
  const { toggleAgentPanel } = useAgentPanel();

  return (
    <Button
      color="secondary"
      size="sm"
      onClick={toggleAgentPanel}
    >
      <GenerateIcon className="size-4" />
      Ask Data Room
    </Button>
  );
};
