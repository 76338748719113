import useSWR from 'swr';
import { useApi } from '../api';

export const useCustomerPortal = () => {
  const { getFetcher } = useApi();
  const { data, isLoading, error } = useSWR<string | false>('stripe/customer-portal', getFetcher);

  return {
    customerPortal: data,
    isLoading,
    error,
  };
};
