export const Urls = {
  DASHBOARD: '',

  WORKSPACES: 'workspaces',

  DATA_ROOM: 'data',

  CANVASES: 'canvases',
  CANVAS: 'canvas/:canvasId',
  MY_CANVASES: 'my-canvases',
  CANVAS_LIBRARY: 'canvas-library/:category',

  FLOWS_LIBRARY: 'flows',
  FLOW_EDITOR: 'flow/:flowId',
  FLOW_TEMPLATE_EDITOR: 'flow-template/:flowId',

  TOOLS_LIBRARY: 'tools',
  TOOLS_TEST_VIEW: 'tools-test-view',
  TOOLS_PIPELINE: 'tools-pipeline-test-view/:pipelineId',

  PROJECTS: 'projects',
  PROJECT: 'project/:projectId',

  WEB_SEARCHER: 'web-searcher',
  WEB_SEARCHER_ROOM: 'web-searcher/:roomId',

  USER_SETTINGS: 'settings/user-settings',
  CREATE_ORGANIZATION: 'settings/create-organization',
  ORGANIZATION_SETTINGS: 'settings/organization-settings',
  ORGANIZATION_MEMBERS: 'settings/organization-members',

  WORKSPACE_SETTINGS: 'settings/workspace-settings',
  WORKSPACE_MEMBERS: 'settings/workspace-members',

  PUBLIC_APP: 'public-app',

  SHARED: 'shared',

  PRICING: 'pricing',

  PRE_CANVAS_TEST: 'pre-canvas-test',

  SEGMENTS: 'segments',
  PERSONA_DETAILS: 'persona/:personaId',

  ONBOARDING_STARTER: 'onboarding-starter',
  ONBOARDING_PREPARE: 'onboarding-prepare',

  ONBOARDING: 'onboarding',
};
