import { Flow } from '@/models/flow.interface.ts';
import { useApi } from '../../api.ts';
import useSWR from 'swr';

export const useMyFlows = (
  projectId: number | null,
): {
  myFlows: Flow[];
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR(projectId ? `/flow/my-flows/${projectId}` : null, getFetcher);

  return {
    myFlows: data?.sort((a: Flow, b: Flow) => b.id - a.id) || [],
    isLoading,
    error,
  };
};
