import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateTool, useGetAllTools } from '../tools/api';
import { Tool } from '../tools/type.model';
import { Button } from 'flowbite-react';
import {
  ReactFlow,
  Node,
  Edge,
  Background,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  Connection,
  addEdge,
  NodeChange,
  EdgeChange,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { ToolNode } from './nodes/tool.node';
import { ToolPipelineHeader } from './ToolPipelineHeader';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useExecutePipeline, useGetPipelineTemplate, usePipelineAddStepTemplate, useUpdateReactFlow } from './api';

export const ToolsPipelineTestView = () => {
  const { pipelineId } = useParams();

  const { tools: data } = useGetAllTools();
  const { createTool } = useCreateTool();
  const { pipelineAddStep } = usePipelineAddStepTemplate();
  const { updateReactFlow } = useUpdateReactFlow();
  const { pipelineTemplate } = useGetPipelineTemplate(+pipelineId!);
  const { executePipeline } = useExecutePipeline();

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const nodeTypes = useMemo(() => ({ toolNode: ToolNode }), []);

  useEffect(() => {
    if (!pipelineTemplate) return;

    setNodes(
      pipelineTemplate?.reactFlow?.nodes?.map((node) => ({ ...node, data: { ...node.data, deleteNode } })) ?? [],
    );
    setTimeout(() => setEdges(pipelineTemplate?.reactFlow?.edges ?? []));
  }, [pipelineTemplate]);

  const deleteNode = (id: string) => {
    const newNodes = nodes.filter((node) => node.id !== id);
    setNodes(newNodes);
  };

  const execute = async () => {
    if (nodes.length <= 0) {
      return toast.error("Pipeline can't be empty");
    }

    await executePipeline(+pipelineId!);
  };

  const selectTool = async (tool: Tool) => {
    const toolObj = await createTool(tool.name, 1);
    const pipelineStep = await pipelineAddStep(+pipelineId!, toolObj.id);
    const nodeList = [...nodes];

    nodeList.push({
      id: `${pipelineStep.id}`,
      position: { x: 0, y: 0 },
      data: { tool, label: tool.name, deleteNode, pipelineId, toolId: toolObj.id },
      type: 'toolNode',
    });

    setNodes(nodeList);
  };

  const onConnect = (params: Connection | Edge) => {
    const newEdges = addEdge(params, edges);
    setEdges(newEdges);
  };

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => {
        const nodes = applyNodeChanges(changes, nds);
        return nodes;
      }),
    [],
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => {
        const edges = applyEdgeChanges(changes, eds);
        return edges;
      }),
    [],
  );

  return (
    <div className="mx-6">
      <div className="mx-2 my-4 grid grid-cols-3 gap-2">
        {data?.map((tool) => (
          <Button
            key={tool.name}
            onClick={() => selectTool(tool)}
          >
            {tool.name}
          </Button>
        ))}
      </div>

      <ToolPipelineHeader
        executePipeline={execute}
        savePipeline={() => {
          updateReactFlow(+pipelineTemplate!.reactFlow.id!, { nodes, edges: nodes.length > 0 ? edges : [] });
          toast.success('Pipeline saved');
        }}
      />

      <div className="h-[800px] w-full">
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};
