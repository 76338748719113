import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { object, string } from 'yup';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { FormikProvider, useFormik } from 'formik';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { Button, Select, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { useCreateFlowTemplate } from '@/api/flow/create-flow-template';
import { FLOW_CATEGORIES } from '@/consts/flow-categories';
import { FlowTemplateCategory } from '@/models/flow-template.interface';
import { useTemplateFlowAccess } from '@/api/flow/use-template-flow-access';

export function CreateTemplateFlowModalBody() {
  const { closeModal } = useGlobalModal();
  const { createFlowTemplate } = useCreateFlowTemplate();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { templateAccess } = useTemplateFlowAccess();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      category: FlowTemplateCategory.EXECUTION,
      access: 'public',
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
      description: string().required(VALIDATION_ERRORS.Required),
      category: string().required(VALIDATION_ERRORS.Required),
      access: string().default('public').required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (data) => {
      showLoader();
      await createFlowTemplate(data);
      hideLoader();
      closeModal();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form
        className="flex flex-col gap-3"
        onSubmit={formik.handleSubmit}
      >
        <TextInput
          {...getDfFlowbiteFieldProps('name', formik)}
          placeholder="Template name"
        />
        <TextInput
          {...getDfFlowbiteFieldProps('description', formik)}
          placeholder="Template description"
        />
        <Select {...getDfFlowbiteFieldProps('category', formik)}>
          {FLOW_CATEGORIES.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Select>

        <Select {...getDfFlowbiteFieldProps('access', formik)}>
          {templateAccess?.map((option) => (
            <option
              key={option.owner}
              value={option.owner}
            >
              {option.name}
            </option>
          ))}
        </Select>

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Create template
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
