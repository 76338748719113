import { Editor } from '@tiptap/react';
import { useCallback } from 'react';
import Tooltip from '../ui/Tooltip';
import { ToolCategory } from 'src/libs/tools/type.model';

const AddPanel = ({ editor }: { editor: Editor }) => {
  //   const { isActive, setIsActive } = useIsActive();

  const handleAddPanel = useCallback(
    (config: { toolcategory: ToolCategory }) => {
      editor.chain().addGenOuput(config).run();
      editor
        .chain()
        .focus(editor.state.selection.to + 2)
        .run();
    },
    [editor],
  );

  const handleAddUserInput = useCallback(() => {
    editor.chain().addUserInput().run();
    editor
      .chain()
      .focus(editor.state.selection.to + 2)
      .run();
  }, [editor]);

  return (
    <div className="z-[100] rounded-lg border border-gray-divider bg-white p-1 shadow-outliner">
      <Tooltip
        title="Add LLM Model"
        tippyOptions={{ placement: 'right', delay: 40, theme: 'dark' }}
      >
        <div
          className="cursor-pointer rounded-md p-2 hover:bg-gray-ultra-light"
          onClick={() =>
            handleAddPanel({
              toolcategory: ToolCategory.TEXTGEN,
            })
          }
        >
          <img
            src="/hub.svg"
            className="h-4 w-4"
          />
        </div>
      </Tooltip>
      <div className="my-[2px] h-[1px] w-full bg-gray-divider opacity-50" />
      <Tooltip
        title="Add Image Model"
        tippyOptions={{ placement: 'right', delay: 40, theme: 'dark' }}
      >
        <div
          className="cursor-pointer rounded-md p-2 hover:bg-gray-ultra-light"
          onClick={() =>
            handleAddPanel({
              toolcategory: ToolCategory.IMAGEGEN,
            })
          }
        >
          <img
            src="/imagesmode.svg"
            className="h-4 w-4"
          />
        </div>
      </Tooltip>
      <div className="my-[2px] h-[1px] w-full bg-gray-divider opacity-50" />
      <Tooltip
        title="Add a Researcher"
        tippyOptions={{ placement: 'right', delay: 40, theme: 'dark' }}
      >
        <div
          className="cursor-pointer rounded-md p-2 hover:bg-gray-ultra-light"
          onClick={() =>
            handleAddPanel({
              toolcategory: ToolCategory.RESEARCHGEN,
            })
          }
        >
          <img
            src="/manage_search.svg"
            className="h-4 w-4"
          />
        </div>
      </Tooltip>
      <div className="my-[2px] h-[1px] w-full bg-gray-divider opacity-50" />
      <Tooltip
        title="Add an Input"
        tippyOptions={{ placement: 'right', delay: 40, theme: 'dark' }}
      >
        <div
          className="cursor-pointer rounded-md p-2 hover:bg-gray-ultra-light"
          onClick={() => handleAddUserInput()}
        >
          <img
            src="/add_user_input.svg"
            className="h-4 w-4"
            alt=""
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default AddPanel;
