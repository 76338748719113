import { PaymentItemType } from '@/models/stripe-product.interface';
import { useMyProducts } from '@/api/pricing/my-products.ts';

export const useUserPlan = () => {
  const { products, isLoading } = useMyProducts();

  const userPlan = products?.find((product) => product.productType === PaymentItemType.PLAN);

  return {
    userPlan,
    isLoading,
  };
};
