import { AccordionInfo } from '@/components/AccordionInfo.tsx';
import { Button, Label, TextInput } from 'flowbite-react';

import { FieldArray, FormikProvider, useFormik } from 'formik';
import { array, object, string } from 'yup';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper.ts';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useAddResource } from '@/api/inputs/add-resource.ts';
import { GLOBAL_LOADER_MESSAGES } from '@/components/GlobalLoader/global-loader-messages.const';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { URL_REGEXP } from '@/consts/url-regexp-validator';

export function DataRoomAddWebsiteLinksModalBody() {
  const { addResource } = useAddResource();
  const { closeModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();

  const formik = useFormik<{ urls: string[] }>({
    initialValues: {
      urls: [''],
    },
    validationSchema: object({
      urls: array().of(string().matches(URL_REGEXP, VALIDATION_ERRORS.Url).required(VALIDATION_ERRORS.Required)),
    }),
    onSubmit: async ({ urls }: { urls: string[] }) => {
      const payloads: AddResourcePayload[] = inputsPayloadMapper[InputType.Url](urls);
      closeModal();
      showLoader(GLOBAL_LOADER_MESSAGES.AddingData);
      await Promise.all(payloads.map((payload) => addResource(payload)));
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <AccordionInfo
          question="Why share a webiste URL?"
          answer="By providing a URL, you enable our AI to gather information from the website, enhancing the results generated for your business. You can share main pages, landing pages, product pages, or about us sections – essentially, any URL containing written information about your business."
        />
        <FieldArray
          name="urls"
          validateOnChange={false}
          render={(arrayHelpers) => (
            <div className="mt-4">
              {formik.values.urls.map((_url, index) => {
                if (index === 0) {
                  return (
                    // first field cannot be removed
                    <div
                      className="mt-6"
                      key={index}
                    >
                      <Label
                        htmlFor={`urls[${index}]`}
                        value="Website URL"
                      />
                      <TextInput
                        {...getDfFlowbiteFieldProps(`urls[${index}]`, formik)}
                        placeholder="Paste website URL"
                      />
                    </div>
                  );
                } else {
                  return (
                    // other fields can be removed
                    <div
                      className="mt-4"
                      key={index}
                    >
                      <Label
                        htmlFor={`urls[${index}]`}
                        value="Website URL"
                      />
                      <div className="flex w-full items-start gap-2">
                        <div className="grow">
                          <TextInput
                            {...getDfFlowbiteFieldProps(`urls[${index}]`, formik)}
                            placeholder="Paste website URL"
                          />
                        </div>
                        <Button
                          color="secondary"
                          size="xs"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          <img
                            src="/trash-gray.svg"
                            alt=""
                          />
                        </Button>
                      </div>
                    </div>
                  );
                }
              })}
              <div className="flex justify-end">
                <Button
                  color="secondary"
                  className="mt-5"
                  size="sm"
                  onClick={() => {
                    arrayHelpers.push('');
                  }}
                >
                  <img
                    src="/add-circle-black.svg"
                    alt=""
                  />
                  Add new website
                </Button>
              </div>
            </div>
          )}
        />

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Add
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
