import { useApi } from '../api';
import useSWR from 'swr';
import { StripeUpsellProduct } from '@/models/stripe-upsell-product.interface.ts';

export const useUpsellProducts = () => {
  const { getFetcher } = useApi();
  const { data, isLoading } = useSWR<StripeUpsellProduct[]>('stripe/upsell-products', getFetcher);

  return {
    upsellProducts: data,
    isLoading,
  };
};
