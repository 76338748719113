export function MemoryContextEmptySlot({ index }: { index: number }) {
  return (
    <>
      <div
        className="flex h-28 flex-col items-center justify-center gap-2 rounded-xl border border-y border-dashed border-notice-500"
        key={`empty-${index}`}
      >
        <div className="rounded-2xl bg-notice-300 p-1 pl-3 pr-3 text-h5-desktop text-notice-1000">{index + 1}</div>
        <div className="flex gap-2">
          <img
            src="/pin-notice.svg"
            alt=""
          />
          <span>Pin content</span>
        </div>
      </div>
    </>
  );
}
