import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCreateWebSearcherRoom } from '@/api/websearcher/create-web-searcher-room.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { object, string } from 'yup';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useCreatePublicWebSearcherRoom } from '@/api/public/websearcher/create-public-web-searcher-room.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { usePublicWebSearcherRooms } from '@/state/app-store.ts';
import { Button } from 'flowbite-react';
import { useWebSearcherMenuStore } from '../web-searcher.store.ts';

export function WebSearcherNewSearchForm() {
  const navigate = useNavigate();
  const { createWebSearcherRoom } = useCreateWebSearcherRoom();
  const { createPublicWebSearcherRoom } = useCreatePublicWebSearcherRoom();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { publicWebSearcherRooms, setPublicWebSearcherRooms } = usePublicWebSearcherRooms();
  const { setIsMenuOpen } = useWebSearcherMenuStore();

  const formik = useFormik({
    initialValues: {
      question: '',
    },
    validationSchema: object({
      question: string().required(),
    }),
    onSubmit: async ({ question }) => {
      await askFirstQuestion(question);
    },
  });

  const askFirstQuestion = async (question: string) => {
    showLoader();
    setIsMenuOpen(false);

    if (isAuthenticated) {
      const room = await createWebSearcherRoom(question);
      navigate(buildUrl([Urls.WEB_SEARCHER_ROOM], { roomId: room.id }));
      hideLoader();
    } else {
      const room = await createPublicWebSearcherRoom(question);
      setPublicWebSearcherRooms([...publicWebSearcherRooms, room]);
      navigate(buildUrl([Urls.PUBLIC_APP, Urls.WEB_SEARCHER_ROOM], { roomId: room.id }));
      hideLoader();
    }
  };

  return (
    <>
      <section
        className="relative flex h-full w-full flex-col items-center justify-center rounded-2xl p-8 text-center"
        style={{ backgroundImage: 'url(/main-gradient-lg.jpg)', backgroundSize: 'cover' }}
      >
        {!isAuthenticated && (
          <Button
            className="absolute right-8 top-8"
            color="primary"
            onClick={() => loginWithRedirect({ authorizationParams: { redirect_uri: window.location.origin } })}
          >
            Log In
          </Button>
        )}
        {!isAuthenticated && (
          <img
            src="/df-logo-black.svg"
            className="mb-16 h-[60px]"
            alt="Digital First AI"
          />
        )}
        <h1 className="flex items-center text-h1-desktop">
          <span>
            Search <span className="italic">precise</span> data
          </span>
          <span className="mx-3 inline-block rounded-xl bg-mint-600 p-3">
            <img
              src="/search-check-black.svg"
              alt=""
            />
          </span>
          in seconds
        </h1>
        <div className="mt-4 text-body-md text-neutrals-700">
          Enhance your strategies and marketing actions by leveraging AI.
          <br />
          Paste your company website URL to seamlessly load your brand data.
        </div>
        <FormikProvider value={formik}>
          <form
            className="mt-9 flex w-full max-w-[50rem] rounded-xl bg-white p-4 pl-5"
            onSubmit={formik.handleSubmit}
          >
            <input
              {...formik.getFieldProps('question')}
              className="ml-2 flex flex-1 outline-none"
              placeholder="Ask anything..."
            />
            <button
              className="ml-4 rounded-lg bg-primary-default p-2 hover:bg-primary-hover active:bg-primary-active"
              type="submit"
            >
              <img
                src="/arrow-right-white.svg"
                alt=""
              />
            </button>
          </form>
        </FormikProvider>
      </section>
    </>
  );
}
