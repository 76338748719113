import { FormikProvider, useFormik } from 'formik';
import { Button, Checkbox } from 'flowbite-react';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { User } from '@/models/user.interface';
import { useOrganizationMembers } from '@/api/organization/get-organization-members';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { useAddWorkspaceMember } from '@/api/workspace/add-workspace-member';
import { useCurrentWorkspace } from '@/state/app-store';
import { cn } from '@/helpers/cn';

export function AddToWorkspaceModalBody() {
  const workspace = useCurrentWorkspace();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal, context } = useGlobalModal<User[]>();
  const { organizationMembers } = useOrganizationMembers();
  const { addWorkspaceMember } = useAddWorkspaceMember();

  const getMemberFirstName = (organizationMember: User) => {
    return organizationMember.given_name ?? organizationMember.name;
  };

  const getMemberLastName = (organizationMember: User) => {
    return organizationMember.family_name ?? organizationMember.name;
  };

  const getMemberFullName = (organizationMember: User) => {
    return organizationMember.family_name && organizationMember.given_name
      ? `${organizationMember.given_name} ${organizationMember.family_name}`
      : 'Unknown User';
  };

  const formik = useFormik<{ users: User[] }>({
    initialValues: {
      users: context,
    },
    onSubmit: async ({ users }) => {
      showLoader('Inviting members...');
      const newMembers = users.filter((user) => !context.find((value) => value.user_id === user.user_id));
      try {
        await Promise.all(newMembers.map((user) => addWorkspaceMember(workspace?.id, user.user_id)));
        closeModal();
      } finally {
        hideLoader();
      }
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6">
            <div className="flex flex-col gap-3">
              {organizationMembers?.map((organizationMember) => (
                <div
                  key={organizationMember.user_id}
                  className={cn(
                    'flex cursor-pointer items-center gap-3 rounded-lg border p-4',
                    !!formik.values.users.find((item) => item.user_id === organizationMember.user_id) &&
                      'border-primary-default bg-slate-100',
                  )}
                  onClick={() => {
                    if (formik.values.users.find((item) => item.user_id === organizationMember.user_id)) {
                      formik.setFieldValue(
                        'users',
                        formik.values.users.filter((item) => item.user_id !== organizationMember.user_id),
                      );
                    } else {
                      formik.setFieldValue('users', [...formik.values.users, organizationMember]);
                    }
                  }}
                >
                  <Checkbox
                    checked={!!formik.values.users.find((item) => item.user_id === organizationMember.user_id)}
                  />
                  {organizationMember?.picture ? (
                    <img
                      src={organizationMember?.picture}
                      className="size-8 h-auto rounded-full"
                    />
                  ) : (
                    <DfAvatar
                      firstName={getMemberFirstName(organizationMember)}
                      lastName={getMemberLastName(organizationMember)}
                    />
                  )}
                  <div className="flex flex-col gap-1">
                    <span className="text-caption-md">{getMemberFullName(organizationMember)}</span>
                    <span className="text-body-sm">{organizationMember.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
            >
              Add
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}
