import { useCallback, useEffect, useState } from 'react';
import { Handle, Position, useEdges, useNodes, useReactFlow, useUpdateNodeInternals } from '@xyflow/react';
import { ExecuteToolFieldsPayload, Tool } from '../../tools/type.model';
import { IconButton } from '@/components/IconButton.tsx';
import './index.scss';
import { UpdateToolsPipelineStep } from '../type.model';
import toast from 'react-hot-toast';
import { usePipelineRemoveStepTemplate, usePipelineUpdateStepTemplate } from '../api';
import { ConfigurationModal } from './ConfigurationModal';
import { OutputModal } from './OutputModal';

interface ToolNodeProps {
  id: string;
  data: {
    label: string;
    tool: Tool;
    deleteNode: (id: string) => void;
    pipelineId: string;
    formConfig?: Record<string, string>;
    toolId: number;
  };
}
const possibleInputPositions = [Position.Top, Position.Left, Position.Right];

export const ToolNode = ({ id, data }: ToolNodeProps) => {
  const { updateNodeData } = useReactFlow();
  const edges = useEdges();
  const nodes = useNodes();
  const updateNodeInternals = useUpdateNodeInternals();

  const { pipelineRemoveStep } = usePipelineRemoveStepTemplate();
  const { pipelineUpdateStep } = usePipelineUpdateStepTemplate();

  const [handleData, setHandleData] = useState<string[]>([]);
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [showOutputModal, setShowOutputModal] = useState(false);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);

  const updateStep = useCallback(
    async (values: Record<string, string>) => {
      const fromOtherTool = edges
        ?.filter((edge) => edge.target === id)
        ?.map<string>((edge) => edge.targetHandle as string);

      const payload: UpdateToolsPipelineStep = {
        isFirstStep: edges?.filter((edge) => edge.target === id).length === 0,
        input: [],
      };

      for (const key in values) {
        const input: ExecuteToolFieldsPayload = {
          key,
        };

        if (fromOtherTool.includes(key)) {
          const edge = edges?.find((edge) => edge.targetHandle === key);
          const sourceNode = nodes?.find((node) => node.id === edge?.source);

          if (!sourceNode) {
            return toast.error('Something went wrong. Try again later.');
          }

          const toolId = sourceNode.id;
          input.outputFromTool = +toolId;
        } else {
          input.value = values[key];
        }

        payload?.input?.push(input);
      }

      await pipelineUpdateStep(+id, payload);
    },
    [edges, id, nodes, pipelineUpdateStep],
  );

  useEffect(() => {
    const inputOutputFields: string[] = [];
    const inputs = data?.tool?.config?.input;

    if (!inputs) return;

    for (const key in inputs) {
      if (inputs[key]?.canBeGeneratedBy) {
        inputOutputFields.push(key);
      }
    }

    const disabled = edges?.filter((edge) => edge.target === id)?.map<string>((edge) => edge.targetHandle as string);

    setHandleData(inputOutputFields);
    updateNodeInternals(id);
    setDisabledFields(disabled);
  }, [data?.tool?.config?.input, edges, id, updateNodeInternals, nodes]);

  const onChange = useCallback(
    (values: Record<string, string>) => {
      updateNodeData(id, { ...data, formConfig: values });
      updateStep(values);
    },
    [updateNodeData, id, data, updateStep],
  );

  const deleteNode = async (id: string) => {
    await pipelineRemoveStep(+id, +data.pipelineId);
    data.deleteNode(id);
  };

  return (
    <>
      <div className="border-2 bg-white">
        {handleData.map((config, index) => (
          <Handle
            key={config}
            type="target"
            position={possibleInputPositions[index]}
            id={config}
          >
            <p className="react-flow__handle-text">{config}</p>
          </Handle>
        ))}

        <IconButton
          className="absolute right-5 top-0"
          icon="/user-black.svg"
          onClick={() => setShowOutputModal(!showOutputModal)}
          size={20}
        />

        <IconButton
          className="absolute right-0 top-0"
          icon="/close-gray.svg"
          onClick={() => deleteNode(id)}
          size={20}
        />
        <div
          onClick={() => setShowConfigurationModal(!showConfigurationModal)}
          className="mx-4 my-4"
        >
          {data.tool.name}
        </div>

        <Handle
          type="source"
          position={Position.Bottom}
          id="output"
        />
      </div>

      <ConfigurationModal
        data={data}
        onChange={onChange}
        disabledFields={disabledFields}
        showConfigurationModal={showConfigurationModal}
        setShowConfigurationModal={setShowConfigurationModal}
      ></ConfigurationModal>

      <OutputModal
        showOutputModal={showOutputModal}
        setShowOutputModal={setShowOutputModal}
        toolId={data.toolId}
        outputType={data.tool.config.outputType}
      ></OutputModal>
    </>
  );
};
