import { cn } from '@/helpers/cn';
import { Dropdown } from 'flowbite-react';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useDeleteProject } from '@/api/projects/delete-project.ts';
import { Project } from '@/models/project.interface.ts';
import { ReactElement } from 'react';
import { SubDropdown } from '@/components/SubDropdown.tsx';
import { useUpdateProject } from '@/api/projects/update-project.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';

export function DropdownProjectOptions({ project, children }: { project: Project; children: ReactElement }) {
  const { openModal } = useGlobalModal();
  const { openDialog } = useGlobalDialog();
  const { deleteProject } = useDeleteProject();
  const { updateProject } = useUpdateProject();
  const { showLoader, hideLoader } = useGlobalLoader();

  const deleteClick = () => {
    openDialog(DIALOGS.DeleteProject, async () => {
      showLoader();
      await deleteProject(project.id);
      hideLoader();
    });
  };

  const onRenameClick = () => {
    openModal(MODALS.EditProjectName, project);
  };

  const onSetColorClick = async (color: string) => {
    showLoader();
    await updateProject(project.id, { color });
    hideLoader();
  };

  const availableProjectColors = [
    'bg-green-light',
    'bg-lime-600',
    'bg-olive-600',
    'bg-orange-600',
    'bg-yellow-600',
    'bg-pink-600',
    'bg-blue-600',
    'bg-turquoise-600',
    'bg-purple-600',
    'bg-violet-600',
    'bg-brown-600',
  ];

  return (
    <Dropdown
      label=""
      onClick={(e) => e.stopPropagation()}
      dismissOnClick={false}
      renderTrigger={() => children}
      placement="bottom-end"
      className="!fixed"
    >
      <Dropdown.Item
        onClick={() => onRenameClick()}
        className="min-w-48"
      >
        <img
          src="/pencil-black.svg"
          alt=""
        />
        Rename
      </Dropdown.Item>
      <SubDropdown
        renderTrigger={() => (
          <Dropdown.Item>
            <img
              src="/pallette-black.svg"
              alt=""
            />
            <div className="flex w-full items-center justify-between">
              <span>Change color</span>
              <img
                src="/chevron-right-gray.svg"
                alt=""
              />
            </div>
          </Dropdown.Item>
        )}
        placement="right-start"
      >
        <>
          <div className="mb-1 p-1 pb-0 text-caption-sm text-text-secondary">Folder color</div>
          <div className="grid grid-cols-7 gap-2 p-1">
            {availableProjectColors.map((color) => (
              <span
                key={color}
                className={cn('h-4 w-4 cursor-pointer rounded-2xl', color)}
                onClick={() => onSetColorClick(color)}
              ></span>
            ))}
          </div>
        </>
      </SubDropdown>
      <Dropdown.Item onClick={() => deleteClick()}>
        <img
          src="/archive-black.svg"
          alt=""
        />
        Delete
      </Dropdown.Item>
    </Dropdown>
  );
}
