import { IconButton } from '@/components/IconButton.tsx';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { CanvasCategory } from '@/enums/canvas-category.enum.ts';
import { useLocation } from 'react-router-dom';
import { useProjects } from '@/api/projects/get-projects.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { cn } from '@/helpers/cn';
import { LeftSideNavMenuItem } from '@/layouts/MainLayout/components/LeftSideNavMenuItem.tsx';
import { DropdownProjectOptions } from 'src/routes/projects/components/DropdownProjectOptions';
import { useLeftSideNav } from '@/layouts/MainLayout/store.ts';

export function LeftSideNavMenuItems() {
  const location = useLocation();
  const { projects } = useProjects();
  const { openModal } = useGlobalModal();
  const { isNavCollapsed } = useLeftSideNav();

  return (
    <div className="flex flex-col gap-1">
      <LeftSideNavMenuItem
        href={buildUrl([Urls.DASHBOARD])}
        label="Dashboard"
        leftIcon="/dashboard-black.svg"
      />

      <LeftSideNavMenuItem
        href={buildUrl([Urls.PROJECTS])}
        label="Projects"
        leftIcon="/folder-black.svg"
        addons={
          <IconButton
            icon="/plus-black.svg"
            size={24}
            onClick={() => openModal(MODALS.CreateProject)}
          />
        }
        customActiveCondition={
          (location.pathname.includes(buildUrl([Urls.PROJECTS])) && isNavCollapsed) ||
          location.pathname === buildUrl([Urls.PROJECTS])
        }
        renderCollapsed={
          <>
            {projects &&
              projects.slice(0, 6).map((project) => (
                <LeftSideNavMenuItem
                  key={project.id}
                  href={buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: project.id })}
                  addons={
                    <DropdownProjectOptions project={project}>
                      <div>
                        <IconButton
                          icon="/three-dots-gray.svg"
                          alt="More"
                          className="opacity-0 group-hover/item:opacity-100"
                          size={24}
                        />
                      </div>
                    </DropdownProjectOptions>
                  }
                  label={project.name}
                  customLeftIcon={
                    <div className={cn('my-2.5 ml-1.5 rounded-md p-1.5', project.color ?? 'bg-green-light')}>
                      <img
                        src="/business-black.svg"
                        width={12}
                        height={12}
                        alt=""
                      />
                    </div>
                  }
                  level={1}
                  customActiveCondition={location.pathname.includes(
                    buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: project.id }),
                  )}
                />
              ))}
            <LeftSideNavMenuItem
              label="All projects"
              href={buildUrl([Urls.PROJECTS])}
              leftIcon="/tiles-black.svg"
              level={1}
              customActiveCondition={false}
            />
          </>
        }
      />

      <LeftSideNavMenuItem
        href={buildUrl([Urls.DATA_ROOM])}
        label="Data"
        leftIcon="/rows-black.svg"
        customActiveCondition={
          ((location.pathname.includes(buildUrl([Urls.SEGMENTS])) ||
            location.pathname.includes(buildUrl([Urls.CANVASES])) ||
            location.pathname.includes(buildUrl([Urls.PRE_CANVAS_TEST]))) &&
            isNavCollapsed) ||
          location.pathname === buildUrl([Urls.DATA_ROOM])
        }
        renderCollapsed={
          <>
            <LeftSideNavMenuItem
              label="Overview"
              leftIcon="/overview-black.svg"
              level={1}
              disabled={true}
            />

            {/* <LeftSideNavMenuItem
              href={buildUrl([Urls.PRE_CANVAS_TEST])}
              label="Pre canvas test"
              leftIcon="/search-check-black.svg"
              level={1}
              customActiveCondition={location.pathname.includes(Urls.PRE_CANVAS_TEST)}
            /> */}

            <LeftSideNavMenuItem
              label="Brand assets"
              leftIcon="/brand-assets-black.svg"
              level={1}
              disabled={true}
            />

            <LeftSideNavMenuItem
              href={buildUrl([Urls.SEGMENTS])}
              label="Segments"
              leftIcon="/user-black.svg"
              level={1}
            />

            <LeftSideNavMenuItem
              label="Products"
              leftIcon="/products-black.svg"
              level={1}
              disabled={true}
            />

            <LeftSideNavMenuItem
              label="Offers"
              leftIcon="/offers-black.svg"
              level={1}
              disabled={true}
            />

            <LeftSideNavMenuItem
              href={buildUrl([Urls.CANVASES])}
              label="Strategy Canvases"
              leftIcon="/canvases-black.svg"
              level={1}
              renderCollapsed={
                <>
                  <LeftSideNavMenuItem
                    href={buildUrl([Urls.CANVASES, Urls.MY_CANVASES])}
                    label="My Canvases"
                    leftIcon="/overview-black.svg"
                    level={2}
                    customActiveCondition={
                      location.pathname === buildUrl([Urls.CANVASES, Urls.MY_CANVASES]) ||
                      location.pathname.includes(buildUrl([Urls.CANVASES, Urls.CANVAS], { canvasId: '' }))
                    }
                  />

                  <LeftSideNavMenuItem
                    href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.ALL })}
                    label="Library"
                    leftIcon="/overview-black.svg"
                    level={2}
                    customActiveCondition={location.pathname.includes(
                      buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: '' }),
                    )}
                  />
                </>
              }
            />
          </>
        }
      />

      <LeftSideNavMenuItem
        href={buildUrl([Urls.WEB_SEARCHER])}
        label="Web Searcher"
        leftIcon="/search-check-black.svg"
        customActiveCondition={location.pathname.includes(Urls.WEB_SEARCHER)}
      />
    </div>
  );
}
