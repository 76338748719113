import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { Button } from 'flowbite-react';
import { SingleSegmentPanel } from './components/SingleSegmentPanel.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useSegments } from '@/api/segments/get-segments.ts';

export const SegmentsPanel = () => {
  const { segments } = useSegments();
  const { openModal } = useGlobalModal();

  const onCreateNewSegment = () => {
    openModal(MODALS.CreateSegment);
  };

  return (
    <>
      <PageWithHeaderLayout
        header={{
          breadcrumbs: [
            { label: 'Data', href: buildUrl([Urls.DATA_ROOM]) },
            { label: 'Segments', href: '#' },
          ],
          icon: '/user-black.svg',
          rightAddons: [
            <Button
              key={'0'}
              color="primary"
              onClick={onCreateNewSegment}
            >
              <img
                src="/add-white.svg"
                width={16}
                height={16}
                alt=""
              />
              Create new segment
            </Button>,
          ],
        }}
      >
        <Helmet>
          <title>Digitalfirst.ai | Segments</title>
        </Helmet>

        <div className="flex flex-col gap-10">
          {!!segments &&
            segments.map((segment, i) => (
              <SingleSegmentPanel
                key={i}
                segment={segment}
              />
            ))}
        </div>
      </PageWithHeaderLayout>
    </>
  );
};
