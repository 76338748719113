import { WebSearcherRoom } from '@/models/web-searcher-room.interface';
import './WebSearcherRoomChat.scss';
import { FormikProvider, useFormik } from 'formik';
import { useAskQuestionInWebSearcherRoom } from '@/api/websearcher/ask-question-in-web-searcher-room.ts';
import { object, string } from 'yup';
import { WebSearcherAnswer } from './WebSearcherAnswer.tsx';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useAskQuestionInPublicWebSearcherRoom } from '@/api/public/websearcher/ask-question-in-public-web-searcher-room.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useNavigate } from 'react-router-dom';
import { useDeleteWebSearcherRoom } from '@/api/websearcher/delete-web-searcher-room.ts';
import { usePublicWebSearcherRooms } from '@/state/app-store.ts';
import { useWebSearcherMenuStore, useWebSearcherStore } from '../web-searcher.store.ts';

export function WebSearcherRoomChat({ room }: { room: WebSearcherRoom }) {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const { isAuthenticated } = useAuth0();
  const { askQuestionInWebSearcherRoom } = useAskQuestionInWebSearcherRoom();
  const { askQuestionInPublicWebSearcherRoom } = useAskQuestionInPublicWebSearcherRoom();
  const { deleteWebSearcherRoom } = useDeleteWebSearcherRoom();
  const { publicWebSearcherRooms, setPublicWebSearcherRooms } = usePublicWebSearcherRooms();
  const { questions, setQuestions, isFetchingAnswer, setAnswerFetchingStart, setAnswerFetchingEnd } =
    useWebSearcherStore(room.id);
  const { setIsMenuOpen } = useWebSearcherMenuStore();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      question: '',
    },
    validationSchema: object({
      question: string().required(),
    }),
    onSubmit: ({ question }) => {
      formik.resetForm();
      setIsMenuOpen(false);
      askNewQuestion(question);
    },
  });

  // on change of room.id, set questions in global store if not already set
  useEffect(() => {
    if (questions?.length) return;

    if (!room.history.length) {
      setQuestions([room.name]);
    } else {
      setQuestions(room.history.map((historyItem) => historyItem.question.message));
    }
  }, [room.id]);

  // on change of questions array, fetch answer for the question without answer
  useEffect(() => {
    if (!questions?.length) return;

    const questionWithoutAnswer = questions.find(
      (question) => !room.history.find((historyItem) => historyItem.question.message === question),
    );

    if (questionWithoutAnswer && !isFetchingAnswer) {
      scrollToLatestQuestion();
      fetchAnswerForQuestion(questionWithoutAnswer);
    }
  }, [questions, questions?.length]);

  const askNewQuestion = (question: string) => {
    if (isFetchingAnswer) return;

    setQuestions([...questions!, question]);
  };

  const onAskFollowUpQuestion = async (question: string) => {
    setIsMenuOpen(false);
    await askNewQuestion(question);
  };

  const scrollToLatestQuestion = () => {
    setTimeout(() => {
      const headers = scrollContainer.current?.querySelectorAll('h2.web-searcher-header');

      if (headers?.length) {
        headers[headers.length - 1].scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  };

  const fetchAnswerForQuestion = async (question: string) => {
    setAnswerFetchingStart();

    if (isAuthenticated) {
      askQuestionInWebSearcherRoom(room.id, question)
        .then(() => {
          setAnswerFetchingEnd();
        })
        .catch(async () => {
          setAnswerFetchingEnd();
          if (questions!.length === 1) {
            navigate(buildUrl([Urls.WEB_SEARCHER]));
            await deleteWebSearcherRoom(room.id);
          } else {
            setQuestions(questions!.filter((q) => q !== question));
          }
        });
    } else {
      askQuestionInPublicWebSearcherRoom(room, question)
        .then((answer) => {
          publicWebSearcherRooms
            .find((r) => r.id === room.id)
            ?.history.push({
              question: { message: question },
              answer,
            });
          setPublicWebSearcherRooms([...publicWebSearcherRooms]);
          setAnswerFetchingEnd();
        })
        .catch(() => {
          setAnswerFetchingEnd();
          if (questions!.length === 1) {
            navigate(buildUrl([Urls.PUBLIC_APP, Urls.WEB_SEARCHER]));
            setPublicWebSearcherRooms(publicWebSearcherRooms.filter((r) => r.id !== room.id));
          } else {
            setQuestions(questions!.filter((q) => q !== question));
          }
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai | {questions?.[0] ?? ''}</title>
      </Helmet>

      <div className="flex items-start justify-center px-[3.75rem] pb-[3.75rem] pt-8">
        <div
          className="flex flex-col gap-10"
          ref={scrollContainer}
        >
          {questions?.map((question, i) => (
            <WebSearcherAnswer
              key={`${i}-full-answer`}
              historyItem={room.history[i]}
              question={question}
              onAskFollowUpQuestion={onAskFollowUpQuestion}
            />
          ))}

          {!isFetchingAnswer && (
            <div className="sticky bottom-0 left-0 z-[1000] pb-7">
              <div className="mt-8">
                <div className="grid grid-cols-[minmax(10%,42rem),minmax(auto,22rem)] gap-12">
                  <FormikProvider value={formik}>
                    <form
                      className="flex rounded-xl bg-white p-4 pl-5 shadow-[0_0_1rem_0_#E3E3E5]"
                      onSubmit={formik.handleSubmit}
                    >
                      <input
                        {...formik.getFieldProps('question')}
                        className="ml-2 flex flex-1 outline-none"
                        placeholder="Ask anything..."
                      />
                      <button
                        className="ml-4 rounded-lg bg-primary-default p-2 hover:bg-primary-hover active:bg-primary-active"
                        type="submit"
                      >
                        <img
                          src="/arrow-right-white.svg"
                          alt=""
                        />
                      </button>
                    </form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
