import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { useResourceSummary } from '@/api/inputs/get-resource-summary.ts';
import { llmMarked } from '@/helpers/llmMarked.ts';

export function AddedDataItemSummary({ item }: { item: DataRoomItem }) {
  const { resourceSummary, isLoading, error } = useResourceSummary(item.id);

  if (error)
    return (
      <div className="flex size-full items-center justify-center text-center text-body-lg">
        <div className="flex flex-col items-center gap-4">
          <div className="rounded-lg bg-notice-300 p-2.5">
            <img
              src="/file-black.svg"
              className="size-6"
              alt=""
            />
          </div>
          <div>
            The summary for this resource could not be loaded.
            <br />
            Please try again later.
          </div>
        </div>
      </div>
    );

  return (
    <div className="relative size-full px-14 py-6">
      {isLoading ? (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center">
          <img
            src="/loading-blue.svg"
            className="animate-spin-slow"
            alt=""
          />
        </div>
      ) : (
        <div
          className="text-body-md"
          dangerouslySetInnerHTML={{
            __html: llmMarked(resourceSummary),
          }}
        ></div>
      )}
    </div>
  );
}
