import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { Button, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { Flow } from '@/models/flow.interface.ts';
import { useUpdateMyFlow } from '@/api/flow/my-flow/update-my-flow.ts';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';

export const MyFlowRenameModalBody = () => {
  const { closeModal, context } = useGlobalModal<Flow & { projectId: string }>();
  const { updateMyFlowName } = useUpdateMyFlow();
  const { showLoader, hideLoader } = useGlobalLoader();

  const formik = useFormik({
    initialValues: {
      name: context.name,
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (data) => {
      closeModal();
      showLoader();
      await updateMyFlowName(data.name, context.id, Number(context.projectId));
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          {...getDfFlowbiteFieldProps('name', formik)}
          placeholder="Project name"
        />

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Save changes
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};
