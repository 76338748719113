import { useCreateWebSearcherRoom } from '@/api/websearcher/create-web-searcher-room';
import { Urls } from '@/consts/urls';
import { buildUrl } from '@/helpers/build-url';
import { cn } from '@/helpers/cn';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

interface SearchForm {
  search: string;
}

export function DashboardSearch() {
  const { createWebSearcherRoom } = useCreateWebSearcherRoom();
  const navigate = useNavigate();

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: '',
    },
    validationSchema: object({
      search: string().required(),
    }),
    onSubmit: async ({ search }) => {
      if (!search) {
        return;
      }

      const room = await createWebSearcherRoom(search);
      navigate(buildUrl([Urls.WEB_SEARCHER_ROOM], { roomId: room.id }));
    },
  });

  return (
    <div className="relative w-full">
      <div className="flex w-full items-center gap-3">
        <FormikProvider value={formik}>
          <div className="flex flex-1 rounded-xl bg-white p-2">
            <div>
              <div className={cn('my-2.5 ml-1.5 mr-1 rounded-md p-1.5')}>
                <img
                  src="/search-black.svg"
                  width={12}
                  height={12}
                  alt=""
                />
              </div>
            </div>
            <input
              id="search"
              {...formik.getFieldProps('search')}
              className="ml-2 flex flex-1 outline-none"
              autoComplete="off"
              placeholder="Ask anything..."
            />
          </div>
          <button
            onClick={formik.submitForm}
            disabled={!formik.isValid}
            className="group flex size-[56px] items-center justify-center rounded-xl bg-gray-dark text-white"
          >
            <img
              src="/arrow-right-white.svg"
              alt=""
              className="transition-transform group-hover:translate-x-1"
            />
          </button>
        </FormikProvider>
      </div>
    </div>
  );
}
