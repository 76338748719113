import { FormikProvider, useFormik } from 'formik';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useParams } from 'react-router-dom';
import { useProjects } from '@/api/projects/get-projects.ts';
import { Button } from 'flowbite-react';
import { useUpdateMyFlow } from '@/api/flow/my-flow/update-my-flow.ts';
import { Flow } from '@/models/flow.interface.ts';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { DfRadio } from '@/components/DfRadio.tsx';
import { cn } from '@/helpers/cn';

export function MoveMyFlowToProjectModalBody() {
  const { projectId } = useParams();
  const { closeModal, context } = useGlobalModal<{ myFlow: Flow }>();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { projects, isLoading } = useProjects();
  const { moveMyFlowToProject } = useUpdateMyFlow();
  const currentWorkspace = useCurrentWorkspace();

  const formik = useFormik({
    initialValues: {
      newProjectId: +projectId!,
    },
    onSubmit: async ({ newProjectId }) => {
      closeModal();
      showLoader();
      await moveMyFlowToProject(Number(newProjectId), context.myFlow.id, Number(projectId));
      hideLoader();
    },
  });

  const currentProject = projects?.find((project) => project.id === Number(projectId));
  const otherProjects = projects?.filter((project) => project.id !== Number(projectId));

  if (isLoading || !currentProject) {
    return;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-8 flex flex-col gap-4">
            <h2 className="text-subtitle-md">Current project</h2>
            <DfRadio
              name="newProjectId"
              value={currentProject.id}
              formik={formik}
            >
              <div className={cn('rounded-lg p-2', currentProject.color ?? 'bg-green-light')}>
                <img
                  src="/business-black.svg"
                  alt=""
                />
              </div>
              <span className="text-caption-md">{currentProject.name}</span>
            </DfRadio>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="flex items-center gap-3 text-subtitle-md">
              <div className="flex size-6 items-center justify-center rounded-lg bg-pink-300 text-[14px]">W</div>
              <span>{currentWorkspace?.name} projects</span>
            </h2>
            <div className="flex flex-col gap-3">
              {otherProjects &&
                otherProjects.map((project) => (
                  <DfRadio
                    name="newProjectId"
                    value={project.id}
                    formik={formik}
                    key={project.id}
                  >
                    <div className={cn('rounded-lg p-2', project.color ?? 'bg-green-light')}>
                      <img
                        src="/business-black.svg"
                        alt=""
                      />
                    </div>
                    <span className="text-caption-md">{project.name}</span>
                  </DfRadio>
                ))}
            </div>
          </div>

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
            >
              Save changes
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}
