import { useState } from 'react';

export interface UploadedFileProps<FileType extends { name: string; size: number; type: string }> {
  file: FileType;
  onRemove: (file: FileType) => void;
  url?: string;
}

export function UploadedFile<FileType extends { name: string; size: number; type: string }>({
  file,
  onRemove,
  url,
}: UploadedFileProps<FileType>) {
  const [showPlayer, setShowPlayer] = useState(false);
  return (
    <div className="rounded-lg bg-gray-bg py-2 pl-3 pr-2">
      <div className="flex items-center gap-3">
        {file.type?.includes('audio') ? (
          <img
            src="/recordings-gray.svg"
            alt=""
          />
        ) : (
          <img
            src="/text-files-gray.svg"
            alt=""
          />
        )}

        <div className="grow text-subtitle-sm">
          <span className="mr-2">{file.name}</span>
          <span className="text-text-secondary">{Number(file.size / 1024 / 1024).toFixed(2)} MB</span>
        </div>

        {file.type?.includes('audio') && url && (
          <img
            src={showPlayer ? '/close-gray.svg' : '/play-gray.svg'}
            className="size-4 cursor-pointer"
            alt=""
            onClick={() => setShowPlayer(!showPlayer)}
          />
        )}

        <img
          src="/trash-gray.svg"
          className="cursor-pointer"
          alt=""
          onClick={() => onRemove(file)}
        />
      </div>

      {showPlayer && (
        <audio
          controls
          className="mt-4 w-full rounded-lg"
        >
          <source src={url} />
        </audio>
      )}
    </div>
  );
}
