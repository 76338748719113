export enum PaymentItemType {
  PLAN = 'plan',
  WORKSPACE = 'workspace',
  PROJECT_IN_WORKSPACE = 'project_in_workspace',
  STORAGE = 'storage',
  DF_CREDITS = 'df_credits',
}

export interface StripeProduct {
  /** Df product id */
  id: number;
  /** Stripe product id */
  productId: number;

  name: string;

  productType: PaymentItemType;

  settings: Record<string, string | number | boolean>;
}
