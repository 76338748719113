import { MemoryContextItemType } from '@/enums/memory-context-item-type.enum.ts';
import { MemoryContextItem } from '@/models/memory-context-item.interface';
import { cn } from '@/helpers/cn';
import { useDeleteFromMemoryContext } from '@/api/memory-context/delete-from-memory-context.ts';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Editor } from '@tiptap/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export function MemoryContextFilledSlot({
  memoryContextItem,
  index,
  tiptapMode,
  editor,
}: {
  memoryContextItem: MemoryContextItem;
  index: number;
  tiptapMode?: boolean;
  editor?: Editor;
}) {
  const { projectId } = useParams();
  const { deleteFromMemoryContext } = useDeleteFromMemoryContext();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: memoryContextItem.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
  };

  const bgStyles = tiptapMode
    ? 'bg-notice-200 group-hover:bg-notice-300 group-active:bg-notice-400 transition'
    : 'bg-notice-300';

  const onSlotClick = () => {
    if (!editor) return;

    if (memoryContextItem.type === MemoryContextItemType.TEXT_PARTIAL) {
      editor.chain().focus().insertContent(memoryContextItem.textPartial!).run();
    } else if (memoryContextItem.type === MemoryContextItemType.TIPTAP_DOCUMENT) {
      editor.chain().focus().insertContent(memoryContextItem.convertedYDoc!.content).run();
    }
  };

  const onDelete = async () => {
    await deleteFromMemoryContext(projectId!, memoryContextItem.id);
    toast.success('Memory context item deleted');
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
    >
      <div className="relative">
        <button
          className="w-full text-left"
          key={`memory-context-${index}`}
          onClick={onSlotClick}
          disabled={!tiptapMode}
        >
          <div className="group flex flex-col">
            {memoryContextItem.type === MemoryContextItemType.TEXT_PARTIAL && (
              <div
                className={cn(
                  'memory-context-content-trim mb-1 h-[72px] rounded-tl-xl rounded-tr-xl p-2 px-4 text-body-sm',
                  bgStyles,
                )}
              >
                {memoryContextItem.textPartial}
              </div>
            )}
            {memoryContextItem.type === MemoryContextItemType.TIPTAP_DOCUMENT && (
              <div
                className={cn(
                  'mb-1 inline-block h-[72px] rounded-tl-xl rounded-tr-xl bg-notice-300 p-4 text-button-lg',
                  bgStyles,
                )}
              >
                {memoryContextItem.documentTitle}
                <img
                  src="/generate-black.svg"
                  className="ml-2 inline-block"
                  width="17"
                  height="16"
                  alt=""
                />
              </div>
            )}
            <div className={cn('flex justify-between rounded-bl-xl rounded-br-xl p-2 pl-4 pr-4', bgStyles)}>
              <div className="flex gap-2">
                <img
                  src="/generate-black.svg"
                  alt=""
                />
                <span className="text-caption-sm">
                  {memoryContextItem.type === MemoryContextItemType.TEXT_PARTIAL && 'Text'}
                  {memoryContextItem.type === MemoryContextItemType.TIPTAP_DOCUMENT && 'Flow'}
                </span>
              </div>
            </div>
          </div>
        </button>

        <button
          onClick={onDelete}
          className="absolute bottom-2 right-4"
        >
          <img
            src="/cancel-black.svg"
            alt=""
          />
        </button>
      </div>
    </div>
  );
}
