import { LeftSideNavMenuItem } from '@/layouts/MainLayout/components/LeftSideNavMenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import { hide, Intercom, onHide, onShow, show } from '@intercom/messenger-js-sdk';
import { useState } from 'react';

function HelpCenterMenuItem() {
  if (import.meta.env.VITE_INTERCOM_APP_ID) {
    return <HelpCenterIntercom />;
  }
  return (
    <LeftSideNavMenuItem
      label="Help Center"
      leftIcon="/help-black.svg"
      disabled
    />
  );
}

function HelpCenterIntercom() {
  const { user } = useAuth0();

  const [isOpen, setIsOpen] = useState(false);

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    user_id: user?.sub,
    name: user?.name,
    email: user?.email,
    created_at: user?.createdAt,
    hide_default_launcher: true,
  });

  onShow(() => {
    setIsOpen(true);
  });

  onHide(() => {
    setIsOpen(false);
  });

  function toggleIntercom() {
    isOpen ? hide() : show();
  }

  return (
    <LeftSideNavMenuItem
      label="Help Center"
      leftIcon="/help-black.svg"
      onClick={toggleIntercom}
    />
  );
}

export default HelpCenterMenuItem;
