import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Link } from 'react-router-dom';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useMemo } from 'react';
import { StripeUpsellProductName } from '@/enums/StripeUpsellProductName.enum.ts';
import { PlanLimitErrorType } from '@/enums/plan-limit-error-type.enum.ts';
import { useUpsellProducts } from '@/api/pricing/get-upsell-products.ts';
import { UpsellProductForm } from '../../routes/pricing/components/UpsellProductForm.tsx';

export const BuyUpsellProductModalBody = () => {
  const { closeModal, context } = useGlobalModal<{ errorType: PlanLimitErrorType }>();
  const { upsellProducts } = useUpsellProducts();

  const errorInfo = useMemo(() => {
    if (!upsellProducts) return;

    switch (context.errorType) {
      case PlanLimitErrorType.WORKSPACE_LIMIT_EXCEEDED:
        return {
          title: 'You have reached the limit of workspaces',
          upsellProduct: upsellProducts.find(
            (product) => product.metadata.upsellName === StripeUpsellProductName.WORKSPACE,
          ),
        };
      case PlanLimitErrorType.STORAGE_LIMIT_EXCEEDED:
        return {
          title: 'You have reached the limit of storage',
          upsellProduct: upsellProducts.find(
            (product) => product.metadata.upsellName === StripeUpsellProductName.STORAGE,
          ),
        };
    }
  }, [upsellProducts, context.errorType]);

  return (
    <div
      className="ounded-xl"
      style={{ backgroundImage: 'url(/modal-gradient.jpg)', backgroundSize: 'cover' }}
    >
      {!!errorInfo && (
        <>
          <div className="flex flex-col text-center">
            <div className="mb-6 mt-12 self-center rounded-xl bg-yellow-300 p-3">
              <img
                src="/block-black.svg"
                alt=""
              />
            </div>
            <h1 className="mb-3 text-h2-desktop">{errorInfo.title}</h1>
            <p className="text-body-md text-neutrals-700">
              You can extend your limits or change your plan form{' '}
              <Link
                to={buildUrl([Urls.PRICING])}
                onClick={closeModal}
                className="text-primary-default"
              >
                settings.
              </Link>
            </p>
          </div>
          <div className="p-12">
            <UpsellProductForm
              upsellProduct={errorInfo.upsellProduct!}
              displayingOnModal={true}
            />
          </div>
        </>
      )}
    </div>
  );
};
