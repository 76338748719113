import { UpsellProductCard } from './UpsellProductCard.tsx';
import { useUpsellProducts } from '@/api/pricing/get-upsell-products.ts';
import { StripeUpsellProductName } from '@/enums/StripeUpsellProductName.enum.ts';

export const UpsellProductsSection = () => {
  const { upsellProducts } = useUpsellProducts();

  return (
    <>
      <h2 className="mb-6 mt-8 text-h2-desktop">Add-Ons</h2>

      <section className="grid gap-6 xl:grid-cols-2">
        {upsellProducts
          // Filter out the DF_TOKEN upsell product because currently user with plan has unlimited credits
          ?.filter((upsellProduct) => upsellProduct.metadata.upsellName !== StripeUpsellProductName.DF_TOKEN)
          .map((upsellProduct, index) => (
            <UpsellProductCard
              upsellProduct={upsellProduct}
              key={index}
            />
          ))}
      </section>
    </>
  );
};
