import { Badge, Button } from 'flowbite-react';
import { ColorPalette } from './ColorPalette.tsx';
import { DfTooltip } from '@/components/DfTooltip/DfTooltip.tsx';

export function CompanyBriefIsland() {
  return (
    <div className="flex rounded-3xl bg-[#fff3f6] p-8">
      <div className="mr-6 flex size-[132px] shrink-0 items-center justify-center rounded-3xl bg-[#ffb3c7]">
        <span className="text-[70px]">W</span>
      </div>

      <div className="mr-8 flex grow flex-col">
        <div className="mb-3 flex items-center gap-3">
          <h2 className="text-h1-desktop">Company name</h2>
          <Badge
            color="pink"
            size="lg"
          >
            Coming soon!
          </Badge>
        </div>
        <p className="mb-2 text-body-md">
          Digital First AI is a revolutionary marketing platform that provides easy access to effective marketing
          strategies, empowering...
        </p>
        <div className="flex items-end gap-2 font-medium text-[#d73c65]">
          Overview
          <img
            src="/chevron-right-pink.svg"
            alt=""
          />
        </div>
      </div>

      <div className="flex min-w-[300px] flex-col items-end justify-between">
        <Button
          color="secondary"
          disabled={true}
          data-tooltip-id="coming-soon-brand-assets"
        >
          <img
            src="/brand-assets-black.svg"
            alt=""
          />
          Brand assets
        </Button>
        <DfTooltip
          id="coming-soon-brand-assets"
          content="Coming soon!"
          place="bottom"
        />
        <div className="flex items-center gap-4">
          <ColorPalette
            colors={['#ffb3c7', '#110f23', '#423f5e', '#f7658c']}
            bgColor="#fff3f6"
          />
          <div className="flex gap-3">
            <img
              src="/font-black.svg"
              alt=""
            />
            Inter font
          </div>
        </div>
      </div>
    </div>
  );
}
