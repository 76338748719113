import { OnboardingUserData } from '@/models/onboarding-user-data.interface.ts';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { useApi } from '@/api/api.ts';
import { useState } from 'react';
import { setCurrentWorkspace } from '@/state/app-store.ts';
import { mutate } from 'swr';
import { Workspace } from '@/models/workspace.interface.ts';

export const useStartOnboardingProcess = () => {
  const { postFetcher } = useApi();
  const startOnboardingProcessUrl = '/onboarding/start';
  const [isStartingOnboarding, setIsStartingOnboarding] = useState(false);

  const startFlowProcess = async (url: string, flowId: number): Promise<OnboardingUserData> => {
    return await postFetcher(startOnboardingProcessUrl, {
      arg: {
        url,
        type: OnboardingProcessType.FLOW,
        flowId: +flowId,
      },
    });
  };

  const startWebSearcherProcess = async (url: string, marketResearchPrompt: string): Promise<OnboardingUserData> => {
    return await postFetcher(startOnboardingProcessUrl, {
      arg: {
        url,
        type: OnboardingProcessType.WEB_SEARCHER,
        marketResearchPrompt,
      },
    });
  };

  const startCanvasProcess = async (url: string, canvasId: number): Promise<OnboardingUserData> => {
    return await postFetcher(startOnboardingProcessUrl, {
      arg: {
        url,
        type: OnboardingProcessType.CANVAS,
        canvasId,
      },
    });
  };

  const startOnboardingProcess = async (
    url: string,
    onboardingProcessType: OnboardingProcessType,
    option: string,
  ): Promise<void> => {
    setIsStartingOnboarding(true);

    try {
      let workspace: Workspace;

      if (onboardingProcessType === OnboardingProcessType.FLOW) {
        workspace = (await startFlowProcess(url, Number(option))).workspace;
      } else if (onboardingProcessType === OnboardingProcessType.WEB_SEARCHER) {
        workspace = (await startWebSearcherProcess(url, option)).workspace;
      } else if (onboardingProcessType === OnboardingProcessType.CANVAS) {
        workspace = (await startCanvasProcess(url, Number(option))).workspace;
      } else {
        throw new Error('Unknown onboarding process type');
      }

      await mutate('/workspace/by-user');
      setCurrentWorkspace(workspace);
    } finally {
      setIsStartingOnboarding(false);
    }
  };

  return {
    startOnboardingProcess,
    isStartingOnboarding,
  };
};
