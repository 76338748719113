import axios, { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useAccessToken } from '@/api/get-access-token.ts';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useStoredUserPlan } from '../../routes/pricing/user-plan-store.ts';
import { PlanLimitErrorType } from '@/enums/plan-limit-error-type.enum.ts';
import { ModalProps } from '@/models/modal-props.interface.ts';

export const useCreateDfAxios = () => {
  const { getAccessToken } = useAccessToken();
  const { errorComposer } = useErrorComposer();
  const workspaceId = useCurrentWorkspace()?.id;

  const createDfAxios = async () => {
    const accessToken = await getAccessToken();

    const dfAxios = axios.create({
      baseURL: import.meta.env.VITE_API_SERVER,
      timeout: 1000 * 60 * 5,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'DF-Workspace-Id': `${workspaceId}`,
      },
    });

    dfAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        errorComposer(error);
        return Promise.reject(error);
      },
    );

    return dfAxios;
  };

  return {
    createDfAxios,
  };
};

export const useCreatePublicDFAxios = () => {
  const { errorComposer } = useErrorComposer();

  const createPublicDFAxios = () => {
    const dfAxios = axios.create({
      baseURL: import.meta.env.VITE_API_SERVER,
      timeout: 1000 * 60 * 5,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    dfAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        errorComposer(error);
        return Promise.reject(error);
      },
    );

    return dfAxios;
  };

  return {
    createPublicDFAxios,
  };
};

const useErrorComposer = () => {
  const { openModal, closeModal } = useGlobalModal();
  const { closeDialog } = useGlobalDialog();
  const { hideLoader } = useGlobalLoader();
  const userPlan = useStoredUserPlan();

  const closeAllGlobalComponents = () => {
    closeModal();
    closeDialog();
    hideLoader();
  };

  const errorComposer = (error: AxiosError) => {
    closeAllGlobalComponents();

    const statusCode = error.response ? error.response.status : null;

    if (statusCode === 400) {
      toast.error('Bad request');
    }

    if (statusCode === 403 || statusCode === 401) {
      toast.error('No access to this resource.');
    }

    if (statusCode === 404) {
      toast.error('Resource not found');
    }

    if (statusCode === 413) {
      toast.error('File too large. Max size: 50MB');
    }

    if (statusCode === 498) {
      const message = (error.response?.data as { message?: string })?.message;
      if (message) {
        toast.error(message);
      } else {
        toast.error('Something went wrong. Please try again later.');
      }
    }

    if (statusCode === 499) {
      if (userPlan) {
        handlePlanLimitErrorForUserWithPlan(error, openModal);
      } else {
        openModal(MODALS.UpgradePlan);
      }
    }

    if (statusCode?.toString()?.startsWith('5')) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return {
    errorComposer,
  };
};

const handlePlanLimitErrorForUserWithPlan = (
  error: AxiosError,
  openModal: (props: ModalProps, context?: object) => void,
) => {
  const errorType = (error.response?.data as { message: PlanLimitErrorType }).message;

  if (
    errorType === PlanLimitErrorType.PROJECT_LIMIT_EXCEEDED ||
    errorType === PlanLimitErrorType.DF_CREDITS_LIMIT_EXCEEDED
  ) {
    openModal(MODALS.GenericPlanLimitInfo);
  } else {
    openModal(MODALS.BuyUpsellProduct, { errorType });
  }
};
